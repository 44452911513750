import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import DownloadXsl from "./download";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Upload from "./upload";
import Grid from '@material-ui/core/Grid';
import { NotificationManager } from "react-notifications";
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
const { FILE_URL } = require("../../utils/Url");


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`
  };
}

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper
  }
}));

export default function Xsl(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const [value, setValue] = React.useState(0);
  const [fileName, setFileName] = React.useState("");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const setFile = file => {
    setFileName(file);
  };
  const validateFileName = () => {
    if (fileName === "")
      NotificationManager.error("Please select a file", "Error");
    else
      window.open(
        FILE_URL + "/filestorage/xsl/download?fileName=" + fileName + "&User=" + localStorage.getItem("UserInfo"),
        "_blank"
      );
  };

  return (
    <Dialog open={open} onClose={props.closeXslModal} fullWidth >
      <DialogTitle style={{ backgroundColor: "#3f51b5", color: "white" }}>
        <Grid container>
          <Grid item xs={12} sm={12} md={10} lg={10} lg={10}>
            Download XSLs
          </Grid>
          <Grid item xs={12} sm={12} md={2} lg={2} lg={2} container direction="row" justify="flex-end" >
            <IconButton onClick={props.closeXslModal} aria-label="delete" size="small">
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        {/* <AppBar position="static">
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="simple tabs example"
          >
            <Tab label="Upload" {...a11yProps(0)} />
            <Tab label="Download" {...a11yProps(0)} />
          </Tabs>
        </AppBar> */}
        {/* <TabPanel value={value} index={0}>
          <Upload />
        </TabPanel> */}
        {/* <TabPanel value={value} index={0}> */}
        <DownloadXsl setFile={setFile} />
        {/* </TabPanel> */}
      </DialogContent>
      <DialogActions>
        <Button onClick={validateFileName} color="primary">
          Download
        </Button>
      </DialogActions>
    </Dialog >
  );
}
