import React, { Component } from "react";
import { connect } from "react-redux";
import {
  setFilteredActiveConnection,
  updateEndpointConnection
} from "../../Services/endpoint";
import Loading from "../Loading";
import ShowError from "../ShowError";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import IconButton from "@material-ui/core/IconButton";
import Divider from "@material-ui/core/Divider";
import ComStyle from "./comparator.css";
import DeleteSweepIcon from "@material-ui/icons/DeleteSweep";
import Tooltip from "@material-ui/core/Tooltip";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import DialogActions from '@material-ui/core/DialogActions';
import Grid from '@material-ui/core/Grid';
import CloseIcon from '@material-ui/icons/Close';

class ConnectionEndpoint extends Component {

  constructor(props) {
    super(props);
    this.state = {
      dialogState: false,
      deleteProps: null,
      name: ""
    }
  }

  componentDidMount() { }

  handleRemove = data => {
    console.log("remove this one ", data);
    this.props.updateEndpointConnection(
      this.props.activeEndpoint,
      data,
      "REMOVE"
    );
    this.setState({
      dialogState: false,
      deleteProps: null
    });
  };
  showDialog = () => {
    console.log("-------------------------------Show Dialogue");


    return (
      <div>
        <Dialog
          open={this.state.dialogState}
          // onClose={}
          // aria-labelledby="responsive-dialog-title"
          fullWidth
        >
          <DialogTitle id="responsive-dialog-title">
            <Grid container>
              <Grid item xs={12} sm={12} md={10} lg={10} lg={10}>
                Delete Endpoint
          </Grid>
              <Grid item xs={12} sm={12} md={2} lg={2} lg={2} container direction="row" justify="flex-end" >
                <IconButton onClick={this.handleClose} aria-label="delete" size="small">
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent style={{ overflow: "hidden" }}>
            <DialogContentText>
              <div style={{ float: "left" }}>  Are you sure you want to delete    </div>
              <div style={{ float: "left", fontWeight: "1000", marginLeft: "5px" }}> {this.state.name}  </div>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={this.confirmDelete}>
              Ok
                    </Button>

          </DialogActions>
        </Dialog>
      </div >
    )

  }
  confirmDelete = () => {
    this.handleRemove(this.state.deleteProps);
  }
  handleClose = () => {
    this.setState({
      dialogState: false,
      deleteProps: null,
    });
  }
  handleDelete = data => {
    this.setState({
      dialogState: true,
      deleteProps: data,
      name: data.Name
    });
    console.log("Data", data);
    this.showDialog(data);
    console.log(this.state);
  };

  showActiveConnection = () => {
    return (

      <div className="main_container">

        <div className="heading_col">
          Connections for {this.props.activeEndpoint.Name}
        </div>
        <div className="column_container">
          <List>
            {this.props.connectionsWithEndpoint.map(data => {
              return (
                <div>
                  <Divider />
                  <ListItem>
                    <ListItemText primary={data.Name} />
                    <ListItemSecondaryAction>
                      <Tooltip title="Remove" aria-label="add">
                        <IconButton
                          edge="end"
                          aria-label="delete"
                          onClick={() => {
                            this.handleDelete(data);
                          }}
                        >
                          <DeleteSweepIcon color={"primary"} />
                        </IconButton>
                      </Tooltip>
                    </ListItemSecondaryAction>
                  </ListItem>
                </div>
              );
            })}
          </List>
        </div>
      </div>
    );
  };

  render() {

    if (this.props.processing) {
      return (
        <div>
          <Loading />
        </div>
      );
    } else if (
      this.props.connectionsWithEndpoint &&
      this.props.connectionsWithEndpoint.length
    ) {
      return <div>{this.showActiveConnection()}{this.showDialog()}</div>;
    } else if (
      this.props.connectionsWithEndpoint &&
      this.props.connectionsWithEndpoint.length === 0
    ) {
      return (
        <div>
          <div>
            <ShowError message={`No connection linked`} />
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <div>
            <ShowError message={`No Connection Linked`} />
          </div>
        </div>
      );
    }
  }
}

const mapStateToProps = state => {
  return {
    connectionsWithEndpoint: state.endpoints.connectionsWithEndpoint,
    processing: state.endpoints.conEndLoading,
    error: state.endpoints.conEndError,
    activeEndpoint: state.endpoints.activeEndpoint
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setFilteredActiveConnection: val =>
      dispatch(setFilteredActiveConnection(val)),
    updateEndpointConnection: (endPoint, selecteditem, operation) =>
      dispatch(updateEndpointConnection(endPoint, selecteditem, operation))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ConnectionEndpoint);
