import {
    SET_NOTIFICATION_ERROR,
    SET_NOTIFICATION_EVENTS,
    SET_RECIPIENT_ROLES,
    SET_NOTIFICATION_TAGS,
    GET_ALL_NOTIFICATIONS,
    SET_EDIT_DATA,
  } from "../action-types/notifications";
  
  export const setNotificationEvents = (data) => {
    return {
      type: SET_NOTIFICATION_EVENTS,
      payload: data,
    };
  };
  
  export const setRecipientRoles = (data) => {
    return {
      type: SET_RECIPIENT_ROLES,
      payload: data,
    };
  };
  
  export const setNotificationTags = (data) => {
    return {
      type: SET_NOTIFICATION_TAGS,
      payload: data,
    };
  };
  
  export const getAllNotifications = (data) => {
    return {
      type: GET_ALL_NOTIFICATIONS,
      payload: data,
    };
  };
  
  export const setEditData = (data) => {
    return {
      type: SET_EDIT_DATA,
      payload: data,
    };
  };
  
  export const getError = (error) => {
    return {
      type: SET_NOTIFICATION_ERROR,
      payload: error,
    };
  };
  