import * as actions from '../actions/scheduler-actions';
const { BASE_URL } = require('../utils/Url');
const { apiAdapter } = require('../utils/APIAdapter');
const axios = require('axios');

export function validateCron(expression) {
  return async dispatch => {
    let response = await axios.get(`${BASE_URL}/cron/validate?expression=${expression}`)
    console.log(response)
    return response.status === 200 ? true : false
  }
}

export function fetchSchedulers() {
  return (dispatch) => {
    dispatch(actions.fetchSchedulerPending());

    const api = apiAdapter(BASE_URL);
    api
      .get(`/scheduler/fetchAll`)
      .then(res => {
        dispatch(actions.fetchSchedulerSuccess(res.data.result));
        dispatch(actions.setSchedularHealth(true));
      })
      .catch(error => {
        dispatch(actions.fetchSchedulerError(error));
      });
  };
}

export function createScheduler(name, scheduledDateTIme, execute, endpointId) {
  var Name = name;
  var ScheduledDateTime = scheduledDateTIme;
  var Execute = execute;
  var EndpointId = endpointId;
  const data = {
    Name,
    ScheduledDateTime,
    Execute,
    EndpointId,
    Internal: 0
  };
  return dispatch => {
    dispatch(actions.addSchedulerPending());
    const api = apiAdapter(BASE_URL);
    api
      .post(`/scheduler/addNew`, data)
      .then(res => {
        if (res.error) {
          dispatch(actions.addSchedulerError(res.error));
          // throw res.error;
        } else {
          dispatch(actions.addSchedulerSuccess(res.data.result));
        }
      })
      .catch(error => {
        dispatch(actions.addSchedulerError(error));
      });
  };
}

export function deleteScheduler(Id) {
  var Id = Id;
  const data = {
    Id,
  };
  return dispatch => {
    dispatch(actions.deleteSchedulerPending());

    const api = apiAdapter(BASE_URL);
    api
      .post(`/scheduler/delete`, data)
      .then(res => {
        if (res.error) {
          dispatch(actions.deleteSchedulerError(res.error));
        } else {
          dispatch(actions.deleteSchedulerSuccess(Id));
        }
      })
      .catch(error => {
        dispatch(actions.deleteSchedulerError(error));
      });
  };
}

export function updateScheduler(
  Id,
  Name,
  EndpointId,
  cronValue,
  EndpointName,
  Execute,
) {
  var ID = Id;
  var Name = Name;
  var ScheduledDateTime = cronValue;
  var execute = Execute;
  var endpointId = EndpointId;
  var endpointName = EndpointName;
  const data = {
    ID,
    Name,
    ScheduledDateTime,
    execute,
    endpointId,
    endpointName,
  };
  return dispatch => {
    dispatch(actions.updateSchedulerPending());
    const api = apiAdapter(BASE_URL);
    api
      .post(`/scheduler/update`, data)
      .then(res => {
        if (res.error) {
          dispatch(actions.updateSchedulerError(res.error));
        } else {
          dispatch(actions.updateSchedulerSuccess(data));
        }
      })
      .catch(error => {
        dispatch(actions.updateSchedulerError(error));
      });
  };
}

export const changeStatus = (Id, status) => {
  var Id = Id;
  var Status = status;
  const data = {
    Id,
    Status,
  };
  return dispatch => {
    dispatch(actions.changeActiveStatusPending());
    // setTimeout(function() {
    //   dispatch(actions.changeActiveStatusSuccess(Id));
    // }, 1000);
    const api = apiAdapter(BASE_URL);
    api
      .post(`/scheduler/status/update`, data)
      .then(res => {
        if (res.error) {
          throw res.error;
        } else {
          dispatch(actions.changeActiveStatusSuccess(Id));
        }
      })
      .catch(error => {
        throw error;
      });
  };
};
