import React, { Component } from 'react';
import MaterialTable, { MTableToolbar } from 'material-table';
import { fetchSchedulers } from '../../Services/scheduler';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Modal from 'react-responsive-modal';
// import Popup from 'reactjs-popup';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import { deleteScheduler, changeStatus } from '../../Services/scheduler';
import Loading from '../Loading';
import ToggleButton from 'react-toggle-button'
import AddBoxIcon from '@material-ui/icons/AddBox';
var cronstrue = require('cronstrue');

const divStyles = {
    fontFamily: 'sans-serif',
    textAlign: 'center',
};
const tableWidth = {
    minWidth: '20%',
};


function MaterialTableDemo(props) {
    var Name = 'Test';
    const [state, setState] = React.useState({
        columns: [
            { title: 'Scheduler Name', field: 'Name' },
            {
                title: 'Scheduled Time',
                field: 'ScheduledDateTime',
                render: rowData => cronstrue.toString(rowData.ScheduledDateTime),
            },

            {
                title: 'Last Execution',
                field: 'LastExecution',
                render: rowData => (
                    <span>{`${rowData.LastExecution.toString().slice(
                        0,
                        10,
                    )} ${rowData.LastExecution.toString().slice(11, 19)}`}</span>
                ),
            },
            // { title: 'Execute URL', field: 'Execute' },
            {
                title: 'Active',
                field: 'Active',
                render: rowData => (
                    <ToggleButton
                        value={
                            rowData.Active == '1'
                                ? true
                                : false
                        }
                        onToggle={(value) => {
                            var Status = 0;
                            if (rowData.Active == '1') {
                                Status = 0;
                            } else {
                                Status = 1;
                            }
                            props.changeStatus(rowData.ID, Status);
                        }} />
                ),
            },
        ],
    });
    return (
        <div>
            <MaterialTable

                style={tableWidth}
                title=""
                columns={state.columns}
                data={props.schedulers}
                components={{
                    Toolbar: props => (
                        <div>
                            <MTableToolbar {...props} />
                            <div style={{ padding: '0px 10px' }}>
                                <Link
                                    to={{
                                        pathname: '/createScheduler',
                                        state: {
                                            schedulerData: {
                                                ScheduledDateTime: '*/1 * * * *',
                                            },
                                            actionType: 'Create',
                                        },
                                    }}
                                >

                                </Link>
                            </div>
                        </div>
                    ),
                }}
                options={{
                    search: true,
                    actionsColumnIndex: -1,
                    headerStyle: {
                        backgroundColor: 'rgb(119,136,153)',
                        color: '#FFF'
                    },
                    rowStyle: rowData => ({
                        backgroundColor: (rowData.tableData.id % 2 !== 0) ? '#EEE' : '#FFF'
                    })

                }}
                actions={[


                    {
                        icon: AddBoxIcon,
                        tooltip: 'Add New Schedular',
                        isFreeAction: true,
                        onClick: (event, rowData) => {
                            props.history.push({
                                pathname: '/createScheduler',
                                state: {
                                    schedulerData: {
                                        ScheduledDateTime: '*/1 * * * *',
                                    },
                                    actionType: 'Create',
                                },
                            })
                        },
                    },

                    {
                        icon: 'edit',
                        tooltip: 'Edit Schedular',
                        onClick: (event, rowData) => (
                            props.history.push({
                                pathname: '/editScheduler',
                                state: {
                                    schedulerData: rowData,
                                    actionType: 'Edit',
                                },
                                actionType: 'Edit',
                            })

                        ),
                    },



                ]}
                editable={{
                    onRowDelete: oldData =>
                        new Promise((resolve, reject) => {
                            setTimeout(() => {
                                {
                                    console.log(oldData);
                                    props.deleteScheduler(oldData.ID);
                                }
                                resolve();
                            }, 1000);

                        }),
                }}

            />
        </div>
    );
}

class Scheduler extends Component {
    componentDidMount() {
        this.props.fetchSchedulers();
    }

    render() {
        return (
            <div>
                {this.props.fetchData === false ? (
                    <Loading />
                ) : (
                        <MaterialTableDemo {...this.props} />
                    )}
            </div>
        );
    }
}

const mapStateToProps = state => ({
    schedulers: state.schedulerReducer.schedulers,
    fetchData: state.schedulerReducer.fetchData,
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            fetchSchedulers: fetchSchedulers,
            deleteScheduler: deleteScheduler,
            changeStatus: changeStatus,
        },
        dispatch,
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Scheduler);
