import * as actions from '../actions/audit-actions';

const { BASE_URL } = require('../utils/Url');
const { apiAdapter } = require('../utils/APIAdapter');


export function fetchAuditTrails(pageSize) {
  return (dispatch) => {
    dispatch(actions.fetchAuditPending());

    const api = apiAdapter(BASE_URL);
    api
      .post(`audit/getAuditsByDateAndUser`, {
        From: "",
        To: "",
        User: "",
        Event: "",
        page: 1,
        pageSize: pageSize

      })
      .then(res => {
        dispatch(actions.fetchAuditSuccess(res.data.result.message));
        dispatch(actions.updateTotalPages(res.data.result.pager.totalPages))

      })
      .catch(error => {
        dispatch(actions.fetchAuditError(error));
      });
  };
}
export function fetchUsers() {
  return (dispatch) => {
    dispatch(actions.fetchAuditPending());
    const api = apiAdapter(BASE_URL);
    api
      .get(`user/getAllUsers`)
      .then(res => {

        dispatch(actions.fetchUserSuccess(res.data.result));


      })
      .catch(error => {

        dispatch(actions.fetchUserError(error));
      });
  };
}
export function fetchAuditTrailsWithFilters(filters) {
  return (dispatch) => {

    const api = apiAdapter(BASE_URL);
    api
      .post(`audit/getAuditsByDateAndUser`, {
        From: filters.From,
        To: filters.To,
        User: filters.User,
        Event: filters.Event,
        page: filters.page,
        pageSize: filters.pageSize,

      })
      .then(res => {
        dispatch(actions.fetchAuditSuccess(res.data.result.message));
        dispatch(actions.updateTotalPages(res.data.result.pager.totalPages))
        dispatch(actions.updateRowsPerPage(res.data.result.pager.pageSize))
      })
      .catch(error => {
        dispatch(actions.fetchAuditError(error));
      });
  };
}