import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { saveNewConnection, updateConnection } from '../../Services/connection';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import ConnectionForm from './ConnectionForm';
import Snackbar from '../Snackbar';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import Swagger from '../Swagger';
import { REQUEST_MAIN, REQUEST_REST } from "../../utils/JsUtils";
import CloseIcon from '@material-ui/icons/Close';

function rand() {
    return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
    const top = 49;
    const left = 49;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

const useStyles = makeStyles(theme => ({
    paper: {
        position: 'absolute',
        minWidth: 650,
        minHeight: 630,
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #f6f6f6',
        boxShadow: theme.shadows[4],
    },
}));

export function MyHeader(props) {
    console.log("PROPS", props);
    return <div className="row" style={{ padding: 5, backgroundColor: "#3f51b5", margin: "auto", color: "white", marginBottom: "-10px", marginLeft: "-2px", marginRight: "-3px", marginTop: "-2px" }}>

        <div className="col-6">
            <DialogTitle id="scroll-dialog-title" onClose={props.handleClose} >Connection Properties</DialogTitle>
        </div>
        <div className="col-6" style={{ textAlign: 'right', marginTop: "15px" }}>
            <div >
                <Button

                    id="closeNewConnection" onClick={() => { props.handleClose(); }}>
                    <CloseIcon></CloseIcon>
                </Button>
            </div>
        </div>
    </div >;
}


export default function SimpleModal(props) {

    const classes = useStyles();
    // getModalStyle is not a pure function, we roll the style only on the first render
    const [modalStyle] = React.useState(getModalStyle);
    const [modalData, setOpen] = React.useState({ open: false, requestData: null, message: { success: false, show: false }, saved: false });
    const [open, setO] = React.useState(false);

    //redux hooks 

    const connections = useSelector(state => ({
        ...state.connections_reducer.connections
    }),
        state => state);

    const editConnectionData = useSelector(state => ({
        ...state.connections_reducer.editConnectionData
    }),
        state => state);




    console.log('connections ->', connections);

    // dispatch action

    const dispatch = useDispatch();


    const handleClose = () => {
        let data = { ...modalData, open: false };
        setOpen(data);
    };

    // invoke swagger from the child component
    const setSendRequest = (requestData) => {
        let data = { ...modalData, requestData, open: true };
        setOpen(data);

    };

    const handleSave = (saveData) => {
        dispatch(saveNewConnection(connections, saveData, (response) => {
            let data = { ...modalData, message: { success: response, show: true }, saved: true };
            handleClose();
            setOpen(data);

        }));
    };


    return (
        <div>
            <Modal
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                open={props.open}
                onClose={props.handleClose}
                title={"New Connection"}
            >
                <div style={modalStyle} className={classes.paper}>



                    <MyHeader handleClose={props.handleClose} />
                    <Divider style={{ marginTop: 10, marginBottom: 10 }} />
                    <ConnectionForm connectionTypes={props.data} setSendRequest={setSendRequest} handleSave={handleSave} handleClose={handleClose} handleParentClose={props.handleClose} />
                </div>
            </Modal>
            <div>
                {modalData.message.show && modalData.message.success ? <Snackbar message={'Connection saved'} severity={'success'} /> : null}
                {modalData.message.show && !modalData.message.success ? <Snackbar message={'Unable to save, Please try again'} severity={'error'} /> : null}
            </div>
            <Swagger swagerData={{ ...modalData }} handleClose={handleClose} handleParentClose={props.handleClose} />
        </div>
    );
}