import React from "react";
import {
    G2,
    Chart,
    Geom,
    Axis,
    Tooltip,
    Coord,
    Label,
    Legend,
    View,
    Guide,
    Shape,
    Facet,
    Util
} from "bizcharts";
import DataSet from "@antv/data-set";
import Divider from '@material-ui/core/Divider';
import graphcss from '../../Containers/Dashboard/Graphs/Graphs.css';

export default class Donut extends React.Component {


    render() {

        const { DataView } = DataSet;
        const { Html } = Guide;
        const data = this.props.data;
        const dv = new DataView();
        dv.source(data).transform({
            type: "percent",
            field: "count",
            dimension: "item",
            as: "percent"
        });
        const cols = {
            percent: {
                formatter: val => {
                    val = (val * 100).toFixed(2) + "%";
                    return val;
                }
            }
        };
        return (
            <div>
                <Chart
                    height={350}
                    data={dv}
                    scale={cols}
                    padding={[0, 0, 0, 0]}
                    forceFit
                >
                    <Coord type={"theta"} radius={0.60} innerRadius={0.55} />
                    <Axis name="percent" />
                    {this.props.legend ? <Legend
                        position="right"
                        offsetY={-window.innerHeight / 2 + 200}
                        offsetX={-90}
                    /> : null}
                    <Tooltip
                        showTitle={false}
                        itemTpl="<li><span style=&quot;background-color:{color};&quot; class=&quot;g2-tooltip-marker&quot;></span>{name}: {value}</li>"
                    />
                    <Guide>
                        <Html
                            position={["50%", "50%"]}
                            html={`<div style=&quot;color:#8c8c8c;font-size:1.16em;text-align: center;width: 10em;&quot;><br><span style=&quot;color:#262626;font-size:2.5em&quot;>Total:${this.props.total ? this.props.total : null}</span></div>`}
                            alignX="middle"
                            alignY="middle"
                        />
                    </Guide>
                    <Geom
                        type="intervalStack"
                        position="percent"
                        color="item"
                        tooltip={[
                            "item*percent",
                            (item, percent) => {
                                percent = Math.round(percent * this.props.total);
                                return {
                                    name: item,
                                    value: percent
                                };
                            }
                        ]}
                        style={{
                            lineWidth: 1,
                            stroke: "#fff"
                        }}
                    >
                        <Label
                            content="percent"
                            formatter={(val, item) => {
                                return item.point.item + ": " + val;
                            }}
                        />
                    </Geom>
                </Chart>
                <div className="heading_color">
                    <Divider style={{ marginTop: 19, marginBottom: 19 }} />
                    <div className="heading_graph">
                        {this.props.title}
                    </div>
                </div>
            </div>
        );
    }
}
