import React from 'react';
import Logs from "../../Components/DowloadLogs";

function DownloadLogs(props) {
    return (
        <div>
            <Logs/>
        </div>
    )
}


export default DownloadLogs

