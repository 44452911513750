import {
    FETCH_LOGS_PENDING,
    FETCH_LOGS_SUCCESS,
    FETCH_LOGS_ERROR,
    FILE_SYSTEM_HEALTH
} from '../action-types/logs-action-types';

export const fetchLogsPending = () => {
    return {
        type: FETCH_LOGS_PENDING,
    };
};

export const fetchLogsSuccess = logs => {
    return {
        type: FETCH_LOGS_SUCCESS,
        payload: {logs},
    };
};

export const fetchLogsError = error => {
    return {
        type: FETCH_LOGS_ERROR,
        payload: {error},
    };
};

export const setSystemHealth = (flag) => {
  return {
    type: FILE_SYSTEM_HEALTH,
    payload: flag
  };
};
