import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setConnections, deleteConnection, editConnection } from '../../Services/connection';
import Loading from '../../Components/Loading';
import ConnectionView from '../../Components/Connections';
import ShowError from '../../Components/ShowError';
import Snackbar from '../../Components/Snackbar';


class ConnectionWidget extends Component {


    state = {
        showError: false,
        showSuccess: false
    };

    componentDidMount() {
        this.props.setConnections();
    }



    handleDelete = (deleteData) => {
        this.props.deleteConnection(deleteData, this.props.connections, (response) => {
            if (response) {
                this.setState({
                    showSuccess: true
                });
                return;
            }
        });
    };

    handleEdit = (editData) => {
        this.props.editConnection(editData);
    };


    render() {

        if (this.props.processing) {
            return (
                <div>
                    <Loading />
                </div>
            );
        } else if (this.props.connections && this.props.connections.length) {
            return (

                < div >
                    {this.state.showSuccess ?
                        <Snackbar message={'Successfully Done'} severity={"success"} /> : null
                    }
                    <ConnectionView data={this.props.connections} handleEdit={this.handleEdit} handleDelete={this.handleDelete} />
                </div >
            );
        } else {
            return (
                <div>
                    {this.props.error && <div>
                        <ShowError />
                    </div>}
                </div>
            );
        }
    }
};


const mapStateToProps = (state) => {
    return {
        connections: state.connections_reducer.connections,
        error: state.connections_reducer.error,
        processing: state.connections_reducer.processing
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setConnections: () => dispatch(setConnections()),
        deleteConnection: (data, old, cb) => dispatch(deleteConnection(data, old, cb)),
        editConnection: (data) => dispatch(editConnection(data)),
    };
};

// Connections.propTypes = {
//     count: PropTypes.number
// };

export default connect(mapStateToProps, mapDispatchToProps)(ConnectionWidget);