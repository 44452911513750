import React, { PureComponent } from 'react'

export default class NoMatch extends PureComponent {
    render() {
        return (
            <div class="mainbox">
                <div class="err">4</div>
                <i class="far fa-question-circle fa-spin"></i>
                <div class="err2">4</div>
                <div class="msg">PAGE NOT FOUND<p>Let's go <a id="homelink" href="/dashboard">home</a> and try from there.</p></div>
            </div>
        )
    }
}
