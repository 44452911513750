import {
    FETCH_AUDIT_PENDING,
    FETCH_AUDIT_ERROR,
    FETCH_AUDIT_SUCCESS,
    FETCH_USER_ERROR,
    FETCH_USER_SUCCESS,
    UPDATE_TOTAL_PAGES,
    UPDATE_ROWS_PER_PAGE
} from '../action-types/audit-action-types';

const initialState = {
    fetchData: false,
    audits: [],
    loading: false,
    error: null,
    user: [],
    totalPages: 0,
    rowsPerPage: 10

};

export function auditReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_AUDIT_PENDING:
            return {
                ...state,
                loading: true
            };

        case FETCH_AUDIT_SUCCESS:

            return {
                ...state,
                loading: false,
                audits: action.payload,
                fetchData: true,

            };
        case FETCH_AUDIT_ERROR:
            return {
                ...state,
                error: action.error,
            };
        case FETCH_USER_SUCCESS:
            
            return {
                ...state,
                loading: false,
                user:action.payload
            };
        case FETCH_USER_ERROR:
            return {
                ...state,
                error: action.error,
            };
        case UPDATE_TOTAL_PAGES:
            return {
                ...state,
                totalPages: action.payload
            }
        case UPDATE_ROWS_PER_PAGE:
            return {
                ...state,
                rowsPerPage: action.payload
            }
        default:
            return state;
    }
}