import React, { Component } from "react";
import ActiveConnections from "./ActiveConnections";
import ConnectionEndpoint from "./ConnectionEndpoint";

export default class EndpointComparator extends Component {
  render() {
    return (
      <div className="row" >
        <div className="col-md-6">
          <ActiveConnections />
        </div>

        <div className="col-md-6">
          <ConnectionEndpoint />
        </div>
      </div>
    );
  }
}
