import React, { Component } from 'react';
import { fetchEndpoints, setActiveEndpoint, setConnectionsOfEndpoint } from "../../Services/endpoint";
import { connect } from "react-redux";
import ShowError from "../ShowError";
import Loading from "../Loading";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";

class EndpointDrop extends Component {
    constructor(props) {
        super(props);
        this.handleTypeChange = this.handleTypeChange.bind(this);
    }

    componentDidMount() {
        this.props.fetchEndpoints();
    }

    state = {
        selectedEndpoint: this.props.endpoints[0] ? this.props.endpoints[0] : {}
    };
    handleTypeChange = (event, child) => {
        let data = { Name: event.target.value, Id: child.props.id };
        this.setState({ selectedEndpoint: data });
        this.props.setActiveEndpoint(data);
        this.props.setConnectionsOfEndpoint(null, data.Name);
    };

    // load when component is update but avoid to set state
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.endpoints !== this.props.endpoints) {
            if (this.props.endpoints.length) {
                this.props.setActiveEndpoint(this.props.endpoints[0]);
                this.props.setConnectionsOfEndpoint(null, this.props.endpoints[0].Name);
            }
        }
    }


    showDropDown = () => {
        return (
            <TextField
                select
                label="Select Endpoint"
                name="selectedEndpoint"
                variant="outlined"
                value={this.state.selectedEndpoint.Name ? this.state.selectedEndpoint.Name : this.props.endpoints[0].Name}
                onChange={this.handleTypeChange}
                style={{ width: '100%', marginTop: 15 }}
            >
                {this.props.endpoints.map(option => (
                    <MenuItem key={option.Id} id={option.Id} value={option.Name}>
                        {option.Name}
                    </MenuItem>
                ))}
            </TextField>
            // <select
            //     style={{ width: '100%', marginTop: 15 }}
            //     className="browser-default custom-select"
            //     onChange={this.handleTypeChange}
            // >
            //     {/* <option selected disabled>
            //         Choose an Endpoint...
            //     </option> */}
            //     {this.props.endpoints.map(row => {
            //         return (
            //             <option key={row.Id}
            //                 value={row.Name}
            //                 data-key={row.Id}
            //             >
            //                 {row.Name}
            //             </option>
            //         );
            //     })}
            // </select>
        );
    };

    render() {
        if (this.props.processingEndpoint) {
            return (
                <div>
                    <Loading />
                </div>
            );
        } else if (this.props.endpoints && this.props.endpoints.length) {
            return <div style={this.props.style}>{this.showDropDown()}</div>;
        } else {
            return (
                <div>
                    <div>
                        <ShowError />
                    </div>
                </div>
            );
        }
    }
}


const mapStateToProps = (state) => {
    return {
        endpoints: state.endpoints.endpoints,
        processingEndpoint: state.endpoints.processingEndpoint,
        error: state.endpoints.error
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchEndpoints: (val) => dispatch(fetchEndpoints(val)),
        setActiveEndpoint: (endpoint) => dispatch(setActiveEndpoint(endpoint)),
        setConnectionsOfEndpoint: (data, endPointName) => dispatch(setConnectionsOfEndpoint(data, endPointName))
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(EndpointDrop);