import {
    FETCH_CONFIGURATIONS_PENDING,
    FETCH_CONFIGURATIONS_SUCCESS,
    FETCH_CONFIGURATIONS_ERROR,
    DELETE_CONFIGURATION_PENDING,
    DELETE_CONFIGURATION_SUCCESS,
    DELETE_CONFIGURATION_ERROR,
    TOGGLE_CONFIGURATIONS_DIALOG,
    ADD_CONFIGURATION_ERROR,
    ADD_CONFIGURATION_PENDING,
    ADD_CONFIGURATION_SUCCESS,
    UPDATE_CONFIGURATION_PENDING,
    UPDATE_CONFIGURATION_SUCCESS,
    UPDATE_CONFIGURATION_ERROR,
    CHANGE_STATUS_PENDING,
    CHANGE_STATUS_ERROR,
    CHANGE_STATUS_SUCCESS,
    CHANGE_ISAUTHENTICATED,
    TOGGLE_DROPDOWN_STATE,
    CONFIG_HEALTH
} from '../action-types/configurations-action-types';

const initialState = {
    isAuthenticated: false,
    fetchData: false,
    pending: false,
    configurations: [],
    error: null,
    toggleConfigurationsDialog: false,
    dropDownFlagDisabled: false,
    configHealth: false
};

export function configurationsReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_CONFIGURATIONS_PENDING:
            return {
                ...state,
                pending: true,
            };
        case FETCH_CONFIGURATIONS_SUCCESS:
            return {
                ...state,
                pending: false,
                configurations: action.payload.configurations,
                fetchData: true,
            };
        case FETCH_CONFIGURATIONS_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error,
            };
        case ADD_CONFIGURATION_PENDING:
            return {
                ...state,
                pending: true,
            };
        case ADD_CONFIGURATION_SUCCESS:
            return {
                ...state,
                configurations: state.configurations.concat(
                    action.payload.configuration,
                ),
                pending: false,
            };
        case ADD_CONFIGURATION_ERROR:
            return {
                ...state,
                error: action.error,
            };
        case TOGGLE_CONFIGURATIONS_DIALOG:
            return {
                ...state,
                toggleConfigurationsDialog: action.payload.configuration,
            };
        case DELETE_CONFIGURATION_PENDING:
            return {
                ...state,
                pending: true,
            };
        case DELETE_CONFIGURATION_SUCCESS:
            var configurations = state.configurations.filter(function (obj) {
                return obj.Id !== action.payload.id;
            });
            return {
                ...state,
                pending: false,
                configurations: configurations,
            };
        case DELETE_CONFIGURATION_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error,
            };
        case UPDATE_CONFIGURATION_PENDING:
            return {
                ...state,
                pending: true,
            };
        case UPDATE_CONFIGURATION_SUCCESS:
            var newConfigurations = [];
            newConfigurations = state.configurations.slice();
            var index = newConfigurations.findIndex(
                x => x.Id === action.payload.updateData.Id,
            );
            newConfigurations[index].Name = action.payload.updateData.Name;
            newConfigurations[index].Value = action.payload.updateData.Value;
            newConfigurations[index].Description = action.payload.updateData.Description;
            newConfigurations[index].Encrypted = action.payload.updateData.Encrypted;
            return {
                ...state,
                pending: false,
                configurations: newConfigurations,
            };
        case UPDATE_CONFIGURATION_ERROR:
            return {
                ...state,
                error: action.payload.error,
            };
        case CHANGE_STATUS_PENDING:
            return {
                ...state,
                pending: true,
            };
        case CHANGE_STATUS_SUCCESS:
            var newConfigurations = [];
            newConfigurations = state.configurations.slice();
            var index = newConfigurations.findIndex(x => x.Id === action.payload.Id);
            newConfigurations[index].Active = !newConfigurations[index].Active;
            return {
                ...state,
                configurations: newConfigurations,
            };
        case CHANGE_ISAUTHENTICATED:
            return {
                ...state,
                isAuthenticated: action.payload.isAuthenticated,
            };
        case TOGGLE_DROPDOWN_STATE:
            return {
                ...state,
                dropDownFlagDisabled: action.payload
            };
        case CONFIG_HEALTH:
            return {
              ...state,
                configHealth: action.payload

            };
        default:
            return state;
    }
}
