import React, { Component } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Grid from '@material-ui/core/Grid';
import { NotificationManager } from "react-notifications";
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Select from 'react-select';
const axios = require("axios");
const { FILE_URL } = require("../../utils/Url");

const useStyles = makeStyles(theme => ({
    container: {
        display: "flex",
        flexWrap: "wrap"
    },

    formControl: {
        margin: theme.spacing(1)
        // minWidth: 120
        // width: '100%',
    }
}));

class DownoadXsl extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: true,
            files: [],
            fetchFiles: false,
            downloadPending: false,
            fileName: "",
            address: "",
            fileName: ""
        };
    }

    updateSelectValue = value => {
        this.setState({
            fileName: value
        });
    };

    // handleMenuItemClick = event => {
    //     this.setState({
    //         fileName: event.target.value
    //     });
    //     this.props.setFile(event.target.value);
    // };

    fetchFiles = () => {
        let files = [];
        axios.get(`${FILE_URL}/filestorage/getXsl`).then(res => {
            console.log("res", res)
            res.data.result.files.map(row => {
                files.push({
                    value: row,
                    label: row
                })
            });
            this.setState({
                files: files,
                fetchFiles: true
            });
        }).catch(error => console.log(error))
    };

    componentDidMount() {
        this.fetchFiles();
    }

    downloadFile = () => {
        if (this.state.fileName === "")
            NotificationManager.error("Please select a file", "Error");
        else
            window.open(
                FILE_URL + "/filestorage/xsl/download?fileName=" + this.state.fileName + "&User=" + localStorage.getItem("UserInfo"),
                "_blank"
            );
    };

    render() {
        // const { classes } = this.props;
        return (
            <div>
                <Grid id="overidePaperElevation" container spacing={2}>
                    <Grid id="overidePaperElevation" item xs={12} sm={12} lg={12} xl={12}>
                        <Typography className="Grid-heading" variant="h4"> Download XSLT </Typography>
                        <Divider className="divider" />
                    </Grid>
                    <Grid id="overidePaperElevation" item xs={12} sm={12} lg={12} xl={12}>
                        <Select onChange={(val) => this.updateSelectValue(val.value)} options={this.state.files} />
                    </Grid>
                    <Grid id="overidePaperElevation" item xs={12} sm={12} lg={12} xl={12}>
                        <button style={{ backgroundColor: "#3f51b5", alignContent: "left" }} onClick={this.downloadFile} className="btn btn-primary btn-sm">
                            Download
                        </button>
                    </Grid>
                    {/* <select
                        style={{ width: "100%" }}
                        className="browser-default custom-select"
                        onChange={this.updateSelectValue}
                    >
                        <option selected disabled>
                            File Name
                    </option>
                        {this.state.files.map(row => {
                            return (
                                <option selected={this.state.fileName === row} key={row} value={row}>
                                    {row}
                                </option>
                            );
                        })}
                    </select>
                    <br />
                    <br />
                    <br /> */}

                </Grid>
            </div>
        );
    }
}

export default DownoadXsl;
