import React, { Component } from 'react';
import DonutPie from '../../../Components/DonutPie';
import { connect } from "react-redux";
import Loading from "../../../Components/Loading";
import ShowError from "../../../Components/ShowError";
import { setMessageStatusGraph } from '../../../Services/messagequeue';

class MessageGraph extends Component {

    componentDidMount() {
        this.props.setMessageStatusGraph();
    }

    transform = (stats) => {
        let total = 0;
        let data = stats.map((stats) => {
            if (stats.Name == 'Completed' || stats.Name == 'Failed' || stats.Name == 'Error' || stats.Name == 'New') {
                total = total + stats.Value;

                return {
                    item: stats.Name,
                    count: stats.Value
                };
            }
        });
        return {
            data,
            total
        };
    };

    render() {
        if (this.props.processing) {
            return (
                <div>
                    <Loading />
                </div>
            );
        } else if (this.props.messageStats) {
            if (!this.props.messageStats.length) {
                return <div>No Data</div>;
            }

            let result = this.transform(this.props.messageStats);

            return (
                < div>
                    <DonutPie data={result.data} total={result.total} title={"Messages"} />
                </div>

            );

        } else {
            return (
                <div>
                    {this.props.error && <div>
                        <ShowError />
                    </div>}
                </div>
            );
        }

    }
}


const mapStateToProps = (state) => {
    return {
        processing: state.messages.messagesStatsLoading,
        error: state.messages.messageStatsError,
        messageStats: state.messages.messageStats
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setMessageStatusGraph: () => dispatch(setMessageStatusGraph())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(MessageGraph);


MessageGraph.propTypes = {};
