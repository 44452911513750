import React from "react";
import {
    G2,
    Chart,
    Geom,
    Axis,
    Tooltip,
    Coord,
    Label,
    Legend,
    View,
    Guide,
    Shape,
    Facet,
    Util
} from "bizcharts";
import DataSet from "@antv/data-set";
import Divider from "@material-ui/core/Divider";
import graphcss from "../../Containers/Dashboard/Graphs/Graphs.css";

class Groupedcolumn extends React.Component {
    render() {
        const data = this.props.data;
        const ds = new DataSet();
        const dv = ds.createView().source(data);
        dv.transform({
            type: "fold",
            fields: this.props.fields,
            // 展开字段集
            key: "月份",
            // key字段
            value: "月均降雨量" // value字段
        });
        return (
            <div>
                <Chart height={400} data={dv} forceFit>
                    <Axis name="月份" />
                    <Axis name="月均降雨量" />
                    <Legend />
                    <Tooltip
                        crosshairs={{
                            type: "y"
                        }}
                    />
                    <Geom
                        type="interval"
                        position="月份*月均降雨量"
                        color={"name"}
                        adjust={[
                            {
                                type: "dodge",
                                marginRatio: 1 / 32
                            }
                        ]}
                    />
                </Chart>
                <div className="heading_color">
                    <Divider style={{ marginTop: 19, marginBottom: 22 }} />
                    <div className="heading_graph">
                        {this.props.title}
                    </div>
                </div>
            </div>
        );
    }
}

export default Groupedcolumn;