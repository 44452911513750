import user_reducer from "./user-reducer";
import {combineReducers} from "redux";
import {connectRouter} from 'connected-react-router';
import {serveysReducer} from './serveys-reducer';
import {serveyDashboardReducer} from './serveyDashboard-reducer';
import {configurationsReducer} from './configurations-reducer';
import messageReducer from './messagequeue-reducer';
import messageStatusReducer from './messagestatus-reducer';
import {endpointReducer} from './endpoint-reducer';
import {xmlReducer} from './messageviewer-reducer';
import {schedulerReducer} from './scheduler-reducer';
import {LogsReducer} from './logs-reducer';
import connections_reducer from './connections-reducer';
import notification_reducer from './notifications-reducers';
import {auditReducer} from './audit-reducer';
import history from '../utils/history';

const reducer = combineReducers({
    user_reducer,
    connections_reducer,
    serveys: serveysReducer,
    messages: messageReducer,
    statuses: messageStatusReducer,
    serveyDashboard: serveyDashboardReducer,
    configurations: configurationsReducer,
    endpoints: endpointReducer,
    xml: xmlReducer,
    schedulerReducer: schedulerReducer,
    logsReducer: LogsReducer,
    auditReducer:auditReducer,
    notifications_reducer:notification_reducer,
    router: connectRouter(history),
});

export default reducer;