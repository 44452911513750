import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBarComponent from '../../Components/AppBar';
import NavigationComponent from '../../Components/Navigation';
import ConfigurationsComponent from '../../Components/Configurations';
import MessageComponent from '../../Components/Message';
import StatusOccurences from '../../Components/MessageStatus';
import { makeStyles } from '@material-ui/core/styles';
import SchedulerComponent from '../../Components/Scheduler';
import clsx from 'clsx';
import PopupContent from '../../Components/Popup';
import LogsComponent from '../../Components/Logs';
import UserDashbaord from '../../Components/Dashboard';
import TimelineComponent from '../../Components/Timeline';
import DashboardComponent from '../Dashboard';
import LoggerFilesComponent from '../../Components/LoggerFiles/';
import Connections from '../Connections';
import AddNotification from '../Notifications/AddNotification';
import EditNotification from '../Notifications/EditNotification';
import Notifications from '../Notifications/index';
import Audits from '../AuditTrail/index';
import UserMangement from '../UserManagement';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  NotificationContainer,
  NotificationManager,
} from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import PrivateRoute from '../../Components/PrivateRoute';
import Endpoints from "../Endpoints";
import Mappings from "../Mappings";
import DownloadLogs from '../DownloadLogs';

const drawerWidth = 240;
const useStyles = makeStyles(theme => ({
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 8px',
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
}));

const MaterialComponent = data => {
  const classes = useStyles();
  const { toggleNavigation } = data;
  return (
    <div
      className={`${clsx(classes.content, {
        [classes.contentShift]: toggleNavigation,
      })} admin-content `}
    >
      <div className={classes.drawerHeader} />
      <Switch>
        <Redirect key={"Redirect"} exact from="/" to="/dashboard" />
        <Route exact path="/dashboard" component={DashboardComponent} />
        {/* <Route exact path="/" component={DashboardComponent} /> */}
        {/* <Route path="/serveys" exact component={ServeyComponent} /> */}
        <Route path="/messagequeue" exact component={MessageComponent} />
        <Route
          path="/messagestatus/:Id"
          exact
          component={StatusOccurences}
        />
        <Route
          path="/configurations"
          exact
          component={ConfigurationsComponent}
        />
        {/* <Route path="/survey/:Id" component={DashboardComponent} /> */}
        <Route path="/scheduler" component={SchedulerComponent} />
        <Route path="/createScheduler" component={PopupContent} />
        <Route path="/editScheduler" component={PopupContent} />
        {/* <Route path="/logs" component={LogsComponent} /> */}
        <Route path="/Dashboard" component={UserDashbaord} />
        <Route path="/timeline/:Id" component={TimelineComponent} />
        <Route path='/connections' component={Connections} />
        <Route path='/endpoints' component={Endpoints} />
        <Route path='/AddNotification' component={AddNotification} />
        <Route path='/editNotification' component={EditNotification} />
        <Route path='/notification' component={Notifications} />
        <Route path='/mappings' component={Mappings} />
        <Route path='/logs' component={DownloadLogs} />
        <Route path='/audits' component={Audits} />
        <Route path='/users' component={UserMangement} />
        <Route
          path="/loggerFile/Download"
          component={LoggerFilesComponent}
        />
        {/* <Route path="/login" exact component={LoginComponent} /> */}
      </Switch>
    </div>
  );
};

const AdminContainer = (props) => {
  const [toggleNavigation, setToggleNavigation] = React.useState(true)

  const onToggleNavigation = () => {
    setToggleNavigation(!toggleNavigation)
  };

  return (
    <>
      {props.loginStatus &&
        <div className="admin-wrapper">
          <CssBaseline />
          <NotificationContainer />
          <React.Fragment>
            <AppBarComponent
              toggleNavigation={toggleNavigation}
              onToggleNavigation={onToggleNavigation}
            />
            <NavigationComponent
              toggleNavigation={toggleNavigation}
              onToggleNavigation={onToggleNavigation}
            />
            <MaterialComponent toggleNavigation={toggleNavigation} />
          </React.Fragment>
        </div>
      }
    </>

  )
}

const mapStateToProps = state => ({
  loginStatus: state.user_reducer.userLoggedIn
});

export default connect(mapStateToProps, null)(AdminContainer);