import {
    FETCH_CONFIGURATIONS_PENDING,
    FETCH_CONFIGURATIONS_SUCCESS,
    FETCH_CONFIGURATIONS_ERROR,
    DELETE_CONFIGURATION_PENDING,
    DELETE_CONFIGURATION_SUCCESS,
    DELETE_CONFIGURATION_ERROR,
    ADD_CONFIGURATION_PENDING,
    ADD_CONFIGURATION_SUCCESS,
    ADD_CONFIGURATION_ERROR,
    UPDATE_CONFIGURATION_PENDING,
    UPDATE_CONFIGURATION_SUCCESS,
    UPDATE_CONFIGURATION_ERROR,
    CHANGE_STATUS_PENDING,
    CHANGE_STATUS_SUCCESS,
    CHANGE_STATUS_ERROR,
    CHANGE_ISAUTHENTICATED,
    TOGGLE_CONFIGURATIONS_DIALOG,
    TOGGLE_DROPDOWN_STATE,
    CONFIG_HEALTH
} from '../action-types/configurations-action-types';


export const fetchConfigurationsPending = () => {
    return {
        type: FETCH_CONFIGURATIONS_PENDING,
    };
};

export const fetchConfigurationsSuccess = configurations => {
    return {
        type: FETCH_CONFIGURATIONS_SUCCESS,
        payload: {configurations},
    };
};

export const fetchConfigurationError = error => {
    return {
        type: FETCH_CONFIGURATIONS_ERROR,
        payload: error,
    };
};

export const deleteConfigurationPending = () => {
    return {
        type: DELETE_CONFIGURATION_PENDING,
    };
};

export const deleteConfigurationSuccess = id => {
    return {
        type: DELETE_CONFIGURATION_SUCCESS,
        payload: {id},
    };
};

export const deleteConfigurationError = error => {
    return {
        type: DELETE_CONFIGURATION_ERROR,
        payload: error,
    };
};

export const addConfigurationPending = () => {
    return {
        type: ADD_CONFIGURATION_PENDING,
    };
};

export const addConfigurationSuccess = configuration => {
    return {
        type: ADD_CONFIGURATION_SUCCESS,
        payload: {configuration},
    };
};

export const addConfigurationError = error => {
    return {
        type: ADD_CONFIGURATION_ERROR,
        payload: error,
    };
};

export const updateConfigurationPending = () => {
    return {
        type: UPDATE_CONFIGURATION_PENDING,
    };
};

export const updateConfigurationSuccess = updateData => {
    debugger;
    return {
        type: UPDATE_CONFIGURATION_SUCCESS,
        payload: {updateData},
    };
};

export const changeActiveStatusPending = () => {
    return {
        type: CHANGE_STATUS_PENDING,
    };
};

export const changeActiveStatusSuccess = Id => {
    return {
        type: CHANGE_STATUS_SUCCESS,
        payload: {Id},
    };
};

export const changeActiveStatusError = error => {
    return {
        type: CHANGE_STATUS_ERROR,
        payload: error,
    };
};

export const changeAuthentication = isAuthenticated => {
    return {
        type: CHANGE_ISAUTHENTICATED,
        payload: {isAuthenticated},
    };
};

export const updateConfigurationError = () => {
    return {
        type: UPDATE_CONFIGURATION_ERROR,
        payload: {error: 'error'},
    };
};

export const setDropdownState = (flag) => {
    return {
        type: TOGGLE_DROPDOWN_STATE,
        payload: flag,
    };
};

export const setConfigHealth = (data) => {
    return {
        type: CONFIG_HEALTH,
        payload: data
    };
};

