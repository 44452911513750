import * as actions from '../action-types/user-action-types';

const getActiveUser = () => {
    let userStr = sessionStorage.getItem('activeUser');
    if (userStr) return JSON.parse(userStr);
    return null;
};


const initState = () => {
    return {
        roles:[
            {
                Id:1,Name:"Admin"
            },
            {
                Id:2,Name:"User"
            }
        ],
        processing: false,
        message: null,
        error: null,
        formData: null,
        users: [],
        activeUser: getActiveUser(),
        userLoggedIn:false,

    };
};

const user_reducer = (state = initState(), action) => {
    let newState = {...state};

    switch (action.type) {

        case actions.PROCESSING:
            setProcessing(newState, action.payload);
            break;

        case actions.REGISTER:
            userData(newState, action.payload);
            break;
            
        case actions.FETCH_USER:
            return {
                ...state,
                users:action.payload.users
            }

        case actions.UPDATE_USER:
            var newUsers = [];
            newUsers = state.users.slice();
            var index = newUsers.findIndex(
                x => x.Id === action.payload.user.Id,
            );
            newUsers[index] = action.payload.user;
            return {
                ...state,
                processing:false,
                users:newUsers,
            };
        
        case actions.ERROR:
            return {
                ...state,
                error: action.payload.error
            }
        
        case actions.ADD_USER:
            return{
                ...state,
                processing:false,
                users:[...newState.users,action.payload.user]
            }

        case actions.LOGIN:
            return {
                ...state,
                userLoggedIn: action.payload.loginStatus
            }

        default :
            break;
    }

    return newState;
};


const userData = (state, userData) => {
    state.users = userData;
};

const setProcessing = (state, processing) => {
    state.processing = processing;
};

export default user_reducer;

