import React, { Component } from "react";
import { connect } from "react-redux";
import Link from '@material-ui/core/Link';

import axios from 'axios';
import {
  Grid,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  TextareaAutosize,
  Chip,
  Switch,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
} from "@material-ui/core";
import { withRouter, Redirect } from 'react-router-dom';
import moment from "moment";
import { containers } from "../../utils/style";
import {
  fetchNotificationEvents,
  SetNotificationTags,
  addNotification,
  getEventName
} from "../../Services/notification";
import ToggleButton from 'react-toggle-button'
import { Snackbar } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
class AddNotification extends Component {
  state = {
    eventTrigger: null,
    recipientRole: null,
    notificationName: "",
    Cc: "",
    Bcc: "",
    body: "",
    reminderDays: null,
    activateNotification: true,
    DueDate: null,
    currentTag: {},
    Subject: "",
    To: "",
    cursorPos: null,
    showErr: false,
    notificationSubject: "",
    subjectFlag: false,
    emailFields:false,
    allFieldsFilled:false,
    errorMsg:"Please fill All the fields completely"
  };

  componentDidMount() {
    this.props.fetchNotificationEvents();
  }

  handleChange = (event) => {
    console.log([event.target.name],event.target.value);
    this.setState({ [event.target.name]: event.target.value });
  };

  handleBodyChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
      cursorPos: event.target.selectionStart,
    });
  };

  addExtraIndex = (event) => {
    this.setState({
      cursorPos: event.target.selectionStart - 1,
    });
  };
  addExtraIndexSubject = (event) => {
    this.setState({
      cursorPos: event.target.selectionStart + 1,
    });
  };
  validateEmail=(email)=>{
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email))
    {
      return true;
    }else{
      return false;
    }
  }
  validateFields=()=>{
    var toArr = this.state.To.split(';');
    var CcArr=[];
    var BccArr=[];
    if(this.state.Cc!=""){
      CcArr=this.state.Cc.split(';');
    }
    if(this.state.Bcc!=""){
      BccArr=this.state.Bcc.split(';');
    }
    
    if(this.state.eventTrigger==null){
      this.setState({
        errorMsg:"Select An Event To Proceed"
      })
      return false;
    }
    if(this.state.notificationName==""){
      this.setState({
        errorMsg:"Please Enter a Notification Name"
      })
      return false;
    }
    for(var x of toArr){
      if(!this.validateEmail(x)){
        this.setState({
          errorMsg:"Please Enter a Valid Email Recepient Address"
        })
        return false;
      }
    }
    for(var x of CcArr){
      if(!this.validateEmail(x)){
        this.setState({
          errorMsg:"Enter a valid Email In Cc"
        })
        return false;
      }
    }
    for(var x of BccArr){
      if(!this.validateEmail(x)){
        this.setState({
          errorMsg:"Enter a valid Email In BCc"
        })
        return false;
      }
    }
    if(this.state.Subject==""){
      this.setState({
        errorMsg:"Subject should not be empty"
      })
      return false;
    }
    if(this.state.body==""){
      this.setState({
        errorMsg:"Body should not be empty"
      })
      return false;
    }
  
    return true;
  }
  onBlurEvent = async () => {
    const eventName = await getEventName(this.state.eventTrigger);
    if (eventName != false) {
      this.props.SetNotificationTags(eventName);
    }

  };
  setFlag = () => {
    this.setState({
      subjectFlag: true,
    });
  };
  onBlurRoles = () => {
    this.props.fetchNotificationTags(this.state.recipientRole);
  };

  handleActivateNotification = (event) => {
    this.setState({ [event.target.name]: event.target.checked });
  };

  handleCancel = () => {
    this.props.history.push(`/notification`);
  };
  unsetAllFieldsFilled=()=>{
    this.setState({
      allFieldsFilled:false
    })
  }
  handleAddNotification = async () => {

    if(!this.validateFields()){
      this.setState({
        allFieldsFilled:true
      })
      return false;
    } 

    let formData = {
      EventId: this.state.eventTrigger,
      Code: this.state.notificationName.replace(/\s/g, ""),
      Name: this.state.notificationName,
      To: this.state.To,
      CC: this.state.Cc,
      BCC: this.state.Bcc,
      Subject: this.state.Subject,
      Body: this.state.body,
      isActive: this.state.activateNotification ? 1 : 0,
    };

    const res = await addNotification(formData);
    if (res) {
      this.props.history.push(`/notification`);
    } else {
      this.setState({
        showErr: true
      });
    }

  }
  openFields=()=>{
    this.setState({
      emailFields:!this.state.emailFields
    })
  }
  toggleFlag = () => {
    this.setState({
      subjectFlag: false,
    });
  };
  handleTagClick = (tag) => {
    if (this.state.subjectFlag) {
      let cursorPosition = this.state.cursorPos;
      console.log(cursorPosition);
      debugger;
      let textBeforeCursorPosition = this.state.Subject.substring(
        0,
        cursorPosition
      );
      let textAfterCursorPosition = this.state.Subject.substring(
        cursorPosition,
        this.state.Subject.length
      );
      this.setState({
        currentTag: tag,
        Subject: `${textBeforeCursorPosition} [${tag}] ${textAfterCursorPosition}`,
      });
    } else {
      let cursorPosition = this.state.cursorPos;
      let textBeforeCursorPosition = this.state.body.substring(
        0,
        cursorPosition
      );
      let textAfterCursorPosition = this.state.body.substring(
        cursorPosition,
        this.state.body.length
      );
      this.setState({
        currentTag: tag,
        body: `${textBeforeCursorPosition} [${tag}] ${textAfterCursorPosition}`,
      });
    }
  };
  render() {
    const titleContainer = {
      marginTop: 30,
    };
    return (
      <Grid container>
   


        <Grid container style={{paddingLeft:30}} justify="left">
        
          <Grid container justify="left" style={titleContainer}>
            
            <Grid item xs={6} >
              <FormControl fullWidth>
                
                <TextField
                  select
                  variant="outlined"
                  className="form-control"
                  name="eventTrigger"
                  label="Event Trigger"
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={this.state.eventTrigger || ""}
                  onChange={this.handleChange}
                  onBlur={this.onBlurEvent}
                >
                  {this.props.notificationEvents &&
                    this.props.notificationEvents.map((data) => {
                      return <MenuItem value={data.Id}>{data.Name}</MenuItem>;
                    })}

                </TextField>
              </FormControl>
            </Grid>
          </Grid>

          <Grid container justify="left" >
            <Grid container justify="left" style={titleContainer}>
              
              <Grid xs={6}>
                <TextField
                  id={"notificationNameText"}
                  label="Name"
                  className="form-control"
                  variant="outlined"
                  fullWidth
                  name="notificationName"
                  onChange={this.handleChange}
                  type="text"
                  value={this.state.notificationName || ""}
                />
              </Grid>
            </Grid>

            <Grid container justify="left" style={titleContainer}>
              
              <Grid xs={6} style={{float:"left"}}>
                <TextField
                  id={"notificationNameText"}
                  label="To"
                  className="form-control"
                  variant="outlined"
                  fullWidth
                  name="To"
                  onChange={this.handleChange}
                  type="text"
                  value={this.state.To || ""}
                />
              </Grid>
              <Grid container justify="left" style={titleContainer}>
             
              <Grid item xs={6}>
                <Link
                  component="button"
                  variant="body2"
                  onClick={this.openFields}
                >
                  Cc and Bcc
</Link>
              </Grid>

            </Grid>
            
            {this.state.emailFields && <Grid container justify="left" style={{marginTop:"10px"}}>
        
              
              <Grid xs={6}>
                <TextField
                  id={"notificationNameText"}
                  label="Cc"
                  className="form-control"
                  variant="outlined"
                  fullWidth
                  name="Cc"
                  onChange={this.handleChange}
                  type="text"
                  value={this.state.Cc || ""}
                />
              </Grid>
            </Grid>}
            {this.state.emailFields &&
            <Grid container justify="left" style={titleContainer}>
              
              <Grid xs={6}>
                <TextField
                  id={"notificationNameText"}
                  className="form-control"
                  variant="outlined"
                  label="bcc"
                  variant={"outlined"}
                  fullWidth
                  name="Bcc"
                  onChange={this.handleChange}
                  type="text"
                  value={this.state.Bcc || ""}
                />
              </Grid>
            </Grid>
  }
            <Grid container justify="left" style={titleContainer}>
              
              <Grid xs={6}>
                <TextField
                  id={"notificationDescText"}
                  label="Subject"
                  className="form-control"
                  variant="outlined"
                  fullWidth
                  name="Subject"
                  onFocus={this.setFlag}
                  onChange={this.handleBodyChange}
                  onMouseUp={this.handleBodyChange}
                  onKeyDown={this.addExtraIndexSubject}
                  type="text"
                  value={this.state.Subject || ""}
                />
              </Grid>
            </Grid>

            <Grid container justify="left" style={titleContainer}>
              <Grid xs={6}>
                <TextareaAutosize
                  rowsMin={5}
                  name="body"
                  onFocus={this.toggleFlag}
                  label="Body"
                  placeholder="Body"
                  className="form-control"
                  variant="outlined"
                  onChange={this.handleBodyChange}
                  onMouseUp={this.handleBodyChange}
                  onKeyDown={this.addExtraIndex}
                  value={this.state.body || ""}
                  style={{ minWidth: 540, minHeight: 60 }}
                />
              </Grid>
            </Grid>
           

            
            </Grid>
            <Grid container justify="left" style={titleContainer}>
             
              <Grid xs={6} >
                {this.props.notificationTags &&
                  this.props.notificationTags.map((data) => {
                    console.log("Data[TagName]", data["TagName"]);

                    return (
                      <Chip
                        style={{ margin: 5 }}
                        label={data["TagName"]}
                        color={"primary"}
                        onClick={() => {
                          this.handleTagClick(data["TagName"]);
                        }}
                      />
                    );
                  })}
              </Grid>
            </Grid>

            <Grid container justify="left" style={titleContainer}>
              <Grid item xs={1}>
                <Typography style={{fontWeight:500,marginTop:35}} >
                  Active:
                </Typography>
              </Grid>
              
              <Grid xs={5} style={{marginTop:28}}>
                <Switch
                  checked={this.state.activateNotification}
                  onChange={this.handleActivateNotification}
                  name="activateNotification"
                  inputProps={{ "aria-label": "secondary checkbox" }}
                />
              </Grid>
            </Grid>

            

            <Grid container xs={6} justify="flex-end" style={titleContainer}>
              
              <Button
                color="primary"
                size="large"
                type="submit"
                variant="contained"
                onClick={this.handleAddNotification}
                style={{ marginBottom: 10, marginTop: 10, marginRight: 5 }}
              >
                Save Notification
              </Button>
              <Button
                color="inherit"
                size="large"
                type="submit"
                variant="outlined"
                onClick={this.handleCancel}
                style={{ marginBottom: 10, marginTop: 10 }}
              >
                Cancel
              </Button>
            </Grid>
           < Snackbar open={this.state.allFieldsFilled} autoHideDuration={6000} onClose={this.unsetAllFieldsFilled}>
              <Alert onClose={this.unsetAllFieldsFilled} severity="error">
                {this.state.errorMsg}
              </Alert>
          </Snackbar>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    notificationEvents: state.notifications_reducer.notificationEvents,
    recipientRoles: state.notifications_reducer.recipientRoles,
    notificationTags: state.notifications_reducer.notificationTags,
    user: state.user_reducer.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchNotificationEvents: () => dispatch(fetchNotificationEvents()),
    SetNotificationTags: (Name) => dispatch(SetNotificationTags(Name))

  }
};

const connected = connect(
  mapStateToProps,
  mapDispatchToProps
)(AddNotification);

export default withRouter(connected);
