import {
    FETCH_MESSAGE_PENDING,
    FETCH_MESSAGE_SUCCESS,
    FETCH_MESSAGE_ERROR,
    UPDATE_MESSAGE_PENDING,
    UPDATE_MESSAGE_SUCCESS,
    UPDATE_MESSAGE_ERROR,
    SET_MESSAGES_ERROR,
    SET_MESSAGES_LOADING,
    SET_MESSAGES_STATS,
    SET_MESSAGE_ANALYTICS,
    SET_MESSAGE_ANALYTICS_LOADING,
    SET_MESSAGE_ANALYTICS_ERROR
} from "../action-types/messagequeue-action-types";

export const fetchMessagesPending = () => {
    return {
        type: FETCH_MESSAGE_PENDING
    };
};

export const fetchMessagesSuccess = message => {
    return {
        type: FETCH_MESSAGE_SUCCESS,
        payload: {message}
    };
};

export const fetchMessagesError = error => {
    return {
        type: FETCH_MESSAGE_ERROR,
        payload: error
    };
};

export const updateMessagePending = () => {
    return {
        type: UPDATE_MESSAGE_PENDING
    };
};

export const updateMessageSuccess = (ID, StatusName) => {
    return {
        type: UPDATE_MESSAGE_SUCCESS,
        payload: {ID, StatusName}
    };
};

export const updateMessageError = error => {
    return {
        type: UPDATE_MESSAGE_ERROR,
        payload: error
    };
};


export const setMessageStats = stats => {
    return {
        type: SET_MESSAGES_STATS,
        payload: stats
    };
};

export const setMessageStatsError = error => {
    return {
        type: SET_MESSAGES_ERROR,
        payload: error
    };
};

export const setMessageStatsLoading = loading => {
    return {
        type: SET_MESSAGES_LOADING,
        payload: loading
    };
};

export const setMessagesAnalytics = (data) => {
    return {
        type: SET_MESSAGE_ANALYTICS,
        payload: data
    };
};

export const setMessagesAnalyticsLoading = (loading) => {
    return {
        type: SET_MESSAGE_ANALYTICS_LOADING,
        payload: loading
    };
};

export const setMessagesAnalyticsError = (error) => {
    return {
        type: SET_MESSAGE_ANALYTICS_ERROR,
        payload: error
    };
};


