import { DELETE_CONFIGURATION_ERROR } from './configurations-action-types';

export const FETCH_SCHEDULER_PENDING = 'FETCH_SCHEDULER_PENDING';
export const FETCH_SCHEDULER_SUCCESS = 'FETCH_SCHEDULER_SUCCESS';
export const FETCH_SCHEDULER_ERROR = 'FETCH_SCHEDULER_ERROR';

export const ADD_SCHEDULER_PENDING = 'ADD_SCHEDULER_PENDING';
export const ADD_SCHEDULER_SUCCESS = 'ADD_SCHEDULER_SUCCESS';
export const ADD_SCHEDULER_ERROR = 'ADD_SCHEDULER_ERROR';

export const UPDATE_SCHEDULER_PENDING = 'UPDATE_SCHEDULER_PENDING';
export const UPDATE_SCHEDULER_SUCCESS = 'UPDATE_SCHEDULER_SUCCESS';
export const UPDATE_SCHEDULER_ERROR = 'UPDATE_SCHEDULER_ERROR';

export const DELETE_SCHEDULER_PENDING = 'DELETE_SCHEDULER_PENDING';
export const DELETE_SCHEDULER_SUCCESS = 'DELETE_SCHEDULER_SUCCESS';
export const DELETE_SCHEDULER_ERROR = 'DELETE_SCHEDULER_ERROR';

export const CHANGE_ACTIVESTATUS_PENDING = 'CHANGE_ACTIVESTATUS_PENDING';
export const CHANGE_ACTIVESTATUS_SUCCESS = 'CHANGE_ACTIVESTATUS_SUCCESS';
export const CHANGE_ACTIVESTATUS_ERROR = 'CHANGE_ACTIVESTATUS_ERROR';
export const SCHEDULAR_HEALTH = 'SCHEDULAR_HEALTH';