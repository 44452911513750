import React, { Component } from 'react';
import MaterialTable, { MTableToolbar } from 'material-table';
import 'date-fns';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import { fetchAuditTrails, fetchAuditTrailsWithFilters, fetchUsers } from '../../Services/auditTrails';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Modal from 'react-responsive-modal';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import Loading from '../../Components/Loading/index';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import { Button } from '@material-ui/core';
import Snackbar from "../../Components/Snackbar";
import Pagination from "../../Components/Common/pagination";

var cronstrue = require('cronstrue');

const divStyles = {
    fontFamily: 'sans-serif',
    textAlign: 'center',
};
const tableWidth = {
    minWidth: '20%',
};
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
}));

function MaterialTableDemo(props) {
    var Name = 'Test';
    const [state, setState] = React.useState({
        columns: [
            {
                title: 'Event Name',
                field: 'EventName'
            },
            {
                title: 'Event Type',
                field: 'eventType',

            },
            {
                title: 'Description',
                field: 'Description',

            },
            {
                title: 'User',
                field: 'UserId',
                render: rowData => (
                    <span>{
                        props.user.length === 0 ?null:props.user.find(x => x.Id === rowData.UserId).Username 
                       
                    }</span>

                ),

            },
            {
                title: 'Time Stamp',
                field: 'CreatedDateTime',
                render: rowData => (
                    <span>{`${rowData.CreatedDateTime.toString().slice(
                        0,
                        10,
                    )} ${rowData.CreatedDateTime.toString().slice(11, 19)}`}</span>
                ),

            }
        ],
    });
    return (
        <div>
            <MaterialTable

                style={tableWidth}
                title=""
                columns={state.columns}
                data={props.audits}
                components={{
                    Toolbar: props => (
                        <div>
                            <MTableToolbar {...props} />

                        </div>
                    ),
                }}
                options={{
                    paging:false,
                    pageSize:10,  
                    emptyRowsWhenPaging: false,   //to make page size fix in case of less data rows 
                    search: false,
                    actionsColumnIndex: -1,
                    headerStyle: {
                        backgroundColor: 'rgb(119,136,153)',
                        color: '#FFF'
                    },
                    rowStyle: rowData => ({
                        backgroundColor: (rowData.tableData.id % 2 !== 0) ? '#EEE' : '#FFF'
                    })
                   
                }}
                actions={[






                ]}
                editable={{

                }}

            />
        </div>
    );
}

class Audit extends Component {

    state = {
        To: "",
        From: "",
        Event: "",
        User: "",
        emptyTo: false

    }
    handleEventChange = (e) => {
        this.setState({
            Event: e.target.value
        })
    }
    handleUserChange = (u) => {
        this.setState({
            User: u.target.value
        })
    }
    handleToChange = (to) => {
        this.setState({
            To: to.target.value
        })

    }
    handleFromChange = (from) => {

        this.setState({
            From: from.target.value
        })

    }
    searchWithFilter = (page) => {
        console.log("Page", page);
        if (this.state.From != "" && this.state.To == "") {
            this.setState({
                emptyTo: true
            })
            return;
        }
        this.props.fetchAuditTrailsWithFilters({
            To: this.state.To,
            From: this.state.From,
            Event: this.state.Event,
            User: this.state.User,
            page: page,
            pageSize: this.props.rowsPerPage
        })
    }
    resetFilters = () => {
        this.setState({
            To: "",
            From: "",
            Event: "",
            User: ""
        })
    }
    handleChangePerPage=(currentPage, event)=>{
        this.props.fetchAuditTrailsWithFilters({
            To: this.state.To,
            From: this.state.From,
            Event: this.state.Event,
            User: this.state.User,
            page: 1,
            pageSize:event.target.value
        })
    }
    componentWillMount() {
        this.props.fetchUsers();
        this.props.fetchAuditTrails(this.props.rowsPerPage);

    }

    render() {
        const events = ["", "Message", "Connection", "Configuration", "Schedular", "Mappings", "Logs", "Notification"];
        const pager = {
            pages: this.props.rowsPerPage,
            totalPages: this.props.totalPages
        }
        return (
            <div>
                {this.props.fetchData === false || this.props.user.length === 0 ? (
                    <Loading />
                ) : (
                        <>
                            <Grid container spacing={4}>

                                <Grid item xs={6} sm={3} >

                                    <TextField
                                        type="date"
                                        variant="outlined"
                                        margin="normal"
                                        id="from"
                                        label="From"
                                        value={this.state.From}
                                        onChange={this.handleFromChange}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        style={{ width: '100%' }}
                                        required={this.state.From != ''}
                                        autoFocus
                                    />
                                </Grid>
                                <Grid item xs={6} sm={3} >
                                    <TextField
                                        type="date"
                                        variant="outlined"
                                        margin="normal"
                                        id="to"
                                        label="To"
                                        value={this.state.To}
                                        onChange={this.handleToChange}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        style={{ width: '100%' }}
                                        required={this.state.To != ''}
                                        disabled={this.state.From == ""}

                                    />

                                </Grid>

                                <Grid item xs={6} sm={3} >
                                    <TextField
                                        select
                                        label="Select Event"
                                        margin="normal"
                                        name="Event"
                                        variant="outlined"
                                        id="event-select"
                                        value={this.state.Event}
                                        onChange={this.handleEventChange}
                                        style={{ width: '100%' }}
                                    >
                                        {events.map(row => {
                                            return (
                                                <MenuItem key={row} value={row}>
                                                    {row}
                                                </MenuItem>
                                            );
                                        })}
                                    </TextField>
                                </Grid>

                                <Grid item xs={6} sm={3} >
                                    <TextField
                                        select
                                        label="Select User"
                                        margin="normal"
                                        name="User"
                                        variant="outlined"
                                        id="user-select"
                                        value={this.state.User}
                                        onChange={this.handleUserChange}
                                        style={{ width: '100%' }}
                                    >
                                        <MenuItem key={""} value={""}>
                                                    {""}
                                        </MenuItem>
                                        {this.props.user.map(row => {
                                            return (
                                                <MenuItem key={row.Id} value={row.Id}>
                                                    {row.Username}
                                                </MenuItem>
                                            );
                                        })}
                                    </TextField>
                                </Grid>


                                <Grid item xs={3} sm={1} >
                                    <Button variant="contained" color="primary" onClick={this.searchWithFilter}>
                                        Search
                                </Button>
                                </Grid>
                                <Grid item xs={3} sm={1} >
                                    <Button variant="contained" color="primary" onClick={this.resetFilters}>
                                        Reset
                                </Button>
                                </Grid>

                                <Grid item xs={12}>
                                    <MaterialTableDemo {...this.props} />
                                </Grid>
                                <Grid item xs={12}>
                                    <Pagination
                                        pager={pager}
                                        getNewData={this.searchWithFilter}
                                        rowsPerPage={this.props.rowsPerPage}
                                        handleChangePerPage={this.handleChangePerPage}
                                    >
                                    </Pagination>
                                </Grid>



                            </Grid>

                        </>

                    )}
                {this.state.emptyTo && <Snackbar
                    message={"Please Enter To Date"}
                    severity={"Error"}
                />}
            </div>
        );
    }
}

const mapStateToProps = state => ({
    audits: state.auditReducer.audits,
    fetchData: state.auditReducer.fetchData,
    user: state.auditReducer.user,
    totalPages: state.auditReducer.totalPages,
    rowsPerPage: state.auditReducer.rowsPerPage
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            fetchAuditTrails: fetchAuditTrails,
            fetchAuditTrailsWithFilters: fetchAuditTrailsWithFilters,
            fetchUsers: fetchUsers
        },
        dispatch,
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Audit);
