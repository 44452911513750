export const FETCH_ENDPOINT_PENDING = 'FETCH_ENDPOINT_PENDING';
export const FETCH_ENDPOINT_SUCCESS = 'FETCH_ENDPOINT_SUCCESS';
export const FETCH_ENDPOINT_ERROR = 'FETCH_ENDPOINT_ERROR';
export const SET_PROCESSING_ENDPOINTS ='SET_PROCESSING_ENDPOINTS';
export const SET_ACTIVE_ENDPOINT = 'SET_ACTIVE_ENDPOINT';
export const SET_FILTERED_CONNECTION = 'SET_FILTERED_CONNECTION';
export const SET_CONNECTION_ENDPOINTS = 'SET_CONNECTION_ENDPOINTS';
export const LOAD_FILTERED_CONNECTION = 'LOAD_FILTERED_CONNECTION';
export const LOAD_CONNECTION_ENDPOINTS = 'LOAD_CONNECTION_ENDPOINTS';
export const ERR_FILTERED_CONNECTION = 'ERR_FILTERED_CONNECTION';
export const ERR_CONNECTION_ENDPOINTS = 'ERR_CONNECTION_ENDPOINTS';