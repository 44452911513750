import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import GetAppIcon from "@material-ui/icons/GetApp";
import Tooltip from "@material-ui/core/Tooltip";
import CancelIcon from "@material-ui/icons/Cancel";
import IconButton from "@material-ui/core/IconButton";
import RefreshIcon from "@material-ui/icons/Refresh";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { updateMessageStatus } from "../../Services/messagequeue";
import ThumbUpAltIcon from "@material-ui/icons/ThumbUpAlt";
import SmsFailedIcon from "@material-ui/icons/SmsFailed";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import ErrorIcon from "@material-ui/icons/Error";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import WorkIcon from "@material-ui/icons/Work";
import DoubleArrowIcon from "@material-ui/icons/DoubleArrow";
import Badge from "@material-ui/core/Badge";
import Button from "@material-ui/core/Button";
import MailIcon from "@material-ui/icons/Mail";
const { FILE_URL } = require("../../utils/Url");

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  paper: {
    border: "2px solid #d1d5da",
    padding: theme.spacing(2),
    margin: "auto",
    maxWidth: "100%",
    // marginTop: '1%',
    // marginBottom: '1%',
    borderRadius: "3px"
  },
  completed: {
    marginTop: "2%",
    color: "#4da060",
    fontWeight: "bold"
  },
  new: {
    marginTop: "2%",
    color: "#007bff",
    fontWeight: "bold"
  },
  //
  ready: {
    marginTop: "2%",
    color: "#d0ab24",
    fontWeight: "bold"
  },
  failed: {
    marginTop: "2%",
    color: "#ff00008a",
    fontWeight: "bold"
  },
  //
  initialized: {
    marginTop: "2%",
    color: "#007bff",
    fontWeight: "bold"
  },
  notRequired: {
    marginTop: "2%",
    color: "#0000008a",
    fontWeight: "bold"
  },
  bold: {
    fontWeight: "bold"
  },
  iconButton: {
    padding: "4px"
  }
}));

function showTimeSpan(secs) {
  var seconds = secs / 1000;
  var minutes = 0;
  var hours = 0;

  if (seconds > 59) {
    minutes = seconds / 60;
    seconds = seconds % 60 | 0;
    if (minutes > 59) {
      hours = (minutes / 60) | 0;
      minutes = minutes % 60 | 0;
    } else {
      minutes = minutes | 0;
    }
  } else {
    seconds = seconds | 0;
  }

  if (hours === 0)
    if (minutes === 0) return `${seconds} secs`;
    else if (seconds >= 30) return `${minutes + 1} minutes`;
    else return `${minutes} minutes`;
  else if (minutes >= 30) return `${hours + 1} hours`;
  else return `${hours} hours`;
}

function downloadZip(messageID) {
  window.open(
    FILE_URL + "/filestorage/downloadZip/?folderName=" + messageID + "&User=" + localStorage.getItem("UserInfo"),
    "_blank"
  );
}

const Actions = ({ updateStatus, messageId, statusName, previousStatus }) => {
  const classes = useStyles();
  return (
    <div>
      {/* Downaloads */}
      {statusName !== "New" && (previousStatus != 'Initializing' && previousStatus != 'New') && (
        <IconButton
          className={classes.iconButton}
          aria-label="delete"
          color="primary"
          onClick={() => downloadZip(messageId)}
        // onClick={event => updateStatus(event, rowID)}
        >
          <Tooltip title="Download Files" aria-label="add">
            <GetAppIcon fontSize="small" />
          </Tooltip>
        </IconButton>
      )}
      {["Failed", "Initialization Error", "Initialized", "Initializing", "Error"].includes(statusName) && (
        <>
          <IconButton
            className={classes.iconButton}
            aria-label="delete"
            color="primary"
            onClick={() =>
              statusName == "Failed"
                ? updateStatus(messageId, "Ready")
                : updateStatus(messageId, "New")
            }
          >
            <Tooltip title="Retry" aria-label="add">
              <RefreshIcon fontSize="small" />
            </Tooltip>
          </IconButton>

          <IconButton
            className={classes.iconButton}
            aria-label="delete"
            color="primary"
            onClick={() => updateStatus(messageId, "Not Required")}
          // onClick={event => updateStatus(event, rowID)}
          >
            <Tooltip title="Not reuiqred" aria-label="add">
              <CancelIcon fontSize="small" />
            </Tooltip>
          </IconButton>
        </>
      )}
    </div>
  );
};

function Box(props) {
  const classes = useStyles();


  return (
    <div>
      {props.messages.map(row => {
        return (
          <Paper className={classes.paper}>
            <Grid container item spacing={2}>
              <Grid item xs={12} sm={12} md={3} lg={3}>
                <Typography variant="body2" gutterBottom>
                  <Badge
                    color="primary"
                    badgeContent={row.ID}
                    className={classes.margin}
                    max={row.ID}
                  >
                    <MailIcon fontSize="default" color="primary" />
                  </Badge>
                </Typography>
                <Typography
                  className={classes.bold}
                  gutterBottom
                  variant="subtitle1"
                >
                  <Link onClick={() => {
                    let a = []
                    if (sessionStorage.getItem("path") === null) {
                      a.push('/messagequeue')
                      sessionStorage.setItem("path", JSON.stringify(a))
                    }
                    else {
                      a = JSON.parse(sessionStorage.getItem("path"));
                      a.push('/messagequeue')
                      sessionStorage.setItem("path", JSON.stringify(a))
                    }
                  }}
                    to={{
                      pathname: `/timeline/${row.ID}`,
                      data: [row.ID]
                    }}
                  >
                    {row.SourceId}
                  </Link>
                </Typography>
                <Typography
                  className={
                    row.CurrentStatus == "Completed"
                      ? classes.completed
                      : row.CurrentStatus == "New"
                        ? classes.new
                        : row.CurrentStatus == "Ready"
                          ? classes.ready
                          : row.CurrentStatus == "Initialized"
                            ? classes.initialized
                            : row.CurrentStatus == "Failed"
                              ? classes.failed
                              : classes.notRequired
                  }
                  variant="body2"
                >
                  {row.CurrentStatus == "Initialized" ? (
                    <KeyboardArrowRightIcon />
                  ) : row.CurrentStatus == "Ready" ? (
                    <ThumbUpAltIcon />
                  ) : row.CurrentStatus == "Failed" ? (
                    <SmsFailedIcon />
                  ) : row.CurrentStatus == "Completed" ? (
                    <CheckCircleIcon />
                  ) : row.CurrentStatus == "Initialization Error" ? (
                    <ErrorIcon />
                  ) : row.CurrentStatus == "Not Required" ? (
                    <ErrorIcon />
                  ) : (
                    <WorkIcon />
                  )}
                  <span>&nbsp;</span>
                  {row.CurrentStatus}
                </Typography>
              </Grid>
              <Grid xs={12} sm={12} md={4} lg={4}>
                <Typography
                  className={classes.bold}
                  gutterBottom
                  variant="subtitle1"
                >
                  Initialized At:{" "}
                  <span className="normalfont">{row.CreatedAt}</span>
                </Typography>
                <Typography
                  className={classes.bold}
                  gutterBottom
                  variant="subtitle1"
                >
                  {row.CurrentStatus == "Completed" ? row.CurrentStatus : null} {row.CurrentStatus == "Completed" ? "At: " : null}
                  <span className="normalfont">{row.CurrentStatus == "Completed" ? row.StatusTime : null}</span>
                </Typography>

                <Typography
                  className={classes.bold}
                  gutterBottom
                  variant="subtitle1"
                >
                  Time Elapsed:{" "}
                  <span className="normalfont">
                    About{" "}
                    {showTimeSpan(
                      new Date(row.StatusTime) - new Date(row.CreatedAt)
                    )}
                  </span>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={2} lg={3}>
                <Typography variant="subtitle1">
                  {row.SourceName} <DoubleArrowIcon color="primary" />{" "}
                  {row.DestinationName}
                </Typography>
              </Grid>
              <Grid
                xs={12}
                sm={12}
                md={2}
                lg={2}
                direction="row"
                // justify="flex-end"
                alignItems="center"
                spacing={2}
                container
              >
                {/* {row.CurrentStatus !== 'New' && <DownloadMessages />} */}
                {/* {row.CurrentStatus === 'Failed' && ( */}
                <Actions
                  updateStatus={props.updateStatus}
                  messageId={row.ID}
                  statusName={row.CurrentStatus}
                  previousStatus={row.PreviousStatus}
                />
              </Grid>
            </Grid>
          </Paper>
        );
      })}
    </div>
  );
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updateStatus: updateMessageStatus
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(Box);
