import {
    FETCH_STATUS_ERROR,
    FETCH_STATUS_SUCCESS,
    FETCH_STATUS_PENDING,
    UPDATE_STATUS_ERROR,
    UPDATE_STATUS_SUCCESS,
    UPDATE_STATUS_PENDING,
} from '../action-types/messagestatus-action-types';

const initialState = {
    fetchData: false,
    status: [],
    loading: false,
    error: null,
};

export default function messageStatusReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_STATUS_PENDING:
            return {
                ...state,
                error: null,
                loading: true,
            };
        case FETCH_STATUS_SUCCESS:
            return {
                ...state,
                pending: false,
                status: action.payload.status,
                fetchData: true,
            };
        case FETCH_STATUS_ERROR:
            return {
                ...state,
                pending: false,
                error: true,
                status: [],
            };
        case UPDATE_STATUS_PENDING:
            return {
                ...state,
                pending: true,
                // status: [],
            };
        case UPDATE_STATUS_SUCCESS:
            return {
                ...state,
                pending: false,
                status: state.statuses.concat(action.payload),
            };
        case UPDATE_STATUS_ERROR:
            return {
                ...state,
                pending: false,
                error: true,
                // status: [],
            };
        default:
            return state;
    }
}
