import React, { Component } from "react";
import MaterialTable from "material-table";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { fetchMessages } from "../../Services/messagequeue";
import { updateMessageStatus } from "../../Services/messagequeue";
import { bindActionCreators } from "redux";
import {
  NewClass,
  FailedClass,
  InProcessClass,
  InitializedClass,
  ErrorClass,
  CompletedClass,
  ReadyClass
} from "../../styles/StatusColors";
import { IconButton } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(1)
  },
  input: {
    display: "none"
  }
}));

function MaterialTableDemo(props) {
  const data = props.messages.filter(row => row.CurrentStatus == "Failed");
  data.splice(4);
  const [state, setState] = React.useState({
    columns: [
      { title: "Source Id", field: "SourceId" },
      {
        title: "Status",
        render: rowData => (
          <button
            className={
              rowData.CurrentStatus === "Initializing"
                ? InitializedClass
                : rowData.CurrentStatus === "Ready"
                ? ReadyClass
                : rowData.CurrentStatus === "Failed"
                ? FailedClass
                : rowData.CurrentStatus === "Completed"
                ? CompletedClass
                : rowData.CurrentStatus === "InProcess"
                ? InProcessClass
                : rowData.CurrentStatus === "Error"
                ? ErrorClass
                : NewClass
            }
            type="button"
            disabled
          >
            {rowData.CurrentStatus}
          </button>
        )
      }
      // {
      //   title: 'Actions',
      //   render: rowData => (
      //     <div>
      //       <IconButton
      //         className={classes.button}
      //         color="primary"
      //         disabled={rowData.CurrentStatus != 'Failed'}
      //         onClick={() => updateStatus(rowData.ID)}
      //       >
      //         <RefreshIcon />
      //       </IconButton>
      //       <Link
      //         to={{
      //           pathname: `/timeline/${rowData.ID}`,
      //           data: [rowData.ID],
      //         }}
      //       >
      //         <DetailIcon />
      //       </Link>
      //     </div>
      //   ),
      // },
    ]
  });
  const classes = useStyles();
  return (
    <MaterialTable
      title="Failed Messages"
      data={data}
      columns={state.columns}
      options={{
        search: false,
        paging: false
      }}
    />
  );
}

class FailedMessages extends Component {
  constructor(props) {
    super(props);
    this.state = {
      failedMessages: []
    };
  }
  componentDidMount() {
    this.props.fetchMessages();
  }

  render() {
    return (
      <div>{this.props.messages && <MaterialTableDemo {...this.props} />}</div>
    );
  }
}

const mapStateToProps = state => ({
  messages: state.messages.message
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updateMessageStatus: updateMessageStatus,
      fetchMessages: fetchMessages
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(FailedMessages);
