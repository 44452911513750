import React, {Component} from 'react';
import {bindActionCreators} from 'redux';
import {fetchMessages} from '../../Services/messagequeue';
import {connect} from 'react-redux';
import PieChart from 'react-minimal-pie-chart';

const data = [
    {title: 'Data 1', value: 100, color: '#22594e'},
    {title: 'Data 2', value: 60, color: '#2f7d6d'},
    {title: 'Data 3', value: 30, color: '#3da18d'},
    {title: 'Data 4', value: 20, color: '#69c2b0'},
    {title: 'Data 5', value: 10, color: '#a1d9ce'},
];

class Chart extends Component {
    constructor(props) {
        super(props);
        this.state = {
            statusToShow: ['ready', 'Completed', 'Failed'],
            statusToHide: ['Initializing', 'New', 'InProcess'],
        };
    }

    componentWillMount() {
        this.handleChange();
    }

    handleChange = () => {
    };

    render() {
        return (
            <div>
                <PieChart
                    data={[
                        {title: 'One', value: 10, color: '#E38627'},
                        {title: 'Two', value: 15, color: '#C13C37'},
                        {title: 'Three', value: 20, color: '#6A2135'},
                    ]}
                    style={{height: '300px'}}
                />
            </div>
        );
    }
}

class DasboardComponent extends React.Component {
    componentDidMount() {
        this.props.fetchMessages();
    }

    render() {
        return <Chart {...this.props} />;
    }
}

const mapStateToProps = state => ({
    messages: state.messages.message,
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            fetchMessages: fetchMessages,
        },
        dispatch,
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(DasboardComponent);
