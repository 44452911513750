import React, { Component } from 'react';
import connectionStyle from './connection.css';
import Add from '@material-ui/icons/Add';
import { connect } from 'react-redux';
import { addConnection } from '../../Services/connection';
import ConnectionWidget from './ConnectionWidget';
import NewConnectionWidget from './NewConnectionHandler';
class Connections extends Component {




    addConnection = () => {
        this.props.addConnection(!this.props.addConFlag);
    };

    showNewConnection = () => {
        //const containerStyle = `row ${connectionStyle.button_container}`;
        //const classStyles = `btn btn-sm btn-primary ${connectionStyle.button_add}`;
        // const containerStyle = `row button_container`;
        // const classStyles = `btn btn-sm btn-primary button_add`
        // return <div className={containerStyle}>
        //     <div className="col-md-4">
        //     </div>
        //     <div className="col-md-4">
        //     </div>
        //     <div className="col-md-4">
        //         <button className={classStyles} onClick={this.addConnection}>
        //             <Add style={{ marginTop: -3 }} />
        //             Add Connection
        //         </button>
        //     </div>
        // </div>;
    };

    showConnections = () => {

    };



    render() {
        return <div className={connectionStyle.connection}>
            {this.showNewConnection()}
            <NewConnectionWidget />
            <div>
                <ConnectionWidget />
            </div>

        </div>;

    }
}

const mapStateToProps = (state) => {
    return {
        addConFlag: state.connections_reducer.addConFlag
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        addConnection: (val) => dispatch(addConnection(val))
    };
};

export default connect(null, mapDispatchToProps)(Connections);
