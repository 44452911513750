import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import SaveIcon from "@material-ui/icons/Save";
import LinkIcon from "@material-ui/icons/Link";
import SendIcon from "@material-ui/icons/Send";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import EditIcon from "@material-ui/icons/Edit";
import { testConnection, updateConnection } from "../../Services/connection";
import ConnectionStatus from "./ConnectionStatus";
import { useDispatch, useSelector } from "react-redux";
import Snackbar from "../Snackbar";
import { firstCapital, REQUEST_MAIN } from "../../utils/JsUtils";


export default function ConnectionForm(props) {
    let connectionData = {
        Name: "",
        connectionType: props.connectionTypes[0].Name,
        TypeId: props.connectionTypes[0].Id,
        port: "",
        loading: false,
        error: false,
        connectionStatus: false,
        connectionModal: false,
        inValid: false,
        edited: false,
        selectedType: props.connectionTypes[0],
        message: { success: false, show: false }
    };

    const [connectionInfo, setConnectionInfo] = React.useState(connectionData);

    let { editConnectionData, editFlag } = useSelector(state => ({
        ...state.connections_reducer
    }));

    useEffect(() => {
        if (editFlag && !connectionInfo.edited) {
            let details = JSON.parse(editConnectionData.Details);
            let newConnectionType = connectionInfo.connectionType;
            let TypeId = "";
            let selectedType = {};
            props.connectionTypes.map(typeData => {
                if (editConnectionData.Type === typeData.Name) {
                    newConnectionType = typeData.Name;
                    TypeId = typeData.Id;

                    selectedType = typeData;
                }
            });
            let newData = {
                ...connectionInfo,
                ...editConnectionData,
                ...details,
                ...details.auth,
                edited: true,
                connectionType: newConnectionType,
                TypeId,
                selectedType: selectedType
            };
            setConnectionInfo(newData);
        }
    });

    // dispatch action

    const dispatch = useDispatch();

    const handleChange = event => {
        let data = {
            ...connectionInfo,
            [event.target.name]: event.target.value,
            inValid: false
        };
        setConnectionInfo(data);
    };

    const handleTypeChange = (event, child) => {
        let data = {
            ...connectionInfo,
            [event.target.name]: event.target.value,
            inValid: false,
            TypeId: child.props.id
        };
        setConnectionInfo(data);
    };

    const closeConnectionModal = () => {
        let data = {
            ...connectionInfo,
            loading: false,
            connectionModal: false,
            error: false,
            connectionStatus: false
        };
        setConnectionInfo(data);
    };

    const handleTestClick = () => {
        if (validator({ ...connectionInfo })) {
            let loadingTrue = {
                ...connectionInfo,
                loading: true,
                connectionModal: true,
                inValid: false
            };
            setConnectionInfo(loadingTrue);
            testConnection(connectionInfo, response => {
                if (response) {
                    const newState = {
                        ...connectionInfo,
                        loading: false,
                        error: false,
                        connectionStatus: true,
                        connectionModal: true
                    };
                    setConnectionInfo(newState);
                } else {
                    const newState = {
                        ...connectionInfo,
                        loading: false,
                        error: true,
                        connectionModal: true
                    };
                    setConnectionInfo(newState);
                }
                return;
            });
        } else {
            let data = { ...connectionInfo, inValid: true };
            setConnectionInfo(data);
        }
    };

    const handleSendRequest = () => {
        if (validator({ ...connectionInfo })) {
            props.setSendRequest(connectionInfo);

        } else {
            let data = { ...connectionInfo, inValid: true };
        }
    };

    const handleSaveClick = () => {
        if (validator({ ...connectionInfo })) {
            props.handleSave(connectionInfo);
            setTimeout(function () {
                props.handleParentClose();
                props.handleClose();
            }, 1000);
        } else {
            let data = { ...connectionInfo, inValid: true };
            setConnectionInfo(data);
        }
    };

    const handleEditClick = () => {
        if (validator({ ...connectionInfo })) {
            dispatch(
                updateConnection(
                    connectionInfo,
                    connectionInfo.connectionType,
                    response => {
                        let newData = {
                            ...connectionInfo,
                            message: { success: response, show: true }
                        };
                        setConnectionInfo(newData);
                    }
                )
            );
            setTimeout(function () {
                props.handleParentClose();
                props.handleClose();
            }, 1000);

        } else {
            let data = { ...connectionInfo, inValid: true };
            setConnectionInfo(data);
        }
    };

    const validator = formData => {
        if (
            formData.name !== "" &&
            formData.url !== "" &&
            formData.connectionType !== ""
        ) {
            return true;
        } else {
            return false;
        }
    };

    return (
        <div className="row" style={{ padding: 20 }}>
            {connectionInfo.inValid ? (
                <div
                    className="col-12"
                    style={{ fontWeight: "500", color: "red", textAlign: "center" }}
                >
                    *Please fill the required fields*
                </div>
            ) : null}

            <div className="col-12">
                <TextField
                    required
                    label="Name"
                    name="Name"
                    id="Name"
                    variant="outlined"
                    style={{ width: "100%", marginTop: 5 }}
                    onChange={handleChange}
                    value={connectionInfo.Name}
                />
            </div>

            <div className="col-12">
                <TextField
                    required
                    select
                    label="Type"
                    name="connectionType"
                    variant="outlined"
                    value={connectionInfo.connectionType}
                    onChange={handleTypeChange}
                    style={{ width: "100%", marginTop: 15 }}
                >
                    {props.connectionTypes.map(option => (
                        <MenuItem key={option.Id} id={option.Id} value={option.Name}>
                            {option.Name}
                        </MenuItem>
                    ))}
                </TextField>
            </div>
            <div className="col-12">
                <ConnectionProperties
                    connectionInfo={connectionInfo}
                    handleChange={handleChange}
                    connectionTypes={props.connectionTypes}
                    editConnectionData={editConnectionData}
                    editFlag={editFlag}
                    selectedType={connectionInfo.connectionType}
                />
            </div>
            {/* button widget */}
            <div className="col-12 ">
                <div className="row" style={{ marginTop: 15 }}>
                    <div className="col-6">
                        <Button
                            variant="contained"
                            color="primary"
                            size="medium"
                            onClick={() => {
                                handleTestClick();
                            }}

                        >
                            Test Connection
            </Button>
                    </div>
                    {connectionInfo.connectionType == 'Rest' || connectionInfo.connectionType == 'SOAP' ?
                        <div className="col-3" style={{ textAlign: "right" }}>
                            <Button
                                style={{ marginRight: "-10px" }}
                                variant="contained"
                                color="default"
                                size="medium"
                                onClick={() => {
                                    handleSendRequest();
                                }}

                            >
                                Send Request
            </Button>
                        </div> : <div className="col-3" >
                            <span></span>
                        </div>}



                    <div className="col-3" style={{ textAlign: "right" }} >
                        {editFlag ? (
                            <Button
                                variant="contained"
                                color="primary"
                                size="medium"
                                onClick={() => {
                                    handleEditClick();
                                }}


                            >
                                Save Connection
                            </Button>
                        ) : (
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="medium"
                                    onClick={() => {
                                        handleSaveClick();
                                    }}

                                    style={{ textAlign: "center" }}
                                >
                                    Save Connection
                                </Button>
                            )}
                    </div>

                </div>
            </div>
            {
                connectionInfo.connectionModal ? (
                    <ConnectionStatus
                        onClose={closeConnectionModal}
                        stateData={{ ...connectionInfo }}
                    />
                ) : null
            }
            <div>
                {connectionInfo.message.show && connectionInfo.message.success ? (
                    <Snackbar message={"Connection updated "} severity={"success"} />
                ) : null}
                {connectionInfo.message.show && !connectionInfo.message.success ? (
                    <Snackbar
                        message={"Unable to save, Please try again"}
                        severity={"error"}
                    />
                ) : null}
            </div>
        </div >
    );
}

// added dynamic properties of the form

export function ConnectionProperties(props) {
    let metaData = "";
    props.connectionTypes.forEach(type => {
        if (type.Name === props.selectedType) {
            metaData = JSON.parse(type.Metadata);
        }
    });

    const handleFieldChange = e => {
        props.handleChange(e);
    };
    return (
        <div className="row">
            <div
                className="col-12"
                style={{
                    textAlign: "center",
                    marginTop: 35,
                    fontSize: 20,
                    fontWeight: "500",
                    color: "#d79191"
                }}
            >

            </div>
            {metaData &&
                metaData.map(data => {
                    return (
                        <div className="col-12" key={data.key}>
                            <TextField
                                required={data.required}
                                type={data.type}
                                label={firstCapital(data.key)}
                                name={data.key}
                                id={data.key}
                                variant="outlined"
                                style={{ width: "100%", marginTop: 10 }}
                                onChange={handleFieldChange}
                                value={
                                    props.connectionInfo[data.key]
                                        ? props.connectionInfo[data.key]
                                        : ""
                                }
                            />
                        </div>
                    );
                })}
        </div>
    );
}
