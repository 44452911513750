import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import MessageGraph from './MessageGraph.js';
import ConnectionGraph from './ConnectionGraph';
import FileSystemHealth from './FileSystemHealth';
import SchedularsGraph from "./SchedularGraph";
import MessageAnalytics from './MessageAnalytics';
import SystemHealth from "./SystemHealth";
import ConnectionHealth from './ConnectionHealth';

const pieColumnStyle = {
    height: 416
};


export default class index extends Component {
    static propTypes = {
        prop: PropTypes
    };

    messagesConnectonGraph = () => {
        return (
            <Grid container spacing={1}>
                <Grid item xs={4}>
                    <Paper style={pieColumnStyle}><MessageGraph /></Paper>
                </Grid>
                <Grid item xs={4}>
                    <Paper style={pieColumnStyle}><ConnectionGraph /></Paper>
                </Grid>
                <Grid item xs={4}>
                    <Paper style={pieColumnStyle}><FileSystemHealth /></Paper>
                </Grid>
            </Grid>
        );
    };

    schedulerHealth = () => {
        return (
            <Grid container spacing={1} style={{ marginTop: 25 }}>
                <Grid item xs={4}>
                    <Paper style={pieColumnStyle}><SchedularsGraph /></Paper>
                </Grid>
                <Grid item xs={4}>
                    <Paper style={pieColumnStyle}><ConnectionHealth title={"Connection Health"} /></Paper>
                </Grid>
                <Grid item xs={4}>
                    <Paper style={pieColumnStyle}><SystemHealth title={'System Health'} /></Paper>
                </Grid>
            </Grid>
        );
    };

    messageTimeLine = () => {
        return (
            <Grid container spacing={1} style={{ marginTop: 25 }}>
                <Grid item xs={12}>
                    <Paper style={{ ...pieColumnStyle, height: 470 }}><MessageAnalytics /></Paper>
                </Grid>
            </Grid>
        );
    };


    render() {

        return (

            <div >
                {this.messagesConnectonGraph()}
                {this.schedulerHealth()}
                {this.messageTimeLine()}
            </div>
        );
    }
}
