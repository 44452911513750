import {
    SET_NOTIFICATION_ERROR,
    SET_NOTIFICATION_EVENTS,
    SET_RECIPIENT_ROLES,
    SET_NOTIFICATION_TAGS,
    GET_ALL_NOTIFICATIONS,
    SET_EDIT_DATA,
  } from "../action-types/notifications";
  
  const initState = () => {
    return {
      notificationEvents: null,
      recipientRoles: null,
      notificationTags: null,
      allNotifications: null,
      editData: null,
      error: null,
    };
  };
  const notifications_reducer = (state = initState(), action) => {
    let newState = { ...state };
    switch (action.type) {
      case SET_NOTIFICATION_EVENTS:
        setData(newState, action.payload, "notificationEvents");
        break;
      case SET_RECIPIENT_ROLES:
        setData(newState, action.payload, "recipientRoles");
        break;
      case SET_NOTIFICATION_TAGS:
        setData(newState, action.payload, "notificationTags");
        break;
      case GET_ALL_NOTIFICATIONS:
        setData(newState, action.payload, "allNotifications");
        break;
      case SET_EDIT_DATA:
        setData(newState, action.payload, "editData");
        break;
      case SET_NOTIFICATION_ERROR:
        setData(newState, action.payload, "error");
        break;
      default:
        break;
    }
    return newState;
  };
  
  const setData = (state, payload, key) => {
    console.log("Payload",payload);
    state[key] = payload;
    console.log("state[",key,"]",state[key]);
  };
  
  export default notifications_reducer;
  