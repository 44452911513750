import React, { Component } from 'react'
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import IconButton from '@material-ui/core/IconButton';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import { makeStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { setDropdownState } from '../../actions/configurations-actions';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    margin: {
        margin: theme.spacing(1),
    }
}));

const UserForm = (props) => {
    const classes = useStyles();
    const values = props.formValues

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleClickShowPassword = () => {
        props.handleClickShowPassword()
    };

    const handleChange = (prop) => (event) => {
        props.handleChange(prop, event)
    };

    const handleUserChange = (event) => {
        let newUserName = props.users.filter(row => {
            return row.Username === event.target.value
        })[0].Username

        let newRole = props.users.filter(row => {
            return row.Username === event.target.value
        })[0].role.Name

        let newPassword = props.users.filter(row => {
            return row.Username === event.target.value
        })[0].Password

        let Id = props.users.filter(row => {
            return row.Username === event.target.value
        })[0].Id

        props.handleUserChange(event, newUserName, newRole,newPassword, Id)
    }

    const handleRoleChange = (event) => {
        props.handleRoleChange(event)
    }

    return (
        <form className={classes.root} autoComplete="off">
            {
                JSON.parse(localStorage.getItem("UserInfo")).RoleId === 1 && props.formState === "view" &&
                <Grid item xs={12} md={12} lg={12} xl={12} >
                    <FormControl fullWidth variant="outlined" className={classes.formControl}>
                        <InputLabel id="user-name">User</InputLabel>
                        <Select
                            labelId="user-name"
                            id="user-name"
                            value={values.selectdUserName}
                            onChange={handleUserChange}
                            label="User"
                        >
                            {props.users.map((option) => (
                                <MenuItem value={option.Username}>{option.Username}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
            }
            <Grid item xs={12} md={12} lg={12} xl={12} >
                <FormControl fullWidth className={classes.formControl} variant="outlined">
                    <InputLabel htmlFor="UserName">User Name</InputLabel>
                    <OutlinedInput
                        required={true}
                        id="UserName"
                        disabled={!(props.formState === "add") }
                        type={'text'}
                        value={values.username}
                        onChange={handleChange('username')}
                        label={"UserName"}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={12} md={12} lg={12} xl={12} >
                <FormControl fullWidth className={classes.formControl} variant="outlined">
                    <InputLabel htmlFor="Password">Password</InputLabel>
                    <OutlinedInput
                        required={true}
                        id="Passowrd"
                        disabled={ props.formState === "view" }
                        type={values.showPassword ? 'text' : 'password'}
                        value={values.password}
                        onChange={handleChange('password')}
                        label={"Password"}
                        error={values.password !== values.confirmpassword && values.confirmpassword != ''}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                >
                                    {values.showPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                </FormControl>
            </Grid>
            <Grid item xs={12} md={12} lg={12} xl={12} >
                <FormControl fullWidth className={classes.formControl} variant="outlined">
                    <InputLabel htmlFor="ConfirmPassowrd">Confirm Password</InputLabel>
                    <OutlinedInput
                        required={true}
                        id="ConfirmPassword"
                        disabled={props.formState === "view" }
                        type={values.showPassword ? 'text' : 'password'}
                        value={values.confirmpassword}
                        onChange={handleChange('confirmpassword')}
                        label="Confirm Password"
                        error={values.password !== values.confirmpassword && values.confirmpassword != ''}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                >
                                    {values.showPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                    <FormHelperText style={{color:'red'}} id="component-error-text">{values.password !== values.confirmpassword && values.confirmpassword !== "" ? "Passwords do not match" : ""}</FormHelperText>
                </FormControl>
            </Grid>
            {props.users.length > 0 && JSON.parse(localStorage.getItem("UserInfo")).RoleId === 1 && (
                <Grid item xs={12} md={12} lg={12} xl={12} >
                    <FormControl fullWidth variant="outlined" className={classes.formControl}>
                        <InputLabel id="user-role">User Role</InputLabel>
                        <Select
                            disabled={props.formState === "view" }
                            labelId="user-role"
                            id="user-role"
                            value={values.role}
                            onChange={handleRoleChange}
                            label="User Role"
                        >
                            {props.roles.map((option) => (
                                <MenuItem value={option.Name}>{option.Name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
            )}

        </form>
    )
}

export default UserForm;