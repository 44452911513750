import React, { Component, useState, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import MenuIcon from '@material-ui/icons/Menu';
import Tooltip from "@material-ui/core/Tooltip";
import { withRouter, Redirect } from 'react-router-dom';
import { BASE_URL } from '../../utils/Url';
import { bindActionCreators } from 'redux';
import IconButton from '@material-ui/core/IconButton';
import { connect } from 'react-redux';
import { changeAuthentication } from '../../Services/configurations';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Cookies from "js-cookie";
import { apiAdapter } from "../../utils/APIAdapter";
const axios = require('axios');
const { AUTH_URL } = require('../../utils/Url');
const api = apiAdapter(AUTH_URL)

const drawerWidth = 240;
const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    appBarShift: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
}));

const MaterialAppBar = data => {
    const classes = useStyles();
    const [state, setState] = React.useState({
        redirect: false,
    });
    const { toggleNavigation, onToggleNavigation } = data;

    function CallLogoutAPI() {
        axios.get(`${AUTH_URL}/auth/logout`).then(res => {
            console.log(res);
        });
    }

    function goBack() {
        if (sessionStorage.getItem("path") !== null) {
            let a = JSON.parse(sessionStorage.getItem("path"))
            console.log("A", a);


            let route = a.pop()
            // console.log(route, "=========")
            if (route === undefined) {
                sessionStorage.removeItem("path")
                route = "/dashboard"
            }
            else
                sessionStorage.setItem("path", JSON.stringify(a))
            window.location.replace(route);
        }
        // window.history.back()
    }

    async function logout() {
        // if (state.redirect) {
        localStorage.removeItem('isAuthenticated');
        // Cookies.remove("connect.sid");
        let response = await api.get(`/auth/openid/logout`);
        Cookies.remove("connect.sid");
        Cookies.remove("_session");
        Cookies.remove("_session.sig");
        if (response && response.data && response.data.message == "AuthenticationFailure") {

            window.location.replace(`${AUTH_URL}/auth/openid`);
        }
        else
            return response;
        // window.location.replace(`${AUTH_URL}/auth/openid`)
        // }
    }

    return (
        <div className={classes.root}>
            <AppBar
                position="fixed"
                className={clsx(classes.appBar, {
                    [classes.appBarShift]: toggleNavigation,
                })}
            >
                <Toolbar>
                    <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu" onClick={onToggleNavigation}><MenuIcon /></IconButton>
                    <IconButton edge="start" disabled={sessionStorage.getItem("path") === null || JSON.parse(sessionStorage.getItem("path")) === []} className={classes.menuButton} color="inherit" onClick={() => goBack()}>
                        <Tooltip title="Back" aria-label="add">
                            <ArrowBackIcon fontSize="small" />
                        </Tooltip>
                    </IconButton>
                    {data.match.path.includes("/endpoints") ? <div style={{ "font-weight": "500" }} >ENDPOINTS</div> :
                        data.match.path.includes("/configurations") ? <div style={{ "font-weight": "500" }} >CONFIGURATIONS</div> :
                            data.match.path.includes("/scheduler") ? <div style={{ "font-weight": "500" }}>SCHEDULERS</div> :
                                data.match.path.includes("/timeline") ? <div style={{ "font-weight": "500" }} >STATUS TIMELINE</div> :
                                    data.match.path.includes("/AddNotification") ? <div style={{ "font-weight": "500" }}>ADD NOTIFICATIONS</div> :
                                        data.match.path.includes("/createScheduler") ? < div style={{ "font-weight": "500" }}>CREATE SCHEDULER</div> :
                                            data.match.path.includes("/editScheduler") ? < div style={{ "font-weight": "500" }}>EDIT SCHEDULER</div> :
                                                data.match.path.includes("/editNotification") ? <div style={{ "font-weight": "500" }}>EDIT NOTIFICATIONS</div> :
                                                    data.match.path.includes("/notification") ? <div style={{ "font-weight": "500" }}>NOTIFICATIONS</div> :
                                                        data.match.path.includes("/connections") ? < div style={{ "font-weight": "500" }}>CONNECTIONS</div> :
                                                            data.match.path.includes("/messagequeue") ? < div style={{ "font-weight": "500" }}>MESSAGES</div> :
                                                                data.match.path.includes("/dashboard") ? < div style={{ "font-weight": "500" }}>DASHBOARD</div> :
                                                                    data.match.path.includes("/mappings") ? < div style={{ "font-weight": "500" }}>MAPPINGS</div> :
                                                                        data.match.path.includes("/logs") ? < div style={{ "font-weight": "500" }}>LOGS</div> :
                                                                            data.match.path.includes("/audits") ? < div style={{ "font-weight": "500" }}>AUDITS</div> :
                                                                                data.match.path.includes("/users") ? < div style={{ "font-weight": "500" }}>USERS</div> :
                                                                                    data.match.path.includes("/") ? < div style={{ "font-weight": "500" }}>DASHBOARD</div> :
                                                                                        null
                    }
                    <Typography variant="h6" className={classes.title}>
                        {/* Serveys */}
                    </Typography>
                    {/* <Button color="inherit" onClick={CallLogoutAPI}>Logout</Button> */}
                    {/* <Button color="inherit" href='http://localhost:3894/auth/logout'>Logout</Button> */}
                    <Button
                        color="inherit"
                        onClick={() => {
                            // setState({
                            //     ...state,
                            //     redirect: true,
                            // });
                            logout()
                        }}
                    >
                        Logout
                    </Button>
                </Toolbar>
            </AppBar>
        </div>
    );
};

class AppBarComponent extends Component {
    static propTypes = {
        toggleNavigation: PropTypes.bool,
        onToggleNavigation: PropTypes.func,
    };

    render() {
        return <MaterialAppBar {...this.props} />;
    }
}

const mapStateToProps = state => ({
    isAuthenticated: state.configurations.isAuthenticated,
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            changeAuthentication: changeAuthentication,
        },
        dispatch,
    );

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(AppBarComponent),
);
