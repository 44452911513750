import axios from "axios";
import { connectionUrl } from "../utils/Url";
import {
    getConnectionData,
    getConnectionError,
    getConnectionProcessing,
    connectionTypesErr,
    connectionTypesLoading,
    setConnecionTypes,
    addConnectionFlag,
    setEditData,
    setEditable,
    setPayload
} from "../actions/connections-actions";
import { REQUEST_REST, REQUEST_SOAP, REQUEST_MAIN } from "../utils/JsUtils";
const api = require("../utils/APIAdapter").apiAdapter;
const uuidv1 = require("uuid/v1");

export const setConnections = () => {
    return dispatch => {
        dispatch(getConnectionProcessing(true));

        const prepareUrl = `${connectionUrl}/connections/getAllConnections`;

        axios
            .get(prepareUrl, getOptions())
            .then(respose => {
                dispatch(getConnectionProcessing(false));
                dispatch(getConnectionData(respose.data.result));
            })
            .catch(err => {
                dispatch(getConnectionProcessing(false));
                dispatch(getConnectionError(true));
            });
    };
};

export const setConnectionTypes = () => {
    return dispatch => {
        dispatch(connectionTypesLoading(true));

        const prepareUrl = `${connectionUrl}/connections/getAllTypes`;
        axios
            .get(prepareUrl, getOptions())
            .then(respose => {
                if (respose.data.result) {
                    dispatch(setConnecionTypes(respose.data.result));
                }
                dispatch(connectionTypesLoading(false));
            })
            .catch(err => {
                dispatch(connectionTypesLoading(false));
            });
    };
};

export const addConnection = flag => {
    return dispatch => {
        dispatch(setEditData(null));
        dispatch(addConnectionFlag(flag));
        dispatch(setEditable(false));
    };
};

export const modalIsEditable = () => {
    return dispatch => {
        dispatch();
    };
};

export const getOptions = () => {
    return {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            traceId: uuidv1(),
            UserInfo: localStorage.getItem("UserInfo"),
        }
    };
};

export const getPayload = id => {
    return dispatch => {
        let data = {
            Id: id
        };
        const prepareUrl = `${connectionUrl}/connections/findOneById`;
        axios.post(prepareUrl, data, getOptions()).then(response => {
            if (response.data.result) {
                if (response.data.result.result1) {
                    let payload = response.data.result.result1.Payload;
                    dispatch(setPayload(payload));
                }
            }
        });
    };
};

export const testConnection = (params, cb) => {
    const getOptions = () => {
        return {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                traceId: uuidv1()
            }
        };
    };
    const connectionType = params.connectionType.toLowerCase();
    const prepareUrl = `${connectionUrl}/connections/${connectionType}/testConnection`;
    let data = {};
    switch (params.connectionType) {
        case "Rest":
            data = {
                url: params.url
            };
            break;

        case "SOAP":
            data = {
                url: params.url,
                endpoint: params.endpoint
            };
            break;
        case "Database":
            data = {
                username: params.username,
                password: params.password,
                host: params.host,
                port: params.port,
                database: params.database,
                dialect: params.dialect
            };
            break;
        case "FILE":
            data = {
                path: params.path
            }
            break;
        case "FTP":
            data = {
                host: params.host,
                port: params.port,
                user: params.user,
                password: params.password
            }
            break;
        default:
            break;
    }
    console.log(data);
    axios
        .post(prepareUrl, data, getOptions())
        .then(respose => {
            console.log("response => request", respose);
            cb(true);
        })
        .catch(err => {
            cb(false);
            console.log("err => request", err);
        });
};

export const sendRequest = (params, cb) => {
    let type = params.type.toLowerCase();

    const prepareUrl = `${connectionUrl}/connections/${type}/sendrequest`;
    const getOptions = () => {
        return {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                traceId: uuidv1()
            }
        };
    };
    let formData = {
        auth: {
            username: params.username,
            password: params.password
        }
    }
    switch (params.type) {
        case "Rest":
            formData = {
                ...formData,
                method: params.method,
                payload: params.jsonBody,
                header: params.jsonHeader,
                url: params.baseUrl,
                endpoint: params.endPoint
            };
            break;
        case "SOAP":
            formData = {
                ...formData,
                endpoint: params.endPoint,
                payload: params.payload,
                url: params.baseUrl
            };
            break;
        case "Database":
            break;
        default:
            break;
    }

    axios
        .post(prepareUrl, formData, getOptions())
        .then(respose => {

            console.log("*********Response ************", respose);
            if (respose.data !== "") {
                cb(respose.data);
            } else if (respose.status === 200) {
                cb({ "data": "Returned a status code of 200" });
            } else {
                cb({ "data": "Could not get any data, Please try again" });
            }
        })
        .catch(err => {
            cb(err);
        });
};

export const saveNewConnection = (oldData, newData, callback) => {
    return dispatch => {
        let dataMan = {};
        switch (newData.connectionType) {
            case "Rest":
                dataMan = {
                    auth: {
                        username: newData.username,
                        password: (newData.password)
                    },
                    url: newData.url
                };
                break;
            case "FTP":
                dataMan = {
                    host: newData.host,
                    port: newData.port,
                    user: newData.user,
                    password: (newData.password)
                };
                break;
            case "FILE":
                dataMan = {
                    path: newData.path
                };
                break;
            case "S3":
                dataMan = {
                    accessKeyId: newData.accessKeyId,
                    secretAccessKey: newData.secretAccessKey,
                    region: newData.region,
                    bucket: newData.bucket,
                }
                break;
            case "SOAP":
                dataMan = {
                    auth: {
                        username: newData.username,
                        password: (newData.password)
                    },
                    url: newData.url,
                    endpoint: newData.endpoint
                };
                break;
            case "Database":
                dataMan = {
                    auth: {
                        username: newData.username,
                        password: (newData.password)
                    },
                    host: newData.host,
                    port: newData.port,
                    database: newData.database,
                    dialect: newData.dialect
                };
                break;
            default:
                break;
        }
        let formData = {
            Name: newData.Name,
            TypeId: newData.TypeId,
            StatusId: 1,
            Active: true,
            Details: JSON.stringify(dataMan),
            CreatedBy: "Integrator-UI"
        };
        const prepareUrl = `${connectionUrl}/connections/insert`;

        axios
            .post(prepareUrl, formData, getOptions())
            .then(respose => {
                console.log("*********Response save connection ************", respose);
                if (respose.data.success) {
                    callback(true);
                    dispatch(setConnections());
                } else {
                    callback(false);
                }
            })
            .catch(err => {
                callback(false);
            });

        // const data = Object.keys(oldData).map(i => oldData[i]);
        // data.push({Id: uuidv1(), Active: 1, Name: newData.Name, Status: newData.Status, Type: newData.connectionType});
        // dispatch(getConnectionData(data));
        // callback(true);

        // add to backend
    };
};

export const deleteConnection = (connectionInformation, old, callback) => {
    return dispatch => {
        console.log("connection information ->", connectionInformation);
        let active;
        if (connectionInformation.Active == 1) {
            active = 0;
        } else {
            active = 1;
        }
        let newData = { ...connectionInformation, Active: active };
        const prepareUrl = `${connectionUrl}/connections/update`;
        axios
            .post(prepareUrl, newData, getOptions())
            .then(respose => {
                console.log("*********Response save connection ************", respose);
                if (respose.data.success) {
                    callback(true);
                    dispatch(setConnections());
                } else {
                    callback(false);
                }
            })
            .catch(err => {
                callback(false);
            });
    };
};

export const updateConnection = (updateData, updateType, callback) => {
    return dispatch => {
        let formData = {};
        let connectionType = updateData.editConnectionData;
        let dataPayload = {};
        let dataMan = {};

        switch (updateType) {
            case "Rest":
                dataPayload = {
                    body: updateData.jsonBody,
                    header: updateData.jsonHeader
                };
                dataMan = {
                    auth: {
                        username: updateData.username,
                        password: (updateData.password)
                    },
                    url: updateData.url,
                    endPoint: updateData.endPoint
                };
                formData = {
                    Id: updateData.Id,
                    Name: updateData.Name,
                    TypeId: updateData.TypeId,
                    StatusId: updateData.StatusId,
                    Active: updateData.Active,
                    Details: JSON.stringify(dataMan),
                    CreatedBy: "Integrator-UI",
                    PayloadId: updateData.PayloadId,

                };
                break;
            case REQUEST_REST:

                dataPayload = {
                    body: updateData.jsonBody,
                    header: updateData.jsonHeader
                };
                dataMan = {
                    auth: {
                        username: updateData.username,
                        password: (updateData.password)
                    },
                    url: updateData.baseUrl,
                    endPoint: updateData.endPoint
                };
                formData = {
                    "Id": connectionType.Id,
                    "Name": connectionType.Name,
                    "TypeId": connectionType.TypeId,
                    "StatusId": connectionType.StatusId,
                    "Active": connectionType.Active,
                    "Details": JSON.stringify(dataMan),
                    "CreatedBy": "Integrator-UI",
                    "Payload": JSON.stringify(dataPayload)
                };
                break;
            case "SOAP":
                connectionType = updateData.editConnectionData;

                dataPayload = {
                    payload: updateData.payload
                };

                dataMan = {
                    auth: {
                        username: updateData.username,
                        password: (updateData.password)
                    },
                    url: updateData.url,
                    endpoint: updateData.endpoint
                };


                formData = {
                    Id: updateData.Id,
                    Name: updateData.Name,
                    TypeId: updateData.TypeId,
                    StatusId: updateData.StatusId,
                    Active: updateData.Active,
                    Details: JSON.stringify(dataMan),
                    CreatedBy: "Integrator-UI",
                    PayloadId: updateData.PayloadId,
                    payload: updateData.payload
                };
                break;
            case REQUEST_SOAP:
                connectionType = updateData.editConnectionData;

                dataPayload = {
                    payload: updateData.payload,
                };

                dataMan = {
                    auth: {
                        username: updateData.username,
                        password: (updateData.password)
                    },
                    url: updateData.baseUrl,
                    endpoint: updateData.endpoint

                };
                formData = {
                    "Id": connectionType.Id,
                    "Name": connectionType.Name,
                    "TypeId": connectionType.TypeId,
                    "StatusId": connectionType.StatusId,
                    "Active": connectionType.Active,
                    "Details": JSON.stringify(dataMan),
                    "CreatedBy": "Integrator-UI",
                    "Payload": updateData.payload
                };
                break;
            case "Database":
                dataMan = {
                    auth: {
                        username: updateData.username,
                        password: (updateData.password)
                    },
                    host: updateData.host,
                    port: updateData.port,
                    database: updateData.database,
                    dialect: updateData.dialect
                };

                formData = {
                    Id: updateData.Id,
                    Name: updateData.Name,
                    TypeId: updateData.TypeId,
                    StatusId: updateData.StatusId,
                    Active: updateData.Active,
                    Details: JSON.stringify(dataMan),
                    CreatedBy: "Integrator-UI",
                    PayloadId: updateData.PayloadId
                };
                break;
            case "FTP":
                dataMan = {
                    host: updateData.host,
                    port: updateData.port,
                    user: updateData.user,
                    password: updateData.password
                };
                formData = {
                    Id: updateData.Id,
                    Name: updateData.Name,
                    TypeId: updateData.TypeId,
                    StatusId: updateData.StatusId,
                    Active: updateData.Active,
                    Details: JSON.stringify(dataMan),
                    CreatedBy: "Integrator-UI",
                    PayloadId: updateData.PayloadId,

                };
                break;
            case "FILE":
                dataMan = {

                    path: updateData.path,
                };
                formData = {
                    Id: updateData.Id,
                    Name: updateData.Name,
                    TypeId: updateData.TypeId,
                    StatusId: updateData.StatusId,
                    Active: updateData.Active,
                    Details: JSON.stringify(dataMan),
                    CreatedBy: "Integrator-UI",
                    PayloadId: updateData.PayloadId,

                };
                break;
            case "S3":
                dataMan = {
                    accessKeyId: updateData.accessKeyId,
                    secretAccessKey: updateData.secretAccessKey,
                    region: updateData.region,
                    bucket: updateData.bucket,
                };
                formData = {
                    Id: updateData.Id,
                    Name: updateData.Name,
                    TypeId: updateData.TypeId,
                    StatusId: updateData.StatusId,
                    Active: updateData.Active,
                    Details: JSON.stringify(dataMan),
                    CreatedBy: "Integrator-UI",
                    PayloadId: updateData.PayloadId,

                };
                break;
            default:
                break;
        }

        const prepareUrl = `${connectionUrl}/connections/update`;
        axios
            .post(prepareUrl, formData, getOptions())
            .then(respose => {
                console.log("*********Response save connection ************", respose);
                if (respose.data.success) {
                    callback(true);
                    dispatch(setEditable(false));
                    dispatch(setConnections());
                } else {
                    callback(false);
                }
            })
            .catch(err => {
                callback(false);
            });
    };
};

{
    /* update connection */
}

export const editConnection = connectionData => {
    return dispatch => {
        dispatch(addConnectionFlag(true));
        dispatch(setEditable(true));
        dispatch(setEditData(connectionData));
        dispatch(getPayload(connectionData.Id));
    };
};
