import {
    FETCH_ENDPOINT_PENDING,
    FETCH_ENDPOINT_SUCCESS,
    FETCH_ENDPOINT_ERROR,
    SET_PROCESSING_ENDPOINTS,
    SET_ACTIVE_ENDPOINT,
    SET_CONNECTION_ENDPOINTS,
    SET_FILTERED_CONNECTION,
    LOAD_FILTERED_CONNECTION,
    ERR_FILTERED_CONNECTION,
    LOAD_CONNECTION_ENDPOINTS,
    ERR_CONNECTION_ENDPOINTS
} from '../action-types/endpoint-action-types';

export const fetchEndpointPending = () => {
    return {
        type: FETCH_ENDPOINT_PENDING,
    };
};

export const fetchEndpointSuccess = endpoint => {
    return {
        type: FETCH_ENDPOINT_SUCCESS,
        payload: {endpoint},
    };
};

export const fetchEndpointError = error => {
    return {
        type: FETCH_ENDPOINT_ERROR,
        payload: {error},
    };
};

export const setProcessingEndpoint = payload => {
    return {
        type: SET_PROCESSING_ENDPOINTS,
        payload

    };
};

export const setActiveEndpoint = (payload) => {
    return {
        type: SET_ACTIVE_ENDPOINT,
        payload
    };
};


// handle endpoint comparator and its states

export const setFilteredConnection = (payload) => {
    return {
        type: SET_FILTERED_CONNECTION,
        payload
    };
};

export const setEndpointConnections = (payload) => {
    return {
        type: SET_CONNECTION_ENDPOINTS,
        payload
    };
};

export const setFilterConnectionLoading = (payload) => {
    return {
        type: LOAD_FILTERED_CONNECTION,
        payload
    };
};


export const setFilterConnectionError = (payload) => {
    return {
        type: ERR_FILTERED_CONNECTION,
        payload
    };
};

export const setConnectionEndLoading = (payload) => {
    return {
        type: LOAD_CONNECTION_ENDPOINTS,
        payload
    };
};

export const setConnectionEndErr = (payload) => {
    return {
        type: ERR_CONNECTION_ENDPOINTS,
        payload
    };
};