import React, { Component } from "react";
import { connect } from "react-redux";
import {
  setFilteredActiveConnection,
  updateEndpointConnection
} from "../../Services/endpoint";
import Loading from "../Loading";
import ShowError from "../ShowError";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import IconButton from "@material-ui/core/IconButton";
import InputIcon from "@material-ui/icons/Input";
import Divider from "@material-ui/core/Divider";
import ComStyle from "./comparator.css";
import Tooltip from "@material-ui/core/Tooltip";

class ActiveConnections extends Component {
  componentDidMount() {
    this.props.setFilteredActiveConnection(null);
  }

  handleAdd = data => {
    console.log("Adding this one ", data);
    this.props.updateEndpointConnection(this.props.activeEndpoint, data, "ADD");
  };

  showActiveConnection = () => {
    return (
      <div className="main_container">
        <div className="heading_col">Available Connections</div>
        <div className="column_container">
          <List>
            {this.props.filteredConnections.map(data => {
              return (
                <div>
                  <Divider />
                  <ListItem>
                    <ListItemText primary={data.Name} />
                    <ListItemSecondaryAction>
                      <Tooltip title="Add" aria-label="add">
                        <IconButton
                          edge="end"
                          aria-label="delete"
                          onClick={() => {
                            this.handleAdd(data);
                          }}
                        >
                          <InputIcon color={"primary"} />
                        </IconButton>
                      </Tooltip>
                    </ListItemSecondaryAction>
                  </ListItem>
                </div>
              );
            })}
          </List>
        </div>
      </div>
    );
  };

  render() {
    if (this.props.processing) {
      return (
        <div>
          <Loading />
        </div>
      );
    } else if (
      this.props.filteredConnections &&
      this.props.filteredConnections.length
    ) {
      return <div>{this.showActiveConnection()}</div>;
    } else if (
      this.props.filteredConnections &&
      this.props.filteredConnections.length === 0
    ) {
      return (
        <div>
          <div>
            <ShowError message={`All connections are linked`} />
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <div>
            <ShowError />
          </div>
        </div>
      );
    }
  }
}

const mapStateToProps = state => {
  return {
    filteredConnections: state.endpoints.filteredConnections,
    processing: state.endpoints.filterConnectionLoading,
    error: state.endpoints.filterConnectionError,
    activeEndpoint: state.endpoints.activeEndpoint
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setFilteredActiveConnection: val =>
      dispatch(setFilteredActiveConnection(val)),
    updateEndpointConnection: (endPoint, selecteditem, operation) =>
      dispatch(updateEndpointConnection(endPoint, selecteditem, operation))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ActiveConnections);
