import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Truncate from 'react-truncate';

class SimpleTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      TabName: '',
      Logs: [],
    };
  }

  componentWillMount() {
    this.setState({
      TabName: this.props.TabName,
      Logs: this.props.Logs,
    });
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      TabName: nextProps.TabName,
      Logs: nextProps.Logs,
    });
  }
  render() {
    return <div></div>;
  }
}

export default SimpleTable;
