import React, { PureComponent, Component } from 'react';
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    ResponsiveContainer,
    Tooltip,
    Legend,
} from 'recharts';

const axios = require('axios');
const { BASE_URL } = require('../../utils/Url');
const { apiAdapter } = require('../../utils/APIAdapter');

const newdata = [
    {
        name: '0',
        Value: 0,
    },
    {
        name: '2',
        Value: 200,
    },
    {
        name: '4',
        Value: 300,
    },
    {
        name: '6',
        Value: 300,
    },
];

class CompletionTimeChart extends Component {
    state = {
        loading: false,
    };

    //WARNING! To be deprecated in React v17. Use componentDidMount instead.
    componentWillMount() {
        let iterator = 1;
        const api = apiAdapter(BASE_URL);
        api.get(`/statistics/fetchAll`).then(res => {
            for (let i = 4; i < 7; i++) {
                // newdata[iterator].name = res.data.result[i].Name;
                newdata[iterator].Value = res.data.result[i].Value;
                iterator++;
            }
            this.setState({
                loading: true,
            });
        });
    }

    render() {
        return this.state.loading === false ? (
            ''
        ) : (
            <ResponsiveContainer width={'99%'} aspect={3}>
                <LineChart
                    width={500}
                    height={300}
                    data={newdata}
                    margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Line
                        type="monotone"
                        dataKey="Value"
                        stroke="#8884d8"
                        activeDot={{ r: 8 }}
                    />
                    {/* <Line type="monotone" dataKey="uv" stroke="#82ca9d" /> */}
                </LineChart>
            </ResponsiveContainer>
        );
    }
}

export default CompletionTimeChart;
