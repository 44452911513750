import theme from "../theme";

export const containers:any = {
    minHeight: 50,
    background: '#fff',
    borderRadius:2,
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    padding: theme.spacing(1),
    boxShadow: "7px 7px 5px #d4d3d4, -7px -7px 5px #efedf0"
};

export const CardTile :any = {
    border: '1px dashed gray',
    padding: '0.5rem 1rem',
    marginBottom: '.5rem',
    backgroundColor: 'white',
    cursor: 'move',
};

export const shadowed:any = {
    backgroundColor: "#fefeff",
    borderRadius: "8px",
    boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
};
