import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { fetchMessages } from "../../Services/messagequeue";
import Header from "./header";
import Loading from "../Loading";
import Box from "./box";
import Pagination from "../Common/pagination";
import { fetchEndpoints } from "../../Services/endpoint";
import { NotificationManager } from "react-notifications";

class Message extends Component {
  constructor(props) {
    super(props)
    this.state = {
      rowsPerPage: 10,
      filters: {
        Id: "",
        Time: "",
        Status: "",
        Source: "",
        Destination: "",
        SourceId: "",
        From: "",
        To: ""
      }
    };
  }

  handleChangePerPage = (currentPage, event) => {
    this.setState({
      rowsPerPage: event.target.value
    });
    this.props.fetchMessages(this.state.filters, currentPage, event.target.value);
  }

  validateTime = () => {
    return new Date(this.state.filters.From) > new Date(this.state.filters.To) ? false : true
  }

  componentDidMount() {
    this.props.fetchEndpoints();
    this.props.fetchMessages(this.state.filters, 1, 10);
  }

  getNewData = pageNumber => {
    this.props.fetchMessages(this.state.filters, pageNumber, this.state.rowsPerPage);
  };

  getMessages = () => {
    this.props.fetchMessages(this.state.filters, 1, this.state.rowsPerPage)
  };

  setFilters = event => {
    let newFilters = this.state.filters;
    if (event.target.name === "Time" && event.target.value === "All")
      newFilters[event.target.name] = "";
    else newFilters[event.target.name] = event.target.value;
    if (this.validateTime() === true)
      this.setState({ filters: newFilters })
    else {
      NotificationManager.error('Error', 'Invalid Dates!', 3000);
      newFilters = this.state.filters;
      newFilters = {
        "Time": newFilters.Time,
        "Status": newFilters.Status,
        "Source": newFilters.Source,
        "Destination": newFilters.Destination,
        "SourceId": newFilters.SourceId,
        "From": newFilters.From,
        "To": "",
      };

      this.setState({
        filters: newFilters
      });
    }
  };

  clearFilters = () => {
    let newFilters = this.state.filters;
    //functions to clear all values from filter object
    let setAll = (obj, val) => Object.keys(obj).forEach(k => (obj[k] = val));
    let setNull = newFilters => setAll(newFilters, "");
    setNull(newFilters);
    this.setState({ filters: newFilters });
  };

  render() {
    return (
      <div>

        {this.props.fetchData === false ? (
          <Loading />
        ) : (
            // <div></div>
            <>
              <Header
                endpoints={this.props.endpoints}
                pager={this.props.pagination}
                setFilters={this.setFilters}
                getMessages={this.getMessages}
                clearFilters={this.clearFilters}
                filters={this.state.filters}
              />
              {/* <div style={{ fontSize: "16px", fontWeight: "bold", textAlign: "right", border: "1px dotted", marginTop: "-18px", paddingRight: 15 }}>Showing {this.props.pagination.startIndex + 1} to {this.props.pagination.endIndex + 1} of {this.props.pagination.totalItems} results </div> */}
              {console.log(this.props.messages)}
              <Box messages={this.props.messages} />
              <Pagination
                getNewData={this.getNewData}
                pager={this.props.pagination}
                rowsPerPage={this.state.rowsPerPage}
                handleChangePerPage={this.handleChangePerPage}
              />
            </>
          )}


      </div>
    );
  }
}

const mapStateToProps = state => ({
  messages: state.messages.message,
  fetchData: state.messages.fetchData,
  pagination: state.messages.pager,
  endpoints: state.endpoints.endpoints
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchMessages: fetchMessages,
      fetchEndpoints: fetchEndpoints
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Message);
