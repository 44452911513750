import React, { Component } from 'react';
import { connect } from 'react-redux';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { withRouter } from 'react-router';
import ConnectionIcon from '@material-ui/icons/Link';
import MessageIcon from '@material-ui/icons/Message';
import TimerIcon from '@material-ui/icons/Timer';
import DashboardIcon from '@material-ui/icons/Dashboard';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import LoggerFilesComponent from '../LoggerFiles';
import GetAppIcon from '@material-ui/icons/GetApp';
import XSLs from '../Xsls';
import BubbleChartIcon from '@material-ui/icons/BubbleChart';
import SettingsInputComponentIcon from '@material-ui/icons/SettingsInputComponent';
import NotificationsActiveIcon from '@material-ui/icons/Notifications';
import HistoryIcon from '@material-ui/icons/History';


const drawerWidth = 240;
const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
    nestedChild: {
        paddingLeft: theme.spacing(8),
    },
    menuButton: { marginRight: theme.spacing(2) },
    title: { flexGrow: 1 },
    list: { width: 250 },
    fullList: { width: 'auto' },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
        overflow: 'hidden',
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: '0 8px',
        ...theme.mixins.toolbar,
        justifyContent: 'flex-end',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: -drawerWidth,
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    },
}));
const MaterialDrawer = data => {
    const { toggleNavigation, onToggleNavigation } = data;
    const classes = useStyles();
    const theme = useTheme();
    const [selectedIndex, setSelectedIndex] = React.useState(0);
    const [open, setOpen] = React.useState(false);
    const [openSettings, setOpenSettings] = React.useState(false);
    const [openChild, setOpenChild] = React.useState(false);
    const [xslModal, setXslModal] = React.useState(false);

    function handleClick() {
        setOpen(!open);
    }
    function handleClickSettings() {
        setOpenSettings(!openSettings);
    }
    function handleChildListClick() {
        setOpenChild(!openChild);
    }
    const opneModal = () => {
        setOpen(true);
    };

    const opneXslModal = () => {
        setXslModal(true);
    };

    const closeModal = () => {
        setOpen(false);
    };

    const closeXslModal = () => {
        setXslModal(false);
    };
    function handleListItemClick(event, index, route) {
        setSelectedIndex(index);
        let a = []
        if (sessionStorage.getItem("path") === null) {
            a.push(data.history.location.pathname)
            sessionStorage.setItem("path", JSON.stringify(a))
        }
        else {
            a = JSON.parse(sessionStorage.getItem("path"))
            if (data.history.location.pathname == '/createScheduler' || data.history.location.pathname == '/editScheduler') {
                data.history.location.pathname = '/scheduler';
            }
            if (data.history.location.pathname.includes('/timeline')) {
                data.history.location.pathname = '/messagequeue';
            }
            a.push(data.history.location.pathname)
            sessionStorage.setItem("path", JSON.stringify(a))
        }

        window.location.replace(route);
    }
    return (
        <div>
            {open === true && <LoggerFilesComponent closeModal={closeModal} />}
            {xslModal === true && <XSLs closeXslModal={closeXslModal} />}
            <Drawer
                open={toggleNavigation}
                className={classes.drawer}
                variant="persistent"
                anchor="left"
                classes={{ paper: classes.drawerPaper }}
            >
                <div className={classes.drawerHeader}>

                    <a href='/dashboard' >
                        <img src='./../../../gossas_logo.jpeg' ></img>
                    </a>{/* <span className="admin-drawer-header">GS-Integrator</span> */}
                    <IconButton onClick={onToggleNavigation}>
                        {theme.direction === 'ltr' ? (
                            <ChevronLeftIcon style={{ color: "#3F51B5" }} />
                        ) : (
                                <ChevronRightIcon style={{ color: "#3F51B5" }} />
                            )}
                    </IconButton>
                </div>
                <div className={classes.list} role="presentation">
                    <List>

                        <ListItem
                            button
                            selected={selectedIndex === 1}
                            onClick={event => handleListItemClick(event, 1, '/dashboard')}
                        >
                            <ListItemIcon>
                                <DashboardIcon style={{ color: "#3F51B5" }} />
                            </ListItemIcon>
                            <ListItemText primary="Dashboard" />
                        </ListItem>


                        <ListItem
                            button
                            selected={selectedIndex === 2}
                            onClick={event => handleListItemClick(event, 2, '/messagequeue')}
                        >
                            <ListItemIcon>
                                <MessageIcon style={{ color: "#3F51B5" }} />
                            </ListItemIcon>
                            <ListItemText primary="Messages" />
                        </ListItem>
                        {JSON.parse(localStorage.getItem("UserInfo")).RoleId === 1 &&
                            <React.Fragment>
                                <ListItem
                                    button
                                    selected={selectedIndex === 3}
                                    onClick={event => handleListItemClick(event, 3, '/configurations')}
                                >
                                    <ListItemIcon>
                                        <PowerSettingsNewIcon style={{ color: "#3F51B5" }} />
                                    </ListItemIcon>
                                    <ListItemText primary="Configurations" />
                                </ListItem>

                                <ListItem
                                    button
                                    selected={selectedIndex === 4}
                                    onClick={event => handleListItemClick(event, 4, '/scheduler')}
                                >
                                    <ListItemIcon>
                                        <TimerIcon style={{ color: "#3F51B5" }} />
                                    </ListItemIcon>
                                    <ListItemText primary="Schedulers" />
                                </ListItem>

                                <ListItem
                                    button
                                    selected={selectedIndex === 5}
                                    onClick={event => handleListItemClick(event, 5, '/connections')}
                                >
                                    <ListItemIcon>
                                        <ConnectionIcon style={{ color: "#3F51B5" }} />
                                    </ListItemIcon>
                                    <ListItemText primary="Connections" />
                                </ListItem>

                                <ListItem
                                    button
                                    selected={selectedIndex === 6}
                                    onClick={event => handleListItemClick(event, 6, '/endpoints')}
                                >
                                    <ListItemIcon>
                                        <SettingsInputComponentIcon style={{ color: "#3F51B5" }} />
                                    </ListItemIcon>
                                    <ListItemText primary="Endpoints" />
                                </ListItem>
                                <ListItem
                                    button
                                    selected={selectedIndex === 7}
                                    onClick={event => handleListItemClick(event, 7, '/notification')}
                                >
                                    <ListItemIcon>
                                        <NotificationsActiveIcon style={{ color: "#3F51B5" }} />
                                    </ListItemIcon>
                                    <ListItemText primary="Notifications" />
                                </ListItem>
                                <ListItem
                                    button
                                    selected={selectedIndex === 8}
                                    onClick={event => handleListItemClick(event, 8, '/audits')}
                                >
                                    <ListItemIcon>
                                        <HistoryIcon style={{ color: "#3F51B5" }} />
                                    </ListItemIcon>
                                    <ListItemText primary="Audits" />
                                </ListItem>
                            </React.Fragment>
                        }
                        <ListItem
                            button
                            selected={selectedIndex === 9}
                            onClick={event => handleListItemClick(event, 9, '/users')}
                        >
                            <ListItemIcon>
                                <GetAppIcon style={{ color: "#3F51B5" }} />
                            </ListItemIcon>
                            <ListItemText primary="User Profile" />
                        </ListItem>
                        {JSON.parse(localStorage.getItem("UserInfo")).RoleId === 1 &&
                            <React.Fragment>
                                <Divider />
                                <ListItem
                                    button
                                    selected={selectedIndex === 10}
                                    onClick={event => handleListItemClick(event, 10, '/mappings')}
                                >
                                    <ListItemIcon>
                                        <BubbleChartIcon style={{ color: "#3F51B5" }} />
                                    </ListItemIcon>
                                    <ListItemText primary="Mappings" />
                                </ListItem>
                                <ListItem
                                    button
                                    selected={selectedIndex === 11}
                                    onClick={event => handleListItemClick(event, 11, '/logs')}
                                >
                                    <ListItemIcon>
                                        <GetAppIcon style={{ color: "#3F51B5" }} />
                                    </ListItemIcon>
                                    <ListItemText primary="Download Logs" />
                                </ListItem>
                            </React.Fragment>
                        }

                    </List>

                </div>
            </Drawer>
        </div>
    );
};
class DrawerComponent extends Component {
    static propTypes = {
        toggleNavigation: PropTypes.bool,
        onToggleNavigation: PropTypes.func,
    };
    render() {
        return <MaterialDrawer {...this.props} />;
    }
}

export default withRouter(DrawerComponent);