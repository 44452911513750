export const FETCH_CONFIGURATIONS_PENDING = 'FETCH_CONFIGURATIONS_PENDING';
export const FETCH_CONFIGURATIONS_SUCCESS = 'FETCH_CONFIGURATIONS_SUCCESS';
export const FETCH_CONFIGURATIONS_ERROR = 'FETCH_SERVEYS_ERROR';

export const DELETE_CONFIGURATION_PENDING = 'DELETE_CONFIGURATION_PENDING';
export const DELETE_CONFIGURATION_SUCCESS = 'DELETE_CONFIGURATION_SUCCESS';
export const DELETE_CONFIGURATION_ERROR = 'DELETE_CONFIGURATION_ERROR';

export const ADD_CONFIGURATION_PENDING = 'ADD_CONFIGURATION_PENDING';
export const ADD_CONFIGURATION_SUCCESS = 'ADD_CONFIGURATION_SUCCESS';
export const ADD_CONFIGURATION_ERROR = 'ADD_CONFIGURATION_ERROR';

export const UPDATE_CONFIGURATION_PENDING = 'UPDATE_CONFIGURATION_PENDING';
export const UPDATE_CONFIGURATION_SUCCESS = 'UPDATE_CONFIGURATION_SUCCESS';
export const UPDATE_CONFIGURATION_ERROR = 'UPDATE_CONFIGURATION_ERROR';

export const CHANGE_STATUS_PENDING = 'CHANGE_STATUS_PENDING';
export const CHANGE_STATUS_SUCCESS = 'CHANGE_STATUS_SUCCESS';
export const CHANGE_STATUS_ERROR = 'CHANGE_STATUS_ERROR';

export const CHANGE_ISAUTHENTICATED = 'CHANGE_ISAUTHENTICATED';

export const TOGGLE_CONFIGURATIONS_DIALOG = 'TOGGLE_CONFIGURATIONS_DIALOG';

export const TOGGLE_DROPDOWN_STATE = 'TOGGLE_DROPDOWN_STATE';
export const CONFIG_HEALTH = 'CONFIG_HEALTH';