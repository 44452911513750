import React, { Component } from 'react';
import EndpointDrop from "../../Components/EndpointsDropDown";
import EndpointComparator from '../../Components/EndpointComparator';
import endPointStyle from './endpoints.css';
import Divider from "@material-ui/core/Divider";

class Endpoints extends Component {
    render() {
        return (
            <div style={{paddingLeft:55}}>

                <div className='row'>
                    <div className="col-6" >
                        <EndpointDrop />
                        <div>
                            <EndpointComparator />
                        </div>


                    </div>
                </div>
            </div>
        );
    }
}

export default Endpoints;