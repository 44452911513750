import React, { useEffect } from "react";
import { getPayload, sendRequest, updateConnection } from "../../Services/connection";
import Loader from "react-spinners/FadeLoader";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Divider from "@material-ui/core/Divider";
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import Button from "@material-ui/core/Button";
import SendIcon from "@material-ui/icons/Send";
import TabsHoc from "../../Hoc/Tabs";
import JSONInput from "react-json-editor-ajrm/index";
import locale from "react-json-editor-ajrm/locale/en";
import { useDispatch, useSelector } from "react-redux";
import { REQUEST_SOAP } from "../../utils/JsUtils";
import SaveIcon from "@material-ui/icons/Save";
import Snackbar from "../Snackbar";

export default function RequestSoap(props) {


    let initialData = {
        baseUrl: props.requestData.url,
        port: props.requestData.port,
        username: props.requestData.username,
        password: props.requestData.password,
        endPoint: props.requestData.endpoint,
        payload: '',
        jsonResponse: {},
        inValid: false,
        loading: false,
        edited: false,
        type: props.requestData.connectionType,
        message: { success: false, show: false }
    };

    const [requestData, setData] = React.useState(initialData);

    const { editConnectionData, payload } = useSelector(state => ({
        ...state.connections_reducer
    }),
        state => state);

    // dispatch action

    const dispatch = useDispatch();


    useEffect(() => {
        if (!requestData.edited && editConnectionData) {
            let payloadData = "";
            let endPoint = props.requestData.endpoint;
            let Id = editConnectionData.Id;
            if (payload) {
                payloadData = payload.replace(/\\"/g, '"');

            }


            if (editConnectionData.Details) {
                let details = JSON.parse(editConnectionData.Details);
                if (details.endPoint) {
                    endPoint = details.endPoint;
                }
            }
            let newData = { ...requestData, editConnectionData: editConnectionData, edited: true, endpoint: endPoint, payload: payloadData };
            setData(newData);
        }
    });

    const updateRequest = () => {
        dispatch(updateConnection(requestData, REQUEST_SOAP, (response) => {

            let newData = { ...requestData, message: { success: response, show: true } };
            setData(newData);
            setTimeout(function () {
                props.handleParentClose();
                props.handleClose();
            }, 1000);

        }));
    };

    const handleChange = event => {
        console.log("event data value", event.target.name, event.target.value);
        let data = { ...requestData, [event.target.name]: event.target.value, inValid: false };
        setData(data);
    };

    const handleJson = (event, name) => {
        let data = { ...requestData, [name]: event.jsObject, inValid: false };
        setData(data);
    };

    const handleSendRequest = () => {
        if (validator({
            ...requestData
        })) {

            setData({ ...requestData, loading: true });
            sendRequest({ ...requestData }, (res) => {
                let data = { ...requestData, jsonResponse: res, loading: false };
                setData(data);

            });
        } else {
            let data = { ...requestData, inValid: true };
            setData(data);
        }
    };

    const validator = (formData) => {
        if (formData.endPoint !== "") {
            return true;
        } else {
            return false;
        }
    };

    return (
        <div style={{ padding: 15 }}>
            {requestData.inValid ?
                <div className="col-12" style={{ fontWeight: '500', color: 'red', textAlign: 'center' }}>
                    *Please fill the required fields*
                </div> : null}

            <div className='row' style={{ justifyContent: 'center' }}>{
                requestData.loading ? <Loader /> : null
            }</div>



            <Divider style={{ marginTop: 10, marginBottom: 10 }} />

            <div className="row">
                <div className="col-6" style={{ textAlign: 'left' }}>
                    <div style={{ fontWeight: '500', fontSize: 24, color: '#9a2953' }}>
                        Request
                    </div>
                </div>
                <div className="col-3" style={{ textAlign: 'right' }}>

                    {editConnectionData && editConnectionData.Id &&
                        <Button
                            variant="contained"
                            color="default"
                            size="medium"
                            onClick={() => {
                                updateRequest();
                            }}
                            startIcon={<SaveIcon />}>
                            Save
                    </Button>}

                </div>
                <div className="col-3" style={{ textAlign: 'right' }}>
                    <Button
                        variant="contained"
                        color="default"
                        size="medium"
                        onClick={() => {
                            handleSendRequest();
                        }}
                        startIcon={<SendIcon />}>
                        Send
                    </Button>

                </div>
            </div>
            <Divider style={{ marginTop: 10, marginBottom: 30 }} />
            <div style={{ fontWeight: '500', fontSize: 24 }}>Payload</div>
            <TextareaAutosize aria-label="Payload" name="payload" id="payload" variant="outlined"
                style={{ width: '100%' }} onChange={handleChange} value={requestData.payload}
                style={{ minWidth: '100%', minHeight: 120 }} />

            <div style={{ marginTop: 20 }}>

            </div>

            <Divider style={{ marginTop: 10, marginBottom: 10 }} />

            <div style={{ fontWeight: '500' }}>
                Response
            </div>

            <div style={{ marginTop: 20 }}>

                <JSONInput
                    aria-label="jsonResponse" name="jsonResponse" id="jsonResponse" variant="outlined"
                    style={{ width: '100%' }} onChange={handleChange} placeholder={requestData.jsonResponse}
                    style={{ minWidth: '100%', minHeight: 120, maxHeight: 150 }}
                    theme="light_mitsuketa_tribute" locale={locale}
                    confirmGood={false}
                    viewOnly={true}
                    reset={false}
                    onKeyPressUpdate={false}
                    colors={{
                        string: "#DAA520" // overrides theme colors with whatever color value you want
                    }}
                    height="150px"
                    maxWidth="150%" />

            </div>

            <div>
                {requestData.message.show && requestData.message.success ?
                    <Snackbar message={'Connection updated '} severity={'success'} /> : null}
                {requestData.message.show && !requestData.message.success ?
                    <Snackbar message={'Unable to save, Please try again'} severity={'error'} /> : null}
            </div>
        </div>
    );
};
