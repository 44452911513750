import React from 'react';
import ReactDOM from 'react-dom';
import { ConnectedRouter } from 'connected-react-router';
import history from '../utils/history';
import AdminContainer from '../Containers/Admin';
import { BrowserRouter as Router, Switch, Route, Link,Redirect } from 'react-router-dom';
import LoginComponent from '../Components/SignIn';
import AddNotification from  '../Containers/Notifications/AddNotification';
import Notification from  '../Containers/Notifications/index';
import Audits from '../Containers/AuditTrail/index';
import 'bootstrap/dist/css/bootstrap.min.css';

// Import CSS reset and Global Styles
import '../styles/theme.scss';
import PrivateRoute from '../Components/PrivateRoute';
import Mappings from "../Containers/Mappings";
import DownloadLogs from "../Containers/DownloadLogs";
import NoMatch from "../Components/NoMatch";

function App() {
    return (
        <ConnectedRouter history={history}>
            <Router>
                <Switch>
                    {/* Do not show this route if isAuthenticated already true */}
                    {/* <Route path="/login" exact component={LoginComponent} /> */}
                    {/* <PrivateRoute path="/" exact component={AdminContainer} /> */}
                    
                    <PrivateRoute path="/" exact component={AdminContainer} />
                    <PrivateRoute path="/dashboard" exact component={AdminContainer} />
                    <PrivateRoute path="/messagequeue" exact component={AdminContainer} />
                    <PrivateRoute path="/messagestatus/:Id" exact component={AdminContainer} />
                    <PrivateRoute path="/configurations" exact component={AdminContainer} />
                    <PrivateRoute path="/scheduler" component={AdminContainer} />
                    <PrivateRoute path="/createScheduler" component={AdminContainer} />
                    <PrivateRoute path="/editScheduler" component={AdminContainer} />
                    {/* <PrivateRoute path="/logs" component={AdminContainer} /> */}
                    <PrivateRoute path="/endpoints" component={AdminContainer} />
                    <PrivateRoute path="/connections" component={AdminContainer} />
                    {/* <PrivateRoute path="/Dashboard" component={AdminContainer} /> */}
                    <PrivateRoute path="/timeline/:Id" component={AdminContainer} />
                    <PrivateRoute path="/AddNotification" component={AdminContainer} />
                    <PrivateRoute path="/notification" component={AdminContainer} />
                    <PrivateRoute path="/editNotification" component={AdminContainer} />
                    <PrivateRoute path="/mappings" component={AdminContainer} />
                    <PrivateRoute path="/logs" component={AdminContainer} />
                    <PrivateRoute path="/audits" component={AdminContainer} />
                    <PrivateRoute path="/users" component={AdminContainer} />
                    <PrivateRoute
                        path="/loggerFile/Download"
                        component={AdminContainer}
                    />
                    <Route path="*" component={NoMatch}/>
                    <AdminContainer />
                </Switch>
            </Router>
        </ConnectedRouter>
    );
}

export default App;
