import {
    GET_CONNECTIONS_DATA,
    GET_CONNECTIONS_PROCESSING,
    GET_CONNECTIONS_ERROR,
    CONNECTIONS_TYPE_PROCESSING,
    SET_CONNECTION_TYPES,
    SET_CONNECTION_TYPES_ERR,
    ADD_CONNECTION_FLAG,
    SET_EDIT_DATA,
    SET_EDITABLE, SET_PAYLOAD
} from '../action-types/connections-action-types';

export const getConnectionData = (data) => {
    return {
        type: GET_CONNECTIONS_DATA,
        payload: data
    };
};

export const getConnectionProcessing = (processing) => {
    return {
        type: GET_CONNECTIONS_PROCESSING,
        payload: processing
    };
};

// this action will show error

export const getConnectionError = (error) => {
    return {
        type: GET_CONNECTIONS_ERROR,
        payload: error
    };
};

export const connectionTypesLoading = (processing) => {
    return {
        type: CONNECTIONS_TYPE_PROCESSING,
        payload: processing
    };
};

export const setConnecionTypes = (connectionTypes) => {
    return {
        type: SET_CONNECTION_TYPES,
        payload: connectionTypes
    };
};

export const connectionTypesErr = (err) => {
    return {
        type: SET_CONNECTION_TYPES_ERR,
        payload: err
    };
};

export const addConnectionFlag = (err) => {
    return {
        type: ADD_CONNECTION_FLAG,
        payload: err
    };
};


export const setEditData = (editData) => {
    return {
        type: SET_EDIT_DATA,
        payload: editData
    };
};

export const setEditable = (flag) => {
    return {
        type: SET_EDITABLE,
        payload: flag
    };
};

export const setPayload = (data) => {
    return {
        type: SET_PAYLOAD,
        payload: data
    };
};