import React, { Component } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Grid from "@material-ui/core/Grid";
import { NotificationManager } from "react-notifications";
import { TextField } from "@material-ui/core";

const axios = require("axios");
const { FILE_URL } = require("../../utils/Url");
const apiAdapter = require("../../utils/APIAdapter").apiAdapter;
// const Successnotification = createNotification('success');
// const ErrorNotification = createNotification('error');

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    flexWrap: "wrap"
  },

  formControl: {
    margin: theme.spacing(1)
    // minWidth: 120
    // width: '100%',
  }
}));

class DownoadXsl extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
      files: [],
      fetchFiles: false,
      downloadPending: false,
      fileName: "",
      address: "",
      fileName: ""
    };
  }

  updateSelectValue = event => {
    this.setState({
      fileName: event.target.value
    });
  };

  handleMenuItemClick = event => {
    this.setState({
      fileName: event.target.value
    });
    this.props.setFile(event.target.value);
  };

  fetchFiles = () => {
    let files;
    const api = apiAdapter(`${FILE_URL}/filestorage/getXsl`);
    api.get().then(res => {
      res.data.result.files.map(row => {
        this.setState({
          files: [...this.state.files, row]
        });
      });
      this.setState({
        fetchFiles: true
      });
    });
  };

  componentDidMount() {
    this.fetchFiles();
  }

  validateFileName = () => {
    if (this.state.fileName === "")
      NotificationManager.error("Please select a file", "Error");
    else
      window.open(
        FILE_URL + "/filestorage/xsl/download?fileName=" + this.state.fileName + "&User=" + localStorage.getItem("UserInfo"),
        "_blank"
      );
  };

  render() {
    // const { classes } = this.props;
    return (
      <div>
        <FormControl fullWidth>

          {this.state.fetchFiles === true && (
            <TextField
              select
              label="Select File"
              value={this.state.fileName}
              onChange={this.handleMenuItemClick}
              variant="outlined"
            >
              {this.state.files.map(row => {
                return (
                  <MenuItem
                    selected={this.state.fileName === row}
                    key={row}
                    value={row}
                  >
                    {row}
                  </MenuItem>
                );
              })}
            </TextField>
          )}
        </FormControl>
      </div>
    );
  }
}

export default DownoadXsl;
