import {
    FETCH_MESSAGE_ERROR,
    FETCH_MESSAGE_SUCCESS,
    FETCH_MESSAGE_PENDING,
    UPDATE_MESSAGE_ERROR,
    UPDATE_MESSAGE_SUCCESS,
    UPDATE_MESSAGE_PENDING,
    SET_MESSAGES_ERROR,
    SET_MESSAGES_LOADING,
    SET_MESSAGES_STATS,
    SET_MESSAGE_ANALYTICS,
    SET_MESSAGE_ANALYTICS_LOADING,
    SET_MESSAGE_ANALYTICS_ERROR
} from "../action-types/messagequeue-action-types";

const initialState = {
    fetchData: false,
    message: [],
    loading: false,
    error: null,
    messageStats: [],
    messagesStatsLoading: false,
    messageStatsError: false,
    MessageAnalytics: null,
    MessageAnalyticsLoading: false,
    MessageAnalyticsError: false
};

export default function messageReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_MESSAGE_PENDING:
            return {
                ...state,
                loading: true,
                error: null
            };

        case FETCH_MESSAGE_SUCCESS:
            return {
                ...state,
                loading: false,
                message: action.payload.message.result,
                pager: action.payload.message.pager,
                fetchData: true
            };
        case FETCH_MESSAGE_ERROR:
            return {
                ...state,
                loading: false,
                error: action.error,
                message: []
            };
        case UPDATE_MESSAGE_PENDING:
            return {
                ...state,
                loading: true,
                error: null
            };
        case UPDATE_MESSAGE_SUCCESS:
            var newMessage = [];
            newMessage = state.message.slice();
            var index = newMessage.findIndex(x => x.ID === action.payload.ID);
            newMessage[index].CurrentStatus = action.payload.StatusName;
            newMessage[index].RetryCount = newMessage[index].RetryCount + 1;
            return {
                ...state,
                loading: false,
                message: newMessage
            };
        case UPDATE_MESSAGE_ERROR:
            return {
                ...state,
                loading: false,
                error: true,
                message: []
            };

        case SET_MESSAGES_LOADING:
            return {
                ...state,
                messagesStatsLoading: action.payload

            };

        case SET_MESSAGES_STATS:
            return {
                ...state,
                messageStats: action.payload
            };

        case SET_MESSAGES_ERROR:
            return {
                ...state,
                messageStatsError: action.payload
            };

        case SET_MESSAGE_ANALYTICS:
            return {
                ...state,
                MessageAnalytics: action.payload
            };

        case SET_MESSAGE_ANALYTICS_LOADING:
            return {
                ...state,
                MessageAnalyticsLoading: action.payload
            };

        case SET_MESSAGE_ANALYTICS_ERROR:
            return {
                ...state,
                MessageAnalyticsError: action.payload
            };
        default:
            return state;
    }
}
