import axios from '../utils/axios';

import * as actions from '../action-types/user-action-types';

export const setLoginStatus = (loginStatus) => {
    return {
        type: actions.LOGIN,
        payload: { loginStatus }
    };
};

export const setUser = (users) => {
    return {
        type: actions.REGISTER,
        payload: users
    };
};

export const fetchUser = (users) => {
    return {
        type: actions.FETCH_USER,
        payload: { users }
    };
};

export const updateUser = (user) => {
    return {
        type: actions.UPDATE_USER,
        payload: { user }
    };
};

export const addUser = (user) => {
    return {
        type: actions.ADD_USER,
        payload: { user }
    }
}

export const processing = (status) => {
    return {
        type: actions.PROCESSING,
        payload: status
    };
};

export const error = (error) => {
    return {
        type: actions.ERROR,
        payload: { error }
    };
};





