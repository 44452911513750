export const FETCH_MESSAGE_PENDING = 'FETCH_MESSAGE_PENDING';
export const FETCH_MESSAGE_SUCCESS = 'FETCH_MESSAGE_SUCCESS';
export const FETCH_MESSAGE_ERROR = 'FETCH_MESSAGE_ERROR';

export const UPDATE_MESSAGE_PENDING = 'UPDATE_MESSAGE_PENDING';
export const UPDATE_MESSAGE_SUCCESS = 'UPDATE_MESSAGE_SUCCESS';
export const UPDATE_MESSAGE_ERROR = 'UPDATE_MESSAGE_ERROR';

export const SET_MESSAGES_STATS = 'SET_MESSAGES_STATS';
export const SET_MESSAGES_LOADING = 'SET_MESSAGES_LOADING';
export const SET_MESSAGES_ERROR = 'SET_MESSAGES_ERROR';

export const SET_MESSAGE_ANALYTICS = 'SET_MESSAGE_ANALYTICS';
export const SET_MESSAGE_ANALYTICS_LOADING = 'SET_MESSAGE_ANALYTICS_LOADING';
export const SET_MESSAGE_ANALYTICS_ERROR = 'SET_MESSAGE_ANALYTICS_ERROR';