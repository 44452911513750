import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import PublishIcon from '@material-ui/icons/Publish';

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    input: {
        display: 'none',
    },
}));

export default function UploadButtons(props) {
    const classes = useStyles();

    return (
        <>
            <input
                // accept="image/*"
                onChange={(event) => props.onFileChange(event)}
                className={classes.input}
                id="UploadXSLT"
                multiple
                type="file"
            />
            <label htmlFor="UploadXSLT">
                <Button size="small" startIcon={<SaveIcon />} variant="contained" color="primary" component="span">
                    Choose File
                </Button>
            </label>
            <Tooltip title="Upload">
                <IconButton aria-label="UploadFile" className={classes.margin} onClick={props.onUploadCLick} size="small">
                    <PublishIcon fontSize="small" />
                </IconButton>
            </Tooltip>
        </>
    );
}