import React, { Component } from 'react';
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import CreateUser from "./createUser";
import UpdateUser from "./updateUser";
import UserForm from "./form";
import Loading from "../Loading";
import { connect } from 'react-redux';
import { fetchUsers, upsertUsers } from '../../Services/user';
import { bindActionCreators } from 'redux';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';

const BaseCompoent = (props) => {
    //view means show, add means add new user, update means update new user.
    const [formState, setFormState] = React.useState("view");
    const [values, setValues] = React.useState({
        selectdUserName: '',
        role: '',
        Id:'',
        username: '',
        password: '',
        confirmpassword: '',
        passwordMatch: true,
        showPassword: false,
    });

    React.useEffect(() => {
        if (props.users.length > 0) {
            let loggedInUser = props.users.filter(row => {
                return row.Username === JSON.parse(localStorage.getItem('UserInfo')).Username
            });
            setValues({
                ...values,
                selectdUserName: loggedInUser[0].Username,
                Id:loggedInUser[0].Id,
                username: loggedInUser[0].Username,
                password: "",
                confirmpassword: "",
                role: loggedInUser[0].role.Name
            })
            setFormState("view")
        }
    }, [props.users])

    const handleClickShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword });
    };

    const handleValueChange = (prop, event) => {
        setValues({ ...values, [prop]: event.target.value });
    };

    const handleUserChange = (event, newUserName, newRole, newPassword,Id) => {
        setValues({
            ...values,
            selectdUserName: event.target.value,
            Id:Id,
            username: newUserName,
            role: newRole,
            confirmpassword: "",
            password: "",
            passwordMatch: true,
        })
    }

    const handleRoleChange = (event) => {
        setValues({
            ...values,
            role: event.target.value
        })
    }

    React.useEffect(() => {
        props.fetchUsers()
    }, [])

    const handleButtonClick = (value) => {
        // if ()
    }

    const handleUserSubmitButton = () => {
        let userFound = false
        for (let each in props.users) {
            console.log("IN")
            if (JSON.parse(localStorage.getItem('UserInfo')).Username !== props.users[each].Username || formState === "add") {
                console.log(values.username, "-----" ,props.users[each].Username , " ----------",values.username === props.users[each].Username)
                if (values.username === props.users[each].Username) {
                    userFound = true
                }
            }
        }

        let userRole = {}
        let userId = ""
        console.log("USER FOUND",userFound)
        //Only validate User if a new user being added not on user update
        if(userFound && formState === "add")
            NotificationManager.error('Username already taken.', 'Error');
        else if(values.passwordMatch != true)
            NotificationManager.error('Passwords do not match.', 'Error');
        else if (values.username === '' || values.password === '' || values.confirmpassword === '')
            NotificationManager.error('Please fill all fields.', 'Error');
        else {
            for (let each of props.roles) {
                if (each.Name === values.role)
                    userRole = each
            }
            for (let each in props.users) {
                if (props.users[each].Username == values.username)
                    userId = props.users[each].Id
            }
            if (formState === "add")
                props.upsertUsers({
                    Username: values.username,
                    Password: values.password,
                    CreatedBy: JSON.parse(localStorage.getItem("UserInfo")).Username,
                    ModifiedBy: JSON.parse(localStorage.getItem("UserInfo")).Username,
                    Active:1,
                    role: userRole
                },formState)
            else
                props.upsertUsers({
                    Id:values.Id,
                    Username: values.username,
                    Password: values.password,
                    ModifiedBy: JSON.parse(localStorage.getItem("UserInfo")).Username,
                    Active:1,
                    role: userRole
                },formState)
        }

    }

    return (
        <div>
            {props.processing === true ?
                <Loading />
                :
                <React.Fragment>
                    <Grid container spacing={2}>
                        <Grid container justify="flex-start" direction="row" item xs={12} md={12} lg={12} xl={12}>
                            {formState === "view" &&
                                <React.Fragment>
                                    {
                                        JSON.parse(localStorage.getItem("UserInfo")).RoleId === 1 &&
                                        <Button onClick={() => setFormState('add')} size="medium" variant="contained" color="primary">
                                            Add
                                        </Button>
                                    }
                                &nbsp;
                                <Button key="2" onClick={() => setFormState('edit') } size="medium" variant="contained" color="primary">
                                    Edit
                                </Button>
                                </React.Fragment>
                            }
                            {formState === "add" &&
                                <React.Fragment>
                                    <Button onClick={handleUserSubmitButton} size="medium" variant="contained" color="primary">
                                        Save
                                    </Button>
                                        &nbsp;
                                    <Button onClick={() => setFormState('view')} size="medium" variant="contained" color="primary">
                                        Cancel
                                    </Button>
                                </React.Fragment>
                            }
                            {
                                formState === "edit" &&
                                <React.Fragment>
                                    <Button onClick={handleUserSubmitButton} size="medium" variant="contained" color="primary">
                                        Update
                                    </Button>
                                    &nbsp;
                                    <Button onClick={() => setFormState('view')} size="medium" variant="contained" color="primary">
                                        Cancel
                                    </Button>
                                </React.Fragment>
                            }
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid container item xs={12} md={12} lg={9} xl={9}>
                            <UserForm formValues={values}
                                handleRoleChange={handleRoleChange}
                                handleUserChange={handleUserChange}
                                handleChange={handleValueChange}
                                handleClickShowPassword={handleClickShowPassword}
                                formState={formState} {...props}
                            />
                        </Grid>
                    </Grid>
                </React.Fragment>
            }

        </div>
    )
}

const mapStateToProps = state => ({
    users: state.user_reducer.users,
    roles: state.user_reducer.roles,
    processing: state.user_reducer.processing
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            fetchUsers: fetchUsers,
            upsertUsers: upsertUsers,

        },
        dispatch,
    );

var connected = connect(
    mapStateToProps,
    mapDispatchToProps,
)(BaseCompoent);
export default connected;

