import {
    FETCH_XML_ERROR,
    FETCH_XML_SUCCESS,
    FETCH_XML_PENDING,
} from '../action-types/messageViewer-action-types';

const initialState = {
    xml: '',
    loading: false,
    error: null,
};

export function xmlReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_XML_PENDING:
            return {
                ...state,
                loading: true,
                error: null,
            };

        case FETCH_XML_SUCCESS:
            return {
                ...state,
                loading: false,
                xml: action.payload.xml,
            };
        case FETCH_XML_ERROR:
            return {
                ...state,
                loading: false,
                error: true,
                xml: '',
            };
        default:
            return state;
    }
}
