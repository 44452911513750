import { createMuiTheme } from '@material-ui/core';

import palette from './palette';
import typography from './typography';
import overrides from './overrides';


// @ts-ignore
const theme = createMuiTheme({
  palette,
  //@ts-ignore
  typography,
  overrides,
  props: {
  
    MuiListItem: {
      dense: true,
    },

    MuiAppBar :{
      //@ts-ignore
      variant: 'small'
    },
    MuiSlider: {
      //@ts-ignore
      dense: true
    },
    MuiFab: {
      size: 'small',
    },
    MuiTable: {
      size: 'small'
    },
    MuiToolbar: {
      variant: 'dense'
    },
  },
  zIndex: {
    appBar: 1200,
    drawer: 1100
  }
});

export default theme;
