import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { connect } from 'react-redux';
import { fetchLogs } from '../../Services/logs';
import { bindActionCreators } from 'redux';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Truncate from 'react-truncate';
import InfiniteScroll from 'react-infinite-scroll-component';
import LogTabs from '../LogTabs';
import LogTable from '../LogTable';

const { BASE_URL } = require('../../utils/Url');
const axios = require('axios');

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            <Box p={3}>{children}</Box>
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

class SimpleTabs extends Component {
    constructor(props) {
        super(props);
        // this.state.Logs = this.state.Logs.bind(this);
        // this.state.LogTypes = this.state.LogTypes.bind(this);
        // this.state.value = this.state.value.bind(this);
        this.state = {
            open: false,
            value: 0,
            LogTypes: [],
            Logs: [],
        };
    }

    componentWillMount = () => {
        this.setState({
            LogTypes: this.props.LogTypes,
            Logs: this.props.Logs,
        });
    };

    fetchMoreLogs = () => {
        axios.get(BASE_URL + `/cloudWatch/fetchLogs`).then(res => {
            console.log(res);
        });
    };

    handleChange = (event, newValue) => {
        this.setState({ value: newValue });
    };

    handleClickOpen = () => {
        this.setState({ open: true });
    };

    handleClose = () => {
        this.setState({ open: false });
    };

    render() {
        return (
            <div>
                <AppBar position="static">
                    <Tabs
                        value={this.state.value}
                        onChange={this.handleChange}
                        aria-label="simple tabs example"
                    >
                        {this.props.LogTypes &&
                            this.props.LogTypes.map(row => {
                                return (
                                    <Tab key={row.Id} label={row.type} {...a11yProps(row.Id)} />
                                );
                            })}
                    </Tabs>
                </AppBar>
                {this.props.LogTypes &&
                    this.props.LogTypes.map(row => {
                        return (
                            <TabPanel key={row.Id} value={this.state.value} index={row.Id}>
                                <Paper>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Date</TableCell>
                                                <TableCell>Service Name</TableCell>
                                                <TableCell>Message</TableCell>
                                            </TableRow>
                                        </TableHead>

                                        {/* <InfiniteScroll
                        dataLength={10}
                        next={this.fetchMoreData}
                        hasMore={true}
                        loader={<h4>Loading...</h4>}
                      > */}
                                        <TableBody>
                                            {this.props.Logs &&
                                                this.props.Logs.map(
                                                    (newRow, key) =>
                                                        newRow.type === row.type && (
                                                            <TableRow key={key}>
                                                                <TableCell component="th" scope="row">
                                                                    {newRow.date.slice(0, 10)}{' '}
                                                                    {newRow.date.slice(12, 19)}
                                                                </TableCell>
                                                                <TableCell component="th" scope="row">
                                                                    {newRow.seriveName}
                                                                </TableCell>
                                                                <TableCell component="th" scope="row">
                                                                    <Truncate lines={1}>
                                                                        {newRow.message}
                                                                    </Truncate>
                                                                </TableCell>
                                                                {/* <TableCell>
                                  <Button
                                    variant="outlined"
                                    color="primary"
                                    onClick={this.handleClickOpen}
                                  >
                                    Show Details
                                  </Button>
                                </TableCell> */}
                                                                <Dialog
                                                                    open={this.state.open}
                                                                    onClose={this.handleClose}
                                                                    aria-labelledby="form-dialog-title"
                                                                >
                                                                    <DialogTitle id="form-dialog-title" >
                                                                        {newRow.date.slice(0, 10)}{' '}
                                                                        {newRow.date.slice(12, 19)}
                                                                    </DialogTitle>
                                                                    <DialogContent>
                                                                        <DialogContentText>
                                                                            {newRow.message}
                                                                        </DialogContentText>
                                                                    </DialogContent>
                                                                    <DialogActions>
                                                                        <Button
                                                                            onClick={this.handleClose}
                                                                            color="primary"
                                                                        >
                                                                            Close
                                                                        </Button>
                                                                    </DialogActions>
                                                                </Dialog>
                                                            </TableRow>
                                                        ),
                                                )}
                                        </TableBody>
                                        {/* </InfiniteScroll> */}
                                    </Table>
                                </Paper>
                            </TabPanel>
                        );
                    })}
            </div>
        );
    }
}

class LogComponent extends Component {
    componentWillMount() {
        this.props.fetchLogs();
    }

    render() {
        return (
            <div>
                <SimpleTabs {...this.props} />
            </div>
        );
    }
}

const mapStateToProps = state => ({
    Logs: state.logsReducer.logs[0],
    nextToken: state.logsReducer.logs[1],
    LogTypes: state.logsReducer.logs[2],
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            fetchLogs: fetchLogs,
        },
        dispatch,
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(LogComponent);
