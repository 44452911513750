import React, { useState, useEffect, Component } from "react";
import {
  VerticalTimeline,
  VerticalTimelineElement
} from "react-vertical-timeline-component";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import "react-vertical-timeline-component/style.min.css";
import WorkIcon from "@material-ui/icons/Work";
import ThumbUpAltIcon from "@material-ui/icons/ThumbUpAlt";
import SmsFailedIcon from "@material-ui/icons/SmsFailed";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import RefreshIcon from "@material-ui/icons/Refresh";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import ErrorIcon from "@material-ui/icons/Error";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { connect } from "react-redux";
import { fetchStatuses } from "../../Services/messagestatus";
import Button from "@material-ui/core/Button";
import JSONPretty from "react-json-prettify";
import { github } from "react-json-prettify/dist/themes";

import {
  NewClass,
  InitializedClass,
  ReadyClass,
  CompletedClass,
  FailedClass,
  ErrorClass,
  NotRequiredClass
} from "../../styles/StatusColors";
import Loading from "../Loading";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

const useStyles = {
  paper: {
    border: "2px solid white",
    padding: "10px",
    width: "90%",
    margin: "auto",
    maxWidth: "100%",
    borderRadius: "3px",
    height: "6%"
  },
  bold: {
    fontWeight: "bold"
  },
  refreshButton: {
    margin: "2%"
  },
  time: {
    padding: "2px",
    color: "black",

  },
};
const getCurrentDate = () => {
  let parsedDate = new Date().toLocaleString().split(", ")
  return `${parsedDate[0].split("/").reverse("").join("-")} ${parsedDate[1]}`
}

function ErrorMessage(message, status) {
  const [hidden, setHidden] = useState(0);
  const customTheme = {
    ...github,
    background: "white",
  };

  function changeState() {
    setHidden(!hidden);
  }
  return (
    <div>
      <button
        id="messageButton"
        className={
          message.status === "Initialized"
            ? InitializedClass
            : message.status === "Ready"
              ? ReadyClass
              : message.status === "Failed"
                ? FailedClass
                : message.status === "Completed"
                  ? CompletedClass
                  : message.status === "Error"
                    ? ErrorClass
                    : message.status === "Not Required"
                      ? NotRequiredClass
                      : NewClass
        }
        onClick={changeState}
        disabled={message.message === null ? true : false}
      >
        {message.status}
      </button>
      <br />
      <br />
      {hidden === true ? (
        <div>
          <h5>Message</h5> <JSONPretty json={message.message} theme={customTheme} padding={3} /> 
        </div>
      ) : (
          <div></div>
        )}
    </div>
  );
}

function showTimeSpan(secs) {
  var seconds = secs / 1000;
  var minutes = 0;
  var hours = 0;

  if (seconds > 59) {
    minutes = seconds / 60;
    seconds = seconds % 60 | 0;
    if (minutes > 59) {
      hours = (minutes / 60) | 0;
      minutes = minutes % 60 | 0;
    } else {
      minutes = minutes | 0;
    }
  } else {
    seconds = seconds | 0;
  }

  return `Time Elapsed Hrs:${hours} Mins:${minutes} Secs:${seconds}`;
}

class TimeLine extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hidden: true
    };
  }

  componentDidMount() {
    const MessageId = this.props.match.params.Id;
    // console.log(this.props.match);
    this.props.dispatch(fetchStatuses(MessageId));
  }

  render() {
    const { classes } = this.props;
    return this.props.fetchData === false ? (
      <Loading />
    ) : (
        <div>
          <Paper style={{ borderColor: "#dad8d8" }} className={classes.paper}>
            <Grid container>
              <Grid container item xs={12} sm={12} md={4} lg={4}>
                {/* <Button
                  className={classes.refreshButton}
                  size="small"
                  onClick={() => this.props.history.goBack()}
                  variant="contained"
                  color="primary"
                >
                  Go Back
              </Button> */}

                <Grid style={{ padding: "5px" }} item xs={11} sm={11} md={7} lg={7}>
                  <Paper className={classes.time}>
                    <Typography> Last Updated: {getCurrentDate()}</Typography>
                  </Paper>
                </Grid>
                <Grid item xs={1} sm={1} md={1} lg={1}>
                  <IconButton
                    // className={classes.button}
                    style={{ color: "black" }}
                    aria-label="delete"
                    color="primary"
                    onClick={() =>
                      this.props.dispatch(fetchStatuses(this.props.match.params.Id))
                    }
                  >
                    <Tooltip title="Refresh" aria-label="add">
                      <RefreshIcon fontSize="small" />
                    </Tooltip>
                  </IconButton>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <Typography className={classes.bold} gutterBottom variant="h4">
                  Message Id: {this.props.match.params.Id}
                </Typography>
              </Grid>
            </Grid>
          </Paper>

          {this.props.statuses && (
            <VerticalTimeline>
              {this.props.statuses.map((row, key) => {
                return (
                  // console.log(typeof row.CreatedAt),
                  <VerticalTimelineElement
                    position={"left"}
                    contentArrowStyle={{
                      borderRight: "7px solid  rgb(33, 150, 243)"
                    }}
                    // contentStyle={{
                    //   background: 'rgb(254, 253, 253)',
                    //   color: '#fff',
                    // }}
                    className="vertical-timeline-element--work"
                    date={row.CreatedAt}
                    iconStyle={{
                      background: "rgb(33, 150, 243)",
                      color: "#fff"
                    }}
                    iconStyle={{
                      background:
                        row.Status == "Initialized"
                          ? "rgb(123,165,232,1)"
                          : row.Status == "Ready"
                            ? "rgb(255, 193, 7, 1)"
                            : row.Status == "Failed"
                              ? "rgb(220, 53, 69, 1)"
                              : row.Status == "Completed"
                                ? "rgb(40, 167, 69, 1)"
                                : row.Status == "Error"
                                  ? "rgb(52, 58, 64, 1)"
                                  : row.Status == "Not Required"
                                    ? "rgb(108, 117, 125, 1)"
                                    : "rgb(0, 123, 255, 1)",
                      color: "#fff"
                    }}
                    icon={
                      row.Status == "Initialized" ? (
                        <KeyboardArrowRightIcon />
                      ) : row.Status == "Ready" ? (
                        <ThumbUpAltIcon />
                      ) : row.Status == "Failed" ? (
                        <SmsFailedIcon />
                      ) : row.Status == "Completed" ? (
                        <CheckCircleIcon />
                      ) : row.Status == "Error" ? (
                        <ErrorIcon />
                      ) : row.Status == "Not Required" ? (
                        <ErrorIcon />
                      ) : (
                                    <WorkIcon />
                                  )
                    }
                    key={key}
                  >
                    <ErrorMessage message={row.Message} status={row.Status} />
                    <br />
                    {key === 0
                      ? ""
                      : showTimeSpan(
                        new Date(this.props.statuses[key - 1].CreatedAt) -
                        new Date(row.CreatedAt)
                      )}
                  </VerticalTimelineElement>
                );
              })}
            </VerticalTimeline>
          )}
        </div>
      );
  }
}

const mapStateToProps = state => ({
  statuses: state.statuses.status,
  fetchData: state.statuses.fetchData
});

export default connect(mapStateToProps)(withStyles(useStyles)(TimeLine));
