import React, {Component} from 'react';
import DonutPie from '../../../Components/DonutPie';
import {connect} from "react-redux";
import Loading from "../../../Components/Loading";
import ShowError from "../../../Components/ShowError";
import {setConnections} from '../../../Services/connection';

class ConnectionGraph extends Component {

    componentDidMount() {
        this.props.setConnections();
    }

    transform = (connections) => {

        let total = 0;
        let data = [];

        connections.map((connection) => {
            let found = false;
            total = total + 1;
            data.forEach((val) => {
                if (val.item === connection.Status) {
                    val.count = val.count + 1;
                    found = true;
                }
            });
            if (!found) {
                data.push({
                    item: connection.Status,
                    count: 1
                });
            }

        });

        return {
            data,
            total
        };
    };

    render() {
        if (this.props.processing) {
            return (
                <div>
                    <Loading/>
                </div>
            );
        } else if (this.props.connections) {
            if (!this.props.connections.length) {
                return <div>No Data</div>;
            }

            let result = this.transform(this.props.connections);

            return (
                < div>
                    <DonutPie data={result.data} total={result.total} title={"Connections"}/>
                </div>
            );
        } else {
            return (
                <div>
                    {this.props.error && <div>
                        <ShowError/>
                    </div>}
                </div>
            );
        }

    }
}


const mapStateToProps = (state) => {
    return {
        connections: state.connections_reducer.connections,
        error: state.connections_reducer.error,
        processing: state.connections_reducer.processing
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setConnections: () => dispatch(setConnections()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ConnectionGraph);
