import {
    fetchMessagesPending,
    fetchMessagesError,
    fetchMessagesSuccess,
    updateMessageError,
    updateMessagePending,
    updateMessageSuccess,
    setMessageStats,
    setMessageStatsError,
    setMessageStatsLoading,
    setMessagesAnalytics,
    setMessagesAnalyticsError,
    setMessagesAnalyticsLoading
} from "../actions/messagequeue-actions";

const apiAdapter = require("../utils/APIAdapter").apiAdapter;
const { BASE_URL } = require("../utils/Url");
const axios = require("axios");

export function fetchMessages(filters, pageNumber, numberOfRows) {
    let url = BASE_URL + `/messages/fetchAll?`;
    for (let each in filters) {
        if (!(filters[each] == "" || filters[each] == "None")) {
            url = url.concat(`${each}=${filters[each]}&`);
        }
    }
    url = url.concat(`pageNumber=${pageNumber}&numberOfRows=${numberOfRows}`);
    return dispatch => {
        dispatch(fetchMessagesPending());

        const api = apiAdapter(url);

        api
            .get()
            .then(res => {
                if (res.error) {
                    throw res.error;
                } else {
                    dispatch(fetchMessagesSuccess(res.data));
                }
            })
            .catch(error => {
                dispatch(fetchMessagesError(error));
            });
    };
}

export function updateMessageStatus(ID, StatusName) {
    return dispatch => {
        dispatch(updateMessagePending());
        const api = apiAdapter(`${BASE_URL}`);
        api
            .post("/message/updateStatus/", {
                ID: ID,
                StatusName: StatusName
            })
            .then(res => {
                if (res.error) {
                    throw res.error;
                } else {
                    dispatch(updateMessageSuccess(ID, StatusName));
                }
            })

            .catch(error => {
                dispatch(updateMessageError(error));
            });
    };
}

export function setMessageStatusGraph() {
    return (dispatch) => {
        dispatch(setMessageStatsLoading(false));
        const api = apiAdapter(`${BASE_URL}`);
        api.get("/statistics/fetchAll").then((respose) => {

            dispatch(setMessageStatsLoading(false));
            dispatch(setMessageStats(respose.data.result));
        }).catch((err) => {
            dispatch(setMessageStatsLoading(false));
            dispatch(setMessageStatsError(true));
        });
    };
};

export function setMessagesForAnalytics() {
    return async (dispatch) => {
        dispatch(setMessagesAnalyticsLoading(true));
        let data = [];
        let promises = [];
        let maxPages = null;
        const api = apiAdapter(`${BASE_URL}`);
        let response = ""
        response = await api.get(`/messages/fetchAll?pageNumber=&numberOfRows=`);

        if (response.data.success === false) {
            dispatch(setMessagesAnalyticsLoading(false));
            dispatch(setMessagesAnalyticsError(true));
        } else {
            data.push(...response.data.result);
            dispatch(setMessagesAnalyticsLoading(false));
            dispatch(setMessagesAnalytics(data));
        }
    };
};