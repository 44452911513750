import React, { Component } from 'react';
import Guage from '../../../Components/Guage';
import axios from 'axios';
import { API_GATEWAY } from "../../../utils/Url";
import Loading from "../../../Components/Loading";
import ShowError from "../../../Components/ShowError";

class FileSystemHealth extends Component {

    state = {
        data: null,
        loading: false,
        error: false
    };

    componentDidMount() {
        const prepareUrl = `${API_GATEWAY}/filestorage/storage`;
        axios.get(prepareUrl).then((respose) => {
            if (respose.data.size) {
                this.setState({
                    loading: false,
                    data: respose.data
                });
            } else {
                this.setState({
                    loading: false,
                    data: null
                });
            };

        }).catch((err) => {
            this.setState({
                loading: false,
                error: true
            });
        });
    }

    render() {

        if (this.state.loading) {
            return (
                <div>
                    <Loading />
                </div>
            );
        } else if (this.state.data) {
            return (
                <div>
                    <Guage title={' Storage Consumption'} data={this.state.data} />
                </div >
            );
        } else {
            return (
                <div>
                    {this.state.error ? <div>
                        <ShowError />
                    </div> : null}
                </div>
            );
        }

    }
}

FileSystemHealth.propTypes = {};

export default FileSystemHealth;