import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import UploadXsl from "../../Components/Mappings/upload";
import DownloadXsl from "../../Components/Mappings/download";
import Grid from "@material-ui/core/Grid"
import Paper from "@material-ui/core/Paper";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        border: "2px solid",
        color: "black",
        padding: theme.spacing(2),
        // textAlign: 'center',
        // color: theme.palette.text.secondary,
    },
}));

const ExportData = () => {
    const classes = useStyles();

    return (
        <>
            <Grid id="overidePaperElevation" container spacing={2}>
                <Grid item xs={6}>
                    <Paper className={classes.paper}>
                        <UploadXsl />
                    </Paper>
                </Grid>
                <Grid item xs={6}>
                    <Paper className={classes.paper}>
                        <DownloadXsl />
                    </Paper>
                </Grid>
            </Grid>

        </>
    );
};

export default ExportData;