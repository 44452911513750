import React,{useEffect} from 'react';
import MaterialTable from 'material-table';
import ToggleButton from 'react-toggle-button';
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import './ConfigurationTable.css'

function ConfigurationTable(props) {
    const [state, setState] = React.useState({
        editDialog: false,
        deleteDialog: false,
        columns: [
            {
                title: 'Name', field: 'Name',
            },
            {
                title: 'Value',
                field: 'Value',
                initialEditValue: '',
                render: rowData => (
                    <span>{rowData.Encrypted == true ? '*****' : rowData.Value}</span>
                )
            },
            {
                title: 'Description', field: 'Description',
            },
            // { title: 'CreatedBy', field: 'CreatedBy', editable: 'never' },
            {
                title: 'Encrypted',
                field: 'Encrypted',
                initialEditValue: 'No',
                lookup: { 0: 'No', 1: 'Yes' },
                render: rowData => (
                    <span>{rowData.Encrypted == false ? 'No' : 'Yes'}</span>
                ),
            },
            {
                title: 'Active',
                field: 'Active',
                initialEditValue: props => 0,
                editable: 'never',
                render: rowData => (

                    <ToggleButton
                        value={
                            rowData.Active == '1'
                                ? true
                                : false
                        }
                        onToggle={(value) => {
                            var Status = 0;
                            if (rowData.Active == '1') {
                                Status = 0;
                            } else {
                                Status = 1;
                            }
                            props.changeStatus(rowData.Id, Status);
                        }} />


                ),
            },
        ],
        endpointId: 0,
        dropdownFlag: false
    });

    useEffect(() => {
        /**Global Config Pull*/
        props.fetchConfigurations(0);
    },[])
    const handleChange = e => {
        setState({
            ...state,
            endpointId: e.target.value,
        });
        props.fetchConfigurations(e.target.value);
    };

    let styles = {
        width: '50%',
        marginBottom: '15px',
    };
    return (

        <div>
            <TextField
                select
                variant="outlined"
                style={styles}
                id="demo-simple-select"
                label="Select Endpoint"
                onChange={handleChange}
            >
                <MenuItem value="0">Global</MenuItem>
                {props.endpoints.map(row => {
                    return (
                        <MenuItem key={row.Id} value={row.Id}>
                            {row.Name}
                        </MenuItem>
                    );
                })}
            </TextField>

            <MaterialTable

                title=""
                columns={state.columns}
                data={props.configurations}
                options={{
                    actionsColumnIndex: -1,
                    headerStyle: {
                        backgroundColor: 'rgb(119,136,153)',
                        color: '#FFF'
                    },
                    rowStyle: rowData => ({
                        backgroundColor: (rowData.tableData.id % 2 !== 0) ? '#EEE' : '#FFF'
                    })
                }}

                actions={[
                    {
                        icon: 'edit',
                        tooltip: 'Edit',
                        onClick: (event, rowData) => props.changeDialogState(rowData, true, "edit")
                    },
                    {
                        icon: 'delete',
                        tooltip: 'Delete',
                        onClick: (event, rowData) => props.changeDialogState(rowData, true, "delete")
                    }
                ]}
                editable={{
                    onRowAdd: newData =>
                        new Promise((resolve, reject) => {
                            setTimeout(() => {
                                props.addNewConfiguration(
                                    state.endpointId,
                                    newData.Name,
                                    newData.Value,
                                    newData.Description,
                                    state.endpointId,
                                    newData.Encrypted == 'No' ? false : true,
                                    1,
                                );
                                {
                                    // eslint-disable-next-line no-unused-expressions
                                    props.apiResponse === false ? resolve() : '';
                                }
                            }, 600);
                        }),
                }}
            />
        </div>
    );
}

export default ConfigurationTable;