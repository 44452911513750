import {
    FETCH_ENDPOINT_ERROR,
    FETCH_ENDPOINT_SUCCESS,
    FETCH_ENDPOINT_PENDING,
    SET_PROCESSING_ENDPOINTS,
    SET_ACTIVE_ENDPOINT,
    SET_CONNECTION_ENDPOINTS,
    SET_FILTERED_CONNECTION,
    ERR_CONNECTION_ENDPOINTS,
    ERR_FILTERED_CONNECTION,
    LOAD_CONNECTION_ENDPOINTS,
    LOAD_FILTERED_CONNECTION
} from '../action-types/endpoint-action-types';

const initialState = {
    fetchData: false,
    endpoints: [],
    pending: true,
    error: false,
    processingEndpoint: false,
    activeEndpoint: null,
    filteredConnections: null,
    connectionsWithEndpoint: null,
    filterConnectionLoading: false,
    conEndLoading: false,
    filterConnectionError: false,
    conEndError: false,


};

export function endpointReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_ENDPOINT_PENDING:
            return {
                ...state,
                pending: true,
            };

        case FETCH_ENDPOINT_SUCCESS:
            return {
                ...state,
                endpoints: action.payload.endpoint,
                fetchData: true,
            };
        case FETCH_ENDPOINT_ERROR:
            return {
                ...state,
                error: action.error,
            };
        case SET_PROCESSING_ENDPOINTS:
            return {
                ...state,
                processingEndpoint: action.payload,
            };
        case SET_ACTIVE_ENDPOINT:
            return {
                ...state,
                activeEndpoint: action.payload,
            };
        case SET_FILTERED_CONNECTION:
            return {
                ...state,
                filteredConnections: action.payload
            };
        case SET_CONNECTION_ENDPOINTS:
            return {
                ...state,
                connectionsWithEndpoint: action.payload
            };
        case ERR_CONNECTION_ENDPOINTS:
            return {
                ...state,
                conEndError: action.payload
            };
        case ERR_FILTERED_CONNECTION:
            return {
                ...state,
                filterConnectionError: action.payload
            };
        case LOAD_CONNECTION_ENDPOINTS:
            return {
                ...state,
                conEndLoading: action.payload
            };
        case LOAD_FILTERED_CONNECTION:
            return {
                ...state,
                filterConnectionLoading: action.payload
            };
        default:
            return state;
    }
}

