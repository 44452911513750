import * as actions from '../actions/user-actions';
const { BASE_URL } = require('../utils/Url');
const { apiAdapter } = require('../utils/APIAdapter');

// export const setActiveUsers = () => {
//     return (dispatch) => {
//         let userPayload = [
//             {
//                 "userId": "rirani",
//                 "jobTitleName": "Developer",
//                 "firstName": "Romin",
//                 "lastName": "Irani",
//                 "preferredFullName": "Romin Irani",
//                 "employeeCode": "E1",
//                 "region": "CA",
//                 "phoneNumber": "408-1234567",
//                 "emailAddress": "romin.k.irani@gmail.com"
//             },
//             {
//                 "userId": "nirani",
//                 "jobTitleName": "Developer",
//                 "firstName": "Neil",
//                 "lastName": "Irani",
//                 "preferredFullName": "Neil Irani",
//                 "employeeCode": "E2",
//                 "region": "CA",
//                 "phoneNumber": "408-1111111",
//                 "emailAddress": "neilrirani@gmail.com"
//             },
//             {
//                 "userId": "thanks",
//                 "jobTitleName": "Program Directory",
//                 "firstName": "Tom",
//                 "lastName": "Hanks",
//                 "preferredFullName": "Tom Hanks",
//                 "employeeCode": "E3",
//                 "region": "CA",
//                 "phoneNumber": "408-2222222",
//                 "emailAddress": "tomhanks@gmail.com"
//             }];
//         dispatch(setUser(userPayload));
//     };
// };

export function saveLoggedInUser(status) {
    return dispatch => {
        dispatch(actions.setLoginStatus(status))
    }
}

export function fetchUsers() {
    return (dispatch) => {
        dispatch(actions.processing(true));

        const api = apiAdapter(BASE_URL);
        api
            .get(`/user/getAllUsers`)
            .then(res => {
                dispatch(actions.fetchUser(res.data.result));
                dispatch(actions.processing(false));
            })
            .catch(error => {
                dispatch(actions.error(error));
            });
    };
}

//add and update
export function upsertUsers(userData, formState) {
    return (dispatch) => {
        dispatch(actions.processing(true));

        const api = apiAdapter(BASE_URL);
        api
            .post(`/user/createUser`, {
                userData
            })
            .then(res => {
                if (formState === "edit")
                    dispatch(actions.updateUser(res.data.result))
                else
                    dispatch(actions.addUser(res.data.result));
            })
            .catch(error => {
                dispatch(actions.error(error));
            });
    };
}

