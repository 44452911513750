
import * as action from '../actions/configurations-actions';
import Cookies from "js-cookie";
import { getOptions } from '../utils/axios';
import axios from 'axios';
const apiAdapter = require('../utils/APIAdapter').apiAdapter;;
const { BASE_URL, AUTH_URL } = require('../utils/Url');

// const axios = require('axios');

const connected = Cookies.get("connect.sid");
export const fetchConfigurations = Id => {
    return dispatch => {
        dispatch(action.fetchConfigurationsPending());

        const api = apiAdapter(`${BASE_URL}/configuration/fetch/${Id}`);

        api
            .get()
            .then(res => {
                if (res.error) {
                    throw res.error;
                } else {
                    console.log('object:', res.data.result);
                    dispatch(action.fetchConfigurationsSuccess(res.data.result));
                }
            })
            .catch(error => {
                dispatch(action.fetchConfigurationError());
            });
    };
};

export const deleteConfiguration = id => {
    return dispatch => {
        dispatch(action.deleteConfigurationPending());

        const api = apiAdapter(`${BASE_URL}/configuration/delete/${id}`);

        api
            .post()
            .then(res => {
                if (res.error) {
                    throw res.error;
                } else {
                    dispatch(action.deleteConfigurationSuccess(id));
                }
            })
            .catch(error => {
                dispatch(action.deleteConfigurationError(error));
            });
    };
};

export const addNewConfiguration = (EndpointId, Name, Value, Description, Id, Encrypted, Active) => {
    return dispatch => {
        dispatch(action.addConfigurationPending());

        const api = apiAdapter(`${BASE_URL}`);
        api
            .post('/configuration/addNew', {
                EndpointId: EndpointId,
                Name: Name,
                Value: Value,
                Description: Description,
                Id: Id,
                Encrypted: Encrypted,
                Active: Active,
            })
            .then(res => {

                dispatch(action.addConfigurationSuccess(res.data.result));
            })
            .catch(error => {
                dispatch(action.addConfigurationError(error));
            });
    };
};

export const updateConfiguration = (Name, Value, Description, Id, Encrypted) => {
    return dispatch => {
        dispatch(action.updateConfigurationPending());
        const api = apiAdapter(`${BASE_URL}`);
        debugger;
        const updateData = {
            Name: Name,
            Value: Value,
            Description: Description,
            Id: Id,
            Encrypted: Encrypted,
        };
        api
            .post('/configuration/update', {
                Name: Name,
                Value: Value,
                Description: Description,
                Id: Id,
                Encrypted: Encrypted,
            })
            .then(res => {
                if (res.error) {
                    dispatch(action.updateConfigurationError());
                } else {
                    dispatch(action.updateConfigurationSuccess(updateData));
                }
            });
    };
};

export const changeStatus = (Id, status) => {
    var Id = Id;
    var Status = status;
    const data = {
        Id,
        Status,
    };
    const api = apiAdapter(`${BASE_URL}`);
    return dispatch => {
        dispatch(action.changeActiveStatusPending());
        // setTimeout(function() {
        //   dispatch(actions.changeActiveStatusSuccess(Id));
        // }, 1000);
        api
            .post(`/configuration/status/update`, data)
            .then(res => {
                if (res.error) {
                    throw res.error;
                } else {
                    dispatch(action.changeActiveStatusSuccess(Id));
                }
            })
            .catch(error => {
                action.changeActiveStatusError(error);
            });
    };
};

export const changeAuthentication = authentication => {
    return dispatch => {
        dispatch(action.changeAuthentication(authentication));
    };
};


export const setDropdownState = flag => {
    return dispatch => {
        dispatch(action.setDropdownState(flag));
    };
};

export const setSystemHealth = () => {
    return dispatch => {
        const api = apiAdapter(`${BASE_URL}`);
        api.get('/configuration/fetch/2').then((respose) => {
            dispatch(action.setConfigHealth(true));
        }).catch((err) => {
            dispatch(action.setConfigHealth(true));
        });
    };
};
export const getLoggedUserData = (cb) => {
    const api = apiAdapter(`${AUTH_URL}`);

    api.get(`/auth/user`)
        .then(res => {
            cb(res)
        }).
        catch((err) => {
            console.log("RESPONSE", err)
            Cookies.remove("connect.sid");
            cb(false);
        })
};