import React from "react";
import MaterialTable, { Column } from "material-table";
import theme from "../../theme";
import AddBoxIcon from '@material-ui/icons/AddBox';
interface Row {
  name: string;
  surname: string;
  birthYear: number;
  birthCity: number;
}

interface TableState {
  columns: Array<Column<Row>>;
  data: Row[];
}

export default function MaterialTableDemo(props: any) {
  const [state, setState] = React.useState<TableState>({
    columns: props.columns,
    data: props.tableData,
  });

  return (
    <MaterialTable
      title={props.title || ""}
      columns={state.columns}
      data={state.data}
      options={{
        paging: false,
        search: true,
        headerStyle: {
          backgroundColor: 'rgb(119,136,153)',
          color: '#FFF'
        },
        actionsColumnIndex: -1,
      }}
      actions={
        props.noOptions
          ? []
          : [
              {
                icon: "edit",
                tooltip: "Edit Notification",
                onClick: (event: any, rowData: any) =>
                  props.editNotification(rowData),
              },
              {
                icon:AddBoxIcon,
                isFreeAction: true,
                tooltip: "Add Notification",
                onClick: (event: any, rowData: any) =>
                 { 
                    props.addNotification()}
              },
                      
            ]
      }
    />
  );
}
