import React, { Component } from "react";
import { Grid, Typography, Button, Switch } from "@material-ui/core";
import { containers } from "../../utils/style";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import NotificationTable from "../../Components/Table/NotificationTable";
import ToggleButton from 'react-toggle-button'
import {
  fetchAllNotifications,
  fetchEditData,
  editNotification

} from "../../Services/notification";
import AddIcon from "@material-ui/icons/AddCircle";
import moment from "moment";



class Index extends Component {
  state = {
    switch: false,
  };
  handleAddNotification = () => {
    let a = []
    if (sessionStorage.getItem("path") === null) {
      a.push(this.props.history.location.pathname)
      sessionStorage.setItem("path", JSON.stringify(a))
    }
    else {
      a = JSON.parse(sessionStorage.getItem("path"));
      a.push(this.props.history.location.pathname)
      sessionStorage.setItem("path", JSON.stringify(a))
    }
    this.props.history.push(`/AddNotification`);
  };

  handleEditNotification = (rowData) => {
    this.props.fetchEditData(rowData);
    console.log(rowData);
    let a = []
    if (sessionStorage.getItem("path") === null) {
      a.push(this.props.history.location.pathname)
      sessionStorage.setItem("path", JSON.stringify(a))
    }
    else {
      a = JSON.parse(sessionStorage.getItem("path"))
      a.push(this.props.history.location.pathname)
      sessionStorage.setItem("path", JSON.stringify(a))
    }
    this.props.history.push(`/editNotification`);
  };

  handleToggleNotification = async (status, rowData) => {
    this.setState({
      switch: status,
    });
    let formData = {
      IsActive: status ? 1 : 0,
      Name: rowData.Name,
      Body: rowData.Body,
      Subject: rowData.Subject,
      ID: rowData.Id,
      To: rowData.To,
      CC: rowData.CC,
      BCC: rowData.BCC,
      Code: rowData.Code,
      EventId: rowData.EventId
    };
    const res = await editNotification(formData);
    if (res) {
      this.props.fetchAllNotifications();
    } else {
      this.setState({
        showErr: true
      });
    }
  };

  componentWillMount() {
    this.props.fetchAllNotifications();
  }

  dataColumns = [
    {
      title: "Notification Name",
      field: "Name",
    },
    {
      title: "Events",
      field: "notificationEvents",
      render: (rowData) => <div>{rowData.Name ? rowData.Name : null}</div>,
    },
    {
      title: "Subject",
      field: "Subject",
    },
    {
      title: "Active",
      field: "IsActive",
      render: (rowData) => (
        <div>

          <ToggleButton
            value={
              rowData.IsActive == '1'
                ? true
                : false
            }
            onToggle={(value) => {
              var Status = 0;
              if (rowData.IsActive == '1') {
                Status = 0;
              } else {
                Status = 1;
              }
              this.handleToggleNotification(Status, rowData);
            }} />
        </div>
      ),
    },
  ];

  showItems = (dataColumns) => {
    return (
      <div>
        <NotificationTable
          columns={dataColumns}
          tableData={this.props.allNotifications}
          editNotification={this.handleEditNotification}
          addNotification={this.handleAddNotification}
        />
      </div>
    );
  };

  render() {
    return (
      <div>
        {this.props.allNotifications ? (
          <div style={{ width: "100%" }}>
            {this.showItems(this.dataColumns)}
          </div>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {

    allNotifications: state.notifications_reducer.allNotifications,
    editData: state.notifications_reducer.editData,
    user: state.user_reducer.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchAllNotifications: () => dispatch(fetchAllNotifications()),
    fetchEditData: (data) => dispatch(fetchEditData(data))
  };
};

const connected = connect(mapStateToProps, mapDispatchToProps)(Index);

export default withRouter(connected);
