import {
    GET_CONNECTIONS_DATA,
    GET_CONNECTIONS_PROCESSING,
    GET_CONNECTIONS_ERROR,
    CONNECTIONS_TYPE_PROCESSING,
    SET_CONNECTION_TYPES,
    SET_CONNECTION_TYPES_ERR,
    ADD_CONNECTION_FLAG,
    SET_EDIT_DATA,
    SET_EDITABLE,
    SET_PAYLOAD
} from '../action-types/connections-action-types';


const initState = () => {
    return {
        processing: false,
        message: 'Unable to find connection data',
        error: null,
        connections: null,
        connectionTypeProcess: false,
        connectionTypes: [],
        connectionTypeErr: null,
        addConFlag: false,
        editConnectionData: null,
        editFlag: false,
        payload: null

    };
};

const connections_reducer = (state = initState(), action) => {

    let newState = {...state};

    switch (action.type) {

        case GET_CONNECTIONS_DATA:
            setData(newState, action.payload, 'connections');
            break;

        case GET_CONNECTIONS_PROCESSING:
            setData(newState, action.payload, 'processing');
            break;

        case GET_CONNECTIONS_ERROR:
            setData(newState, action.payload, 'error');
            break;

        case CONNECTIONS_TYPE_PROCESSING:
            setData(newState, action.payload, 'connectionTypeProcess');
            break;

        case SET_CONNECTION_TYPES:
            setData(newState, action.payload, 'connectionTypes');
            break;

        case SET_CONNECTION_TYPES_ERR:
            setData(newState, action.payload, 'connectionTypesErr');
            break;

        case ADD_CONNECTION_FLAG:
            setData(newState, action.payload, 'addConFlag');
            break;
        case SET_EDIT_DATA:
            setData(newState, action.payload, 'editConnectionData');
            break;
        case SET_EDITABLE:
            setData(newState, action.payload, 'editFlag');
            break;
        case SET_PAYLOAD:
            setData(newState, action.payload, 'payload');
            break;
        default:
            break;
    }

    return newState;
};

const setData = (state, payload, key) => {
    state[key] = payload;
};


export default connections_reducer;

