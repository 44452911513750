// //export const BASE_URL = 'http://localhost:3894/api/v1';s
// export const FILE_URL = 'http://10.10.20.46:3020/api/v1';
// //export const BASE_URL = 'http://ui-api:3894/api/v1';
// //export const BASE_URL = 'http://192.168.1.50:3894/api/v1';
// //export const BASE_URL = 'http://localhost:3894/api/v1';
// export const BASE_URL = 'http://10.10.20.46:3894/api/v1';
// export const LOGS_URL = 'http://10.10.20.46:3002/api/v1';
// //export const AUTH_URL = 'http://localhost:3894';
// export const API_GATEWAY = 'http://10.10.20.46:3000/api/v1';
// export const connectionUrl = 'http://10.10.20.46:3005/api/v1';
// export const notificationURL="http://10.10.20.46:3100/api/v1/";

export const FILE_URL = 'https://unicom-prod.gosaas.io/api/v1';
export const BASE_URL = 'https://unicom-prod.gosaas.io/api/v1';
export const AUTH_URL = 'https://unicom-prod.gosaas.io/api'
export const LOGS_URL = 'https://unicom-prod.gosaas.io/api/v1';
export const API_GATEWAY = 'https://unicom-prod.gosaas.io/api/v1';
export const connectionUrl = 'https://unicom-prod.gosaas.io/api/v1';
export const notificationURL = "https://unicom-prod.gosaas.io/api/v1/";
export const commonRoutes = ['/dashboard', '/messagequeue', '/users']