import React, { Component } from 'react';
import DonutPie from '../../../Components/DonutPie';
import { connect } from "react-redux";
import Loading from "../../../Components/Loading";
import ShowError from "../../../Components/ShowError";
import { setMessagesForAnalytics } from '../../../Services/messagequeue';
import GroupedHistogram from '../../../Components/GroupedHistogram';

class MessageAnalytics extends Component {

    componentDidMount() {
        this.props.setMessagesForAnalytics();
    }

    transform = (allMessages) => {
        let fields = [];
        let data = [];
        allMessages.map((message) => {
            fields.push(message.SourceName);
            let found = false;
            data.forEach((val) => {
                if (val.name == message.CurrentStatus) {
                    found = true;
                    if (val[message.SourceName]) {
                        val[message.SourceName] = val[message.SourceName] + 1;
                    } else {
                        val[message.SourceName] = 1;
                    }

                }
            });
            if (!found) {
                data.push({ name: message.CurrentStatus, [message.SourceName]: 1 });

            }
        });
        debugger;
        return {
            data,
            fields
        };
    };

    render() {
        if (this.props.processing) {
            return (
                <div>
                    <Loading />
                </div>
            );
        } else if (this.props.MessageAnalytics) {
            if (!this.props.MessageAnalytics.length) {
                return <div>No Data</div>;
            }

            let result = this.transform(this.props.MessageAnalytics);

            return (
                < div>
                    <GroupedHistogram data={result.data} fields={result.fields} title={"Message Comparison"} />
                </div>
            );
        } else {
            return (
                <div>
                    {this.props.error && <div>
                        <ShowError />
                    </div>}
                </div>
            );
        }

    }
}


const mapStateToProps = (state) => {
    return {
        processing: state.messages.MessageAnalyticsLoading,
        error: state.messages.MessageAnalyticsError,
        MessageAnalytics: state.messages.MessageAnalytics
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setMessagesForAnalytics: () => dispatch(setMessagesForAnalytics())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(MessageAnalytics);

