import * as action from '../actions/logs-actions';

const apiAdapter = require('../utils/APIAdapter').apiAdapter;;
const { BASE_URL, FILE_URL } = require('../utils/Url');
const axios = require("axios");

export const fetchLogs = () => {
    return dispatch => {
        dispatch(action.fetchLogsPending());

        const api = apiAdapter(`${BASE_URL}/cloudWatch/fetchLogs`);
        api.get().then(res => {
            if (res.error) {
                dispatch(action.fetchLogsError(res.error));
            } else {
                dispatch(action.fetchLogsSuccess(res.data));
            }
        });
    };
};

export const setFileHealth = () => {
    return dispatch => {
        axios.get(`${FILE_URL}/filestorage/getXsl`).then(res => {
            dispatch(action.setSystemHealth(true));
        }).catch((err) => {
            dispatch(action.setSystemHealth(false));
        });
    };
};
