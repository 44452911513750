import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setConnectionTypes, addConnection } from '../../Services/connection';
import Loading from '../../Components/Loading';
import AddConnectionView from '../../Components/Connections/AddConnection';

class NewConnectionHandler extends Component {

    componentDidMount() {
        this.props.setConnectionTypes();
    }

    handleClose = () => {
        this.props.addConnection();
    };


    render() {
        if (this.props.processing) {
            return (
                <div>
                    <Loading />
                </div>
            );
        } else if (this.props.connectionTypes && this.props.connectionTypes.length) {
            console.log("called with -->", this.props.connectionTypes);
            return (
                <div>
                    <AddConnectionView data={this.props.connectionTypes} open={this.props.addConFlag} handleClose={this.handleClose} />
                </div>
            );
        } else {
            return (
                <div>
                    {this.props.error && <div data-test="errorcmpt">Unable to Load Data</div>}
                </div>
            );
        }
    }
};


const mapStateToProps = (state) => {
    return {
        loading: state.connections_reducer.connectionTypeProcess,
        connectionTypes: state.connections_reducer.connectionTypes,
        error: state.connections_reducer.connectionTypeErr,
        addConFlag: state.connections_reducer.addConFlag

    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setConnectionTypes: () => dispatch(setConnectionTypes()),
        addConnection: (val) => dispatch(addConnection(val))
    };
};

// Connections.propTypes = {
//     count: PropTypes.number
// };

export default connect(mapStateToProps, mapDispatchToProps)(NewConnectionHandler);