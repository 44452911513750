import {
    FETCH_AUDIT_SUCCESS,
    FETCH_AUDIT_ERROR,
    FETCH_AUDIT_PENDING,
    FETCH_USER_ERROR,
    FETCH_USER_SUCCESS,
    UPDATE_ROWS_PER_PAGE,
    UPDATE_TOTAL_PAGES
} from '../action-types/audit-action-types'

export const fetchAuditPending = () => {
    return {
        type: FETCH_AUDIT_PENDING,
    };
};
export const fetchAuditSuccess=(audit)=>{
    
    return {
        type:FETCH_AUDIT_SUCCESS,
        payload: audit,
    }
}
export const fetchAuditError=(error)=>{
    return{
        type:FETCH_AUDIT_ERROR,
        payload:error
    }
}
export const fetchUserSuccess=(user)=>{
    
    return {
        type:FETCH_USER_SUCCESS,
        payload: user,
    }
}
export const fetchUserError=(error)=>{
    return{
        type:FETCH_USER_ERROR,
        payload:error
    }
}
export const updateTotalPages=(pages)=>{
    return{
        type:UPDATE_TOTAL_PAGES,
        payload:pages
    }
}
export const updateRowsPerPage=(row)=>{
    return {
        type:UPDATE_ROWS_PER_PAGE,
        payload:row
    }
}