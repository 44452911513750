import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import Loader from 'react-spinners/FadeLoader';



function rand() {
    return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
    const top = 50 + rand();
    const left = 50 + rand();

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

const useStyles = makeStyles(theme => ({
    paper: {
        position: 'absolute',
        minWidth: 250,
        minHeight: 250,
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #e6c1be',
        borderRadius: 10,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
}));

export default function ConnectionStatus(props) {

    const classes = useStyles();
    // getModalStyle is not a pure function, we roll the style only on the first render
    const [modalStyle] = React.useState(getModalStyle);
    const [open, setOpen] = React.useState(props.stateData.connectionModal);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        props.onClose();
    };

    const iconStyles = {
        width: 90,
        height: 90
    };

    return (
        <div>
            <Modal
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                open={props.stateData.connectionModal}
                onClose={handleClose}
            >
                <div style={modalStyle} className={classes.paper}>
                    {
                        props.stateData.loading ? <div className='row' style={{ justifyContent: 'center' }}>
                            <Loader />
                        </div> : <div>
                                <div style={{ textAlign: 'center', fontWeight: '500', fontSize: 18, color: '#755180' }}>
                                    Connection Status
                             </div>

                                {
                                    props.stateData.connectionStatus ?
                                        <div style={{ textAlign: 'center', fontWeight: '500', fontSize: 15, marginTop: 10, color: 'green' }}>
                                            Successfull!
                                        </div> :
                                        <div style={{ textAlign: 'center', fontWeight: '500', fontSize: 15, marginTop: 10, color: 'red' }}>
                                            Failed!
                                        </div>
                                }
                                <div style={{ textAlign: 'center', marginTop: 10 }}>
                                    {
                                        props.stateData.connectionStatus ? <CheckCircleIcon style={iconStyles} /> : <CancelIcon style={iconStyles} />
                                    }
                                </div>
                                <div style={{ textAlign: 'center', marginTop: 10 }}>
                                    <Button id="close" color="secondary" onClick={() => { props.onClose(); }}>
                                        Close
                             </Button>
                                </div>
                            </div>}
                </div>
            </Modal>
        </div>
    );
}
