import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: '100%',
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 250,
  },
  sourceId: {
    marginLeft: theme.spacing(1),
  },
}));

function AdvancedFilter(props) {
  const [open, setOpen] = React.useState(true);
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const changeCreatedFrom = event => {
    // setFrom(event.target.value);
    props.setFilters(event);
    // props.getMessages();
  };

  const changeMessageId = (event) => {
    props.setFilters(event);
  }

  const changeCreatedTo = event => {
    // setTo(event.target.value);
    props.setFilters(event);
    // props.getMessages();
  };

  const changeSourceId = event => {
    // setSourceId(event.target.value);
    props.setFilters(event);
    // props.getMessages();
  };

  const handleStatusChange = event => {
    // setStatus(event.target.value);
    props.setFilters(event);
    // props.getMessages();
  };

  const handleSourceChange = event => {
    // setSource(event.target.value);
    props.setFilters(event);
    // props.getMessages();
  };

  const handleDestinationChange = event => {
    // setDestination(event.target.value);
    props.setFilters(event);
    // props.getMessages();
  };

  const clearValues = () => {
    // setStatus('');
    // setSource('');
    // setDestination('');
    // setFrom('');
    // setTo('');
    // setSourceId('');
    props.clearFilters();
    props.searchMessages(true);
  };

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={props.closeFiltersDialog}
        aria-labelledby="responsive-dialog-title"

      >
        <DialogTitle id="responsive-dialog-title">
          <Grid container>
            <Grid item xs={12} sm={12} md={10} lg={10} lg={10}>
              Advanced Filters
          </Grid>
            <Grid item xs={12} sm={12} md={2} lg={2} lg={2} container direction="row" justify="flex-end" >
              <IconButton onClick={props.closeDialog} aria-label="delete" size="small">
                <CloseIcon fontSize="small" />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent style={{ overflow: "hidden" }}>
          <TextField
            id="from"
            label="Created From"
            type="date"
            name="From"
            margin="normal"
            variant="outlined"
            value={props.filters.From}
            onChange={changeCreatedFrom}
            className={classes.textField}
            InputLabelProps={{
              shrink: true,
            }}
            required={props.filters.To != ''}
            autoFocus
          />

          <TextField
            id="to"
            label="Created To"
            type="date"
            name="To"
            margin="normal"
            variant="outlined"
            value={props.filters.To}
            className={classes.textField}
            onChange={changeCreatedTo}
            InputLabelProps={{
              shrink: true,
            }}
            disabled={props.filters.From == ""}
            required={props.filters.From != ''}
          />
          <TextField
            id="Id"
            label="Id"
            placeholder="Message Id"
            name="Id"
            margin="normal"
            variant="outlined"
            value={props.filters.Id}
            onChange={changeSourceId}
            className={classes.sourceId}
            fullWidth
          />
          <TextField
            id="SourceId"
            label="SourceId"
            placeholder="Source Id"
            variant="outlined"
            name="SourceId"
            margin="normal"
            value={props.filters.SourceId}
            onChange={changeSourceId}
            className={classes.sourceId}
            fullWidth
          />
          <FormControl className={classes.formControl}>


            <TextField
              select
              label="Select Source"
              name="Source"
              variant="outlined"

              id="demo-simple-select"
              value={props.filters.Source}
              onChange={handleSourceChange}
              style={{ width: '100%', marginTop: 8 }}
            >
              {props.endpoints.map(row => {
                return (
                  <MenuItem key={row.Id} value={row.Name}>
                    {row.Name}
                  </MenuItem>
                );
              })}
            </TextField>
          </FormControl>

          <FormControl className={classes.formControl}>

            <TextField
              select
              label="Select Destination"
              name="Destination"
              variant="outlined"

              id="demo-simple-select"
              value={props.filters.Destination}
              onChange={handleDestinationChange}
              style={{ width: '100%', marginTop: 8 }}
            >
              {props.endpoints.map(row => {
                return (
                  <MenuItem key={row.Id} value={row.Name}>
                    {row.Name}
                  </MenuItem>
                );
              })}
            </TextField>
          </FormControl>
          <FormControl className={classes.formControl}>

            <TextField
              select
              label="Status"
              variant="outlined"
              id="demo-simple-select"
              name="Status"
              style={{ width: '100%', marginTop: 8 }}
              value={props.filters.Status}
              onChange={handleStatusChange}
            >
              <MenuItem value="Initialized">Initialized</MenuItem>
              <MenuItem value="Initializing">Initializing</MenuItem>
              <MenuItem value="New">New</MenuItem>
              <MenuItem value="Error">Error</MenuItem>
              <MenuItem value="Transforming">Transforming</MenuItem>
              <MenuItem value="Ready">Ready</MenuItem>
              <MenuItem value="Processing">Processing</MenuItem>
              <MenuItem value="Completed">Completed</MenuItem>
              <MenuItem value="Failed">Failed</MenuItem>
              <MenuItem value="Not Required">Not Required</MenuItem>
            </TextField>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => props.searchMessages(false)} color="primary">
            Search
          </Button>
          <Button onClick={clearValues} color="primary" >
            Clear All
          </Button>
        </DialogActions>
      </Dialog>
    </div >
  );
}

export default AdvancedFilter;
