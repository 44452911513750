import React, { useEffect } from "react";
import { sendRequest, updateConnection, getPayload } from "../../Services/connection";
import Loader from "react-spinners/FadeLoader";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import SendIcon from "@material-ui/icons/Send";
import SaveIcon from '@material-ui/icons/Save';
import TabsHoc from "../../Hoc/Tabs";
import JSONInput from "react-json-editor-ajrm/index";
import locale from "react-json-editor-ajrm/locale/en";
import { useDispatch, useSelector } from "react-redux";
import Snackbar from "../Snackbar";
import { REQUEST_REST } from '../../utils/JsUtils';


export default function RequestRest(props) {

    let requestMethods = [
        "Post", "Get", "Put", "Delete"
    ];

    let initialData = {
        method: requestMethods[0],
        baseUrl: props.requestData.url,
        port: props.requestData.port,
        username: props.requestData.username,
        password: props.requestData.password,
        endPoint: "",
        jsonBody: {},
        jsonHeader: {},
        jsonResponse: {},
        inValid: false,
        loading: false,
        called: false,
        type: props.requestData.connectionType,
        edited: false,
        message: { success: false, show: false }
    };

    const [requestData, setData] = React.useState(initialData);

    const { editConnectionData, payload } = useSelector(state => ({
        ...state.connections_reducer
    }),
        state => state);

    // dispatch action

    const dispatch = useDispatch();


    useEffect(() => {

        if (!requestData.edited && editConnectionData) {

            let endPoint = "";
            let jsonBody = {};
            let jsonHeader = {};


            if (payload) {
                console.log(payload);
                let PayloadData = JSON.parse(payload);
                if (PayloadData.header) {
                    jsonHeader = PayloadData.header;

                }

                if (PayloadData.body) {
                    jsonBody = PayloadData.body;

                }
            }

            if (editConnectionData.Details) {
                let details = JSON.parse(editConnectionData.Details);
                if (details.endPoint) {
                    endPoint = details.endPoint;
                }
            }

            let newData = {
                ...requestData,
                editConnectionData: editConnectionData,
                edited: true,
                endPoint: endPoint,
                jsonHeader: jsonHeader,
                jsonBody: jsonBody
            };
            setData(newData);
        }
    });


    const updateRequest = () => {
        dispatch(updateConnection(requestData, REQUEST_REST, (response) => {

            let newData = { ...requestData, message: { success: response, show: true } };
            setData(newData);
            setTimeout(function () {
                props.handleParentClose();
                props.handleClose();
            }, 1000);
        }));
    };


    const handleChange = event => {
        console.log("event data value", event.target.name, event.target.value);
        let data = { ...requestData, [event.target.name]: event.target.value, inValid: false };
        setData(data);
    };

    const handleJson = (event, name) => {
        let data = { ...requestData, [name]: event.jsObject, inValid: false };
        setData(data);
    };

    const handleSendRequest = () => {
        if (validator({
            ...requestData
        })) {
            setData({ ...requestData, loading: true });

            sendRequest({ ...requestData }, (res) => {
                let data = { ...requestData, jsonResponse: res, loading: false };
                setData(data);

            });
        } else {
            let data = { ...requestData, inValid: true };
            setData(data);
        }
    };

    const validator = (formData) => {
        if (formData.method !== "") {
            return true;
        } else {
            return false;
        }
    };

    return (
        <div style={{ padding: 15 }}>
            {requestData.inValid ?
                <div className="col-12" style={{ fontWeight: '500', color: 'red', textAlign: 'center' }}>
                    *Please fill the required fields*
                </div> : null}

            <div className='row' style={{ justifyContent: 'center' }}>{
                requestData.loading ? <Loader /> : null
            }</div>

            <div className="row">
                <div className="col-5">
                    <TextField
                        required
                        select
                        label="Method"
                        name="method"
                        variant="outlined"
                        value={requestData.method}
                        onChange={handleChange}
                        style={{ width: '100%' }}
                    >
                        {requestMethods.map(option => (
                            <MenuItem key={option} value={option}>
                                {option}
                            </MenuItem>
                        ))}
                    </TextField>
                </div>

                <div className="col-7">
                    <TextField required label="End Point" name="endPoint" id="endPoint" variant="outlined"
                        style={{ width: '100%' }} onChange={handleChange} value={requestData.endPoint} />
                </div>
            </div>

            <Divider style={{ marginTop: 10, marginBottom: 10 }} />

            <div className="row">
                <div className="col-6" style={{ textAlign: 'left' }}>
                    <div style={{ fontWeight: '500', fontSize: 24, color: '#9a2953' }}>
                        Request
                    </div>
                </div>
                <div className="col-3" style={{ textAlign: 'right' }}>

                    {editConnectionData && editConnectionData.Id && <Button
                        variant="contained"
                        color="default"
                        size="medium"
                        onClick={() => {
                            updateRequest();
                        }}
                        startIcon={<SaveIcon />}>
                        Save
                    </Button>}

                </div>
                <div className="col-3" style={{ textAlign: 'right' }}>

                    <Button
                        variant="contained"
                        color="default"
                        size="medium"
                        onClick={() => {
                            handleSendRequest();
                        }}
                        startIcon={<SendIcon />}>
                        Send
                    </Button>

                </div>
            </div>


            <div style={{ marginTop: 20 }}>
                <TabsHoc>
                    <div>
                        <JSONInput
                            placeholder={requestData.jsonBody} // data to display
                            theme="light_mitsuketa_tribute"
                            name="jsonBody"
                            id="jsonBody"
                            confirmGood={false}
                            value={requestData.jsonBody}
                            onChange={(event) => {
                                handleJson(event, 'jsonBody');
                            }}
                            locale={locale}
                            onKeyPressUpdate={false}
                            reset={false}
                            colors={{
                                string: "#DAA520" // overrides theme colors with whatever color value you want
                            }}
                            height="150px"
                            maxWidth="150%"
                        />
                    </div>

                    <div>
                        <JSONInput
                            placeholder={requestData.jsonHeader} // data to display
                            theme="light_mitsuketa_tribute"
                            name="jsonHeader"
                            id="jsonHeader"
                            confirmGood={false}
                            onChange={(event) => {
                                handleJson(event, 'jsonHeader');
                            }}
                            locale={locale}
                            reset={false}
                            onKeyPressUpdate={false}
                            colors={{
                                string: "#DAA520" // overrides theme colors with whatever color value you want
                            }}
                            height="150px"
                            maxWidth="150%"
                        />
                    </div>
                </TabsHoc>
            </div>

            <Divider style={{ marginTop: 10, marginBottom: 10 }} />

            <div style={{ fontWeight: '500' }}>
                Response
            </div>

            <div style={{ marginTop: 20 }}>
                <JSONInput
                    placeholder={requestData.jsonResponse} // data to display
                    theme="light_mitsuketa_tribute"
                    name="jsonResponse"
                    id="jsonResponse"
                    onChange={(event) => {
                        handleJson(event, 'jsonResponse');
                    }}
                    locale={locale}
                    confirmGood={false}
                    viewOnly={true}
                    reset={false}
                    onKeyPressUpdate={false}
                    colors={{
                        string: "#DAA520" // overrides theme colors with whatever color value you want
                    }}
                    height="150px"
                    maxWidth="150%"
                />
            </div>
            <div>
                {requestData.message.show && requestData.message.success ?
                    <Snackbar message={'Connection updated '} severity={'success'} /> : null}
                {requestData.message.show && !requestData.message.success ?
                    <Snackbar message={'Unable to save, Please try again'} severity={'error'} /> : null}
            </div>
        </div>
    );
};
