import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Paginate from '@material-ui/lab/Pagination';
import { Link } from "react-router-dom";

const useStyles = makeStyles(theme => ({
  pagination: {
    '& > *': {
      marginTop: theme.spacing(2),
    },
  },
  time: {
    border: "2px solid #d1d5da",
    padding: "3px",
    borderRadius: "3px"
  },
  refresh: {
    padding: "9px"
  },
  formControl: {
    // margin: theme.spacing(1),
    minWidth: 120
  },
  root: {
    flexGrow: 1
  },
  paper: {
    margin: "auto",
    maxWidth: "100%",
    // marginTop: '1%',
    // marginBottom: '1%',
    borderRadius: "3px"
  },
  paper: {
    border: "2px solid #d1d5da",
    padding: "20px",
    margin: "auto",
    maxWidth: "100%",
    marginTop: "1%",
    marginBottom: "1%",
    borderRadius: "3px"
  },
  box: {
    padding: "1%"
  },
  margin: {
    // margin: theme.spacing(1),
    marginLeft: "2%"
  }
}));


class Pagination extends Component {
  constructor(props) {
    super(props);
  }

  handleChangePerRow = (event) => {
    this.props.handleChangePerPage(this.props.pager.currentPage, event)
  }

  render() {
    const classes = this.props
    return (
      <Paper style={{ padding: "15px", border: "2px solid #d1d5da" }} >
        <Grid container>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={6}
            container
            direction="row"
            justify="flex-start"
            alignItems="center"
          >
            <Paper style={{ "boxShadow": "0px 0px" }}>
              <Typography> Number of Rows: </Typography>
            </Paper>
          &nbsp;  &nbsp;
          < FormControl className={classes.formControl}>
              <Select
                name="NumberOfRows"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={this.props.rowsPerPage}
                onChange={this.handleChangePerRow}
              >
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={25}>25</MenuItem>
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={100}>100</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={6}
            container
            direction="row"
            justify="flex-end"
            alignItems="center"
          >
            <nav aria-label="Page navigation example">
              {this.props.pager.pages && this.props.pager.pages.length === 0 ? <span>No Row Found</span>
                :
                <div className={classes.root}>
                  <Paginate count={this.props.pager.totalPages} onChange={(event, page) => this.props.getNewData(page)} showFirstButton showLastButton />
                </div>
              }
            </nav>

          </Grid>
        </Grid>
      </Paper>
    )
  }
}

export default withStyles(makeStyles)(Pagination);
