import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import RequestRest from './RequestRest';
import RequestSoap from './RequestSoap';
import { useDispatch, useSelector } from "react-redux";



function rand() {
    return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
    const top = 50 + rand();
    const left = 50 + rand();

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

const useStyles = makeStyles(theme => ({
    paper: {
        position: 'absolute',
        minWidth: 700,
        minHeight: 700,
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #f6f6f6',
        boxShadow: theme.shadows[4],
        display: 'block'
    },
}));

export function MyHeader(props) {
    return <div className="row" style={{ justifyContent: 'center', padding: 5 }}>
        <div className="col-6">
            <div style={{ fontWeight: '500', fontSize: 24 }}>Send Sample Request</div>
        </div>
        <div className="col-6" style={{ textAlign: 'right' }}>
            <div>
                <Button id="closeSwagger" color="secondary" onClick={() => { props.handleClose(); }}>
                    Close
                 </Button>
            </div>
        </div>
    </div>;
}



export default function Swagger(props) {
    const classes = useStyles();
    // getModalStyle is not a pure function, we roll the style only on the first render
    const [modalStyle] = React.useState(getModalStyle);

    const [modalState, setData] = React.useState(props.swagerData);

    // const handleClose = () => {
    //     let data = {...modalState, };
    //     setData({});
    // };

    return (
        <div>
            <Modal
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                open={props.swagerData.open}
                onClose={props.handleClose}
                title={"New Connection"}
            >
                <div style={modalStyle} className={classes.paper}>
                    <MyHeader handleClose={props.handleClose} />
                    <Divider style={{ marginTop: 10, marginBottom: 10 }} />

                    {
                        props.swagerData.requestData && props.swagerData.requestData.connectionType === 'Rest' ? <RequestRest requestData={props.swagerData.requestData} handleClose={props.handleClose} handleParentClose={props.handleParentClose} /> : null
                    }
                    {
                        props.swagerData.requestData && props.swagerData.requestData.connectionType === 'SOAP' ? <RequestSoap requestData={props.swagerData.requestData} handleClose={props.handleClose} handleParentClose={props.handleParentClose} /> : null
                    }
                </div>
            </Modal>
        </div>
    );
}