import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import DialogActions from '@material-ui/core/DialogActions';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

function DeleteConfiguration(props) {
    const [open, setOpen] = React.useState(true);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const deleteConfiguration = () => {
        props.changeDialogState({}, false, "delete");
        props.delete(props.values.Id);
    }

    return (
        <div>
            <Dialog

                fullScreen={fullScreen}
                open={open}
                onClose={() => props.changeDialogState({}, false, "delete")}
                aria-labelledby="responsive-dialog-title"
                fullWidth
            >
                <DialogTitle id="responsive-dialog-title">
                    <Grid container>
                        <Grid item xs={12} sm={12} md={10} lg={10} lg={10}>
                            Delete Configuration
          </Grid>
                        <Grid item xs={12} sm={12} md={2} lg={2} lg={2} container direction="row" justify="flex-end" >
                            <IconButton onClick={() => props.changeDialogState({}, false, "delete")} aria-label="delete" size="small">
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                    </Grid>



                </DialogTitle>
                <DialogContent style={{ overflow: "hidden" }}>
                    <DialogContentText>
                        <div style={{ float: "left" }}>  Are you sure you want to delete    </div>
                        <div style={{ "fontWeight": "1000", "fontStyle": "bold", float: "left", paddingLeft: "3px" }} >{props.values.Name}</div>

                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => deleteConfiguration()} color="primary">
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
        </div >
    );
}

export default DeleteConfiguration;
