import React, { PureComponent } from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  Legend,
} from 'recharts';
import { apiAdapter } from "../../utils/APIAdapter";
const { BASE_URL } = require('../../utils/Url');

class SizeChart extends React.Component {
  state = {
    data: [{ Id: '0', size: 0, time: 0 }],
    dataLoading: false,
  };

  componentDidMount = () => {
    let temp = this.state.data;
    const api = apiAdapter(BASE_URL);
    api.get(`/messages/fetchSizes`).then(res => {
      if (res.data.result != null) {
        res.data.result.map(row => {
          this.setState({
            data: [
              ...this.state.data,
              { Id: row.Id, size: row.SourceSize, time: row.Difference },
            ],
          });
          return row;
        });
      }
      this.setState({
        dataLoading: true,
      });
    });
  };

  render() {
    return this.state.dataLoading === false ? (
      ''
    ) : (
      <ResponsiveContainer width={'99%'} aspect={3}>
        <LineChart
          width={500}
          height={300}
          data={this.state.data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="Id" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Line
            type="monotone"
            dataKey="size"
            stroke="#8884d8"
            activeDot={{ r: 8 }}
          />
          <Line type="monotone" dataKey="time" stroke="#82ca9d" />
        </LineChart>
      </ResponsiveContainer>
    );
  }
}

export default SizeChart;
