import React, {Component} from 'react';
import Divider from "@material-ui/core/Divider";
import Tooltip from '@material-ui/core/Tooltip';
import {connect} from "react-redux";
import {setConnections} from "../../../Services/connection";
import Loading from "../../../Components/Loading";
import ShowError from "../../../Components/ShowError";
import ActiveIcon from '@material-ui/icons/CheckCircle';
import InActiveIcon from '@material-ui/icons/Cancel';
import Success from '@material-ui/icons/Link';
import Failed from '@material-ui/icons/LinkOff';
import Configured from '@material-ui/icons/Settings';
import DashboardTiles from '../../../Hoc/DashboardTiles';

class ConnectionHealth extends Component {


    componentDidMount() {
        this.props.setConnections();
    }


    showItem = (name, active, status) => {
        let colors = {
            Configured: <Configured style={{color: '#5874c5', fontSize: 30}}/>,
            Success: <Success style={{color: '#379a75', fontSize: 30}}/>,
            Failed: <Failed style={{color: '#ff5f5f', fontSize: 30}}/>
        };

        return [
            <div className={"row"} style={{minHeight: 45, padding: 10, margin: 0}}>
                <Tooltip title={active ? 'Active' : 'In Active'} aria-label="add">
                    <div className={'col-2'}>{active ? <ActiveIcon style={{color: '#379a75', fontSize: 30}}/> :
                        <InActiveIcon style={{color: '#ff5f5f', fontSize: 30}}/>}</div>
                </Tooltip>

                <div className={'col-8'} style={{color: '#4c4c4c', fontSize: 18}}>{name}</div>
                <div className={'col-2'}>
                    <Tooltip title={status} aria-label="add">
                        <div>{colors[status]}</div>
                    </Tooltip>
                </div>
            </div>,
            <Divider/>
        ];
    };

    showHealth = (data) => {

        return data.map((val) => {
            return this.showItem(val.Name, val.Active, val.Status);
        });
    };

    refresh = () => {
        this.props.setConnections();
    };


    render() {
        if (this.props.processing) {
            return (
                <div>
                    <Loading/>
                </div>
            );
        } else if (this.props.connections) {
            if (!this.props.connections.length) {
                return <div>No Data</div>;
            }
            return (
                <DashboardTiles title={'Connection Health'} refresh={this.refresh}>
                    {this.showHealth(this.props.connections)}
                </DashboardTiles>
            );
        } else {
            return (
                <div>
                    {this.props.error && <div>
                        <ShowError/>
                    </div>}
                </div>
            );
        }

    }
}

const mapStateToProps = (state) => {
    return {
        connections: state.connections_reducer.connections,
        error: state.connections_reducer.error,
        processing: state.connections_reducer.processing
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setConnections: () => dispatch(setConnections()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ConnectionHealth);
