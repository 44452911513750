import React from 'react';
import MaterialTable from 'material-table';
import LinkOffIcon from '@material-ui/icons/LinkOff';
import { connect } from 'react-redux';
import { addConnection } from '../../Services/connection';
import AddBoxIcon from '@material-ui/icons/AddBox';
import StorageIcon from '@material-ui/icons/Storage';
import CodeRoundedIcon from '@material-ui/icons/CodeRounded';
import CropFreeRoundedIcon from '@material-ui/icons/CropFreeRounded';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import AttachmentIcon from '@material-ui/icons/Attachment';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import ToggleButton from 'react-toggle-button';
import TextField from '@material-ui/core/TextField';

function ConnectionsTable(props) {


    const [state, setState] = React.useState({
        columns: [
            {
                title: 'Name', field: 'Name',
                render: rowData => {
                    return (<div >



                        {rowData.Type == 'SOAP' ?
                            <div style={{ float: "left" }}>
                                <OverlayTrigger
                                    overlay={
                                        <Tooltip id='1'><strong>SOAP</strong></Tooltip>
                                    }>
                                    <CropFreeRoundedIcon></CropFreeRoundedIcon>
                                </OverlayTrigger>
                            </div> :

                            rowData.Type == 'Rest' ?
                                <div style={{ float: "left" }}>
                                    <OverlayTrigger
                                        overlay={
                                            <Tooltip id='2'><strong>REST</strong></Tooltip>
                                        }>
                                        <CodeRoundedIcon></CodeRoundedIcon>
                                    </OverlayTrigger>
                                </div > :

                                rowData.Type == 'Database' ?
                                    <div style={{ float: "left" }}>
                                        <OverlayTrigger
                                            overlay={
                                                <Tooltip id='3'><strong>DataBase</strong></Tooltip>
                                            }>
                                            <StorageIcon></StorageIcon>
                                        </OverlayTrigger>
                                    </div> : rowData.Type == 'FILE' ?
                                        <div style={{ float: "left" }}>
                                            <OverlayTrigger
                                                overlay={
                                                    <Tooltip id='3'><strong>FILE</strong></Tooltip>
                                                }>
                                                <AttachmentIcon></AttachmentIcon>
                                            </OverlayTrigger>
                                        </div> : rowData.Type == 'FTP' ?
                                            <div style={{ float: "left" }}>
                                                <OverlayTrigger
                                                    overlay={
                                                        <Tooltip id='3'><strong>FTP</strong></Tooltip>
                                                    }>
                                                    <FileCopyIcon></FileCopyIcon>
                                                </OverlayTrigger>
                                            </div> : rowData.Type == 'S3' ?
                                                <div style={{ float: "left" }}>
                                                    <OverlayTrigger
                                                        overlay={
                                                            <Tooltip id='3'><strong>S3</strong></Tooltip>
                                                        }>
                                                        <InsertDriveFileIcon></InsertDriveFileIcon>
                                                    </OverlayTrigger>
                                                </div> : null}
                        <div style={{ float: "left", marginLeft: "25px" }}>{rowData.Name}</div>

                    </div>);
                }
            },

            {
                title: 'Status', field: 'Status', render: rowData => (
                    <div>

                        {rowData.Status == "Success" ? <div style={{ textAlign: "center", width: "80px" }} className="badge badge-pill badge-success" >{rowData.Status}</div> :
                            rowData.Status == "Error" ? <div style={{ textAlign: "center", width: "80px" }} className="badge badge-pill badge-danger" >{rowData.Status}</div> :
                                rowData.Status == "Configured" ? <div style={{ textAlign: "center", width: "80px" }} className="badge badge-pill badge-primary" >{rowData.Status}</div> : null}
                    </div>

                )
            },
            {
                title: 'Active', field: 'Active', type: 'boolean', render: rowData => (
                    < ToggleButton
                        value={
                            rowData.Active == '1'
                                ? true
                                : false
                        }
                        onToggle={(value) => {
                            var Status = 0;
                            if (rowData.Active == '1') {
                                Status = 0;
                            } else {
                                Status = 1;
                            }
                            deleteData(rowData);
                        }
                        } />
                )
            }
        ],
        data: props.data,
    });
    const addConnection = () => {
        props.addConnection(!props.addConFlag);
    };
    const deleteData = (rowData) => {
        props.handleDelete(rowData);
    };

    const editData = (rowData) => {
        props.handleEdit(rowData);
    };
    return (
        <MaterialTable
            title=""
            columns={state.columns}
            data={props.data}
            options={{
                actionsColumnIndex: -1,
                headerStyle: {
                    backgroundColor: 'rgb(119,136,153)',
                    color: '#FFF'
                },
                rowStyle: rowData => ({
                    backgroundColor: (rowData.tableData.id % 2 !== 0) ? '#EEE' : '#FFF'
                })
            }}
            actions={[
                {

                    icon: 'edit',
                    tooltip: 'Edit',
                    onClick: (event, rowData) => { editData(rowData) }
                },
                {
                    icon: AddBoxIcon,
                    tooltip: 'Add New Connections',
                    isFreeAction: true,
                    onClick: (event, rowData) => { addConnection() }
                }
            ]}
        />
    );
}
const mapStateToProps = (state) => {
    return {
        addConFlag: state.connections_reducer.addConFlag
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        addConnection: (val) => dispatch(addConnection(val))
    };
};

export default connect(null, mapDispatchToProps)(ConnectionsTable);

