import {
    FETCH_STATUS_PENDING,
    FETCH_STATUS_SUCCESS,
    FETCH_STATUS_ERROR,
    UPDATE_STATUS_PENDING,
    UPDATE_STATUS_SUCCESS,
    UPDATE_STATUS_ERROR
} from '../action-types/messagestatus-action-types';

export const fetchStatusPending = () => ({
    type: FETCH_STATUS_PENDING,
});

export const fetchStatusSuccess = status => ({
    type: FETCH_STATUS_SUCCESS,
    payload: {status},
});

export const fetchStatusError = error => ({
    type: FETCH_STATUS_ERROR,
    payload: {error},
});

export const updateStatusPending = () => ({
    type: UPDATE_STATUS_PENDING,
});

export const updateStatusSuccess = status => ({
    type: UPDATE_STATUS_SUCCESS,
    payload: {status},
});

export const updateStatusError = error => ({
    type: UPDATE_STATUS_ERROR,
    payload: {error},
});
