import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

function EditConfiguration(props) {
    const [open, setOpen] = React.useState(true);
    const [data, setData] = React.useState({
        Name: "",
        Value: "",
        Encrypted: "",
        Description:"",
        Active: ""
    })
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    useEffect(() => {
        setData({
            ...data,
            Id: props.values.Id,
            Name: props.values.Name,
            Value: props.values.Value,
            Description:props.values.Description,
            Encrypted: props.values.Encrypted,
            Active: props.values.Active
        })
    }, []);

    const updateValues = (event) => {
        setData({
            ...data,
            [event.target.name]: event.target.value
        })
    }

    const updateConfiguration = (event) => {
        event.preventDefault();
        props.changeDialogState("", {}, false, "edit");
        props.update(data);
    }

    return (
        <div>

            <Dialog
                fullScreen={fullScreen}
                open={open}
                onClose={() => props.changeDialogState({}, false, "edit")}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title" >
                    <Grid container>
                        <Grid item xs={12} sm={12} md={10} lg={10} lg={10}>
                            Edit Configuration
          </Grid>
                        <Grid item xs={12} sm={12} md={2} lg={2} lg={2} container direction="row" justify="flex-end" >
                            <IconButton onClick={() => props.changeDialogState({}, false, "edit")} aria-label="delete" size="small">
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent style={{ overflow: "hidden" }}>
                    <DialogContentText>
                        <form onSubmit={(event) => updateConfiguration(event)} autoComplete="off">
                            <TextField required={true} id="Name" label="Name" placeholder="Name" margin="normal" variant="outlined" name="Name" value={data.Name}
                                onChange={(event) => updateValues(event)} fullWidth autoFocus />
                            <TextField required={!data.Encrypted === true} label="Value" placeholder="Value" margin="normal" variant="outlined" name="Value" value={data.Value}
                                onChange={(event) => updateValues(event)} fullWidth autoFocus
                            />
                            <TextField required={true} id="Description" label="Description" placeholder="Description" margin="normal" variant="outlined" name="Description" value={data.Description}
                                onChange={(event) => updateValues(event)} fullWidth autoFocus />
                            
                            <TextField id="Encrypted" select fullWidth required={true} value={data.Encrypted} onChange={(event) => updateValues(event)}
                                name="Encrypted" label="Encrypted" margin="normal" variant="outlined"
                            >
                                <MenuItem value={false}>No</MenuItem>
                                <MenuItem value={true}>Yes</MenuItem>
                            </TextField>
                            {/* <TextField
                                id="Active"
                                select
                                fullWidth
                                required={true}
                                value={data.Active}
                                name="Active"
                                onChange={(event) => updateValues(event)}
                                label="Active"
                                margin="normal"
                                variant="outlined"
                            >
                                <MenuItem key={0} value={false}>No</MenuItem>
                                <MenuItem key={1} value={true}>Yes</MenuItem>
                            </TextField> */}
                            <div
                                style={{
                                    textAlign: 'right',
                                    padding: 8,
                                    margin: '24px -24px -24px -24px',
                                }}
                            >

                                <Button type="submit" color="primary">
                                    Save
                                </Button>
                            </div>
                        </form>
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        </div >
    );
}

export default EditConfiguration;
