import React, { useState, useEffect } from 'react';
import { changeStatus } from '../../Services/configurations';
import { fetchConfigurations, deleteConfiguration, addNewConfiguration, updateConfiguration } from '../../Services/configurations';
import { fetchEndpoints } from '../../Services/endpoint';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Loading from '../Loading';
import DeleteConfiguration from "./DeleteConfiguration";
import EditConfiguration from "./EditConfiguration";
import ConfigurationTable from "./ConfigurationTable";

const Configuration = (props) => {

    useEffect(() => {
        props.fetchEndpoints()
    }, [])

    const [state, setState] = React.useState({
        editDialog: false,
        deleteDialog: false,
        rowData: "",
    })

    const changeDialogState = (rowData, state, dialogType) => {
        dialogType === "edit" ? setState({ ...state, rowData: rowData, editDialog: state }) : setState({ ...state, rowData: rowData, deleteDialog: state })
    }

    const updateData = (newData) => {
        props.updateConfiguration(newData.Name, newData.Value,newData.Description ,newData.Id, newData.Encrypted);
    }

    const deleteData = (Id) => {
        props.deleteConfigurations(Id);
    }

    return (

        <div>
            {state.editDialog === true && <EditConfiguration update={updateData} changeDialogState={changeDialogState} values={state.rowData} />}
            {state.deleteDialog === true && <DeleteConfiguration delete={deleteData} changeDialogState={changeDialogState} values={state.rowData} />}
            {props.fetchData === false && <Loading />}
            {props.fetchData === true && <ConfigurationTable changeDialogState={changeDialogState} {...props} />}
        </div>
    )
}

const mapStateToProps = state => ({
    configurations: state.configurations.configurations,
    endpoints: state.endpoints.endpoints,
    fetchData: state.endpoints.fetchData,
    apiResponse: state.configurations.pending,
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            fetchConfigurations: fetchConfigurations,
            deleteConfigurations: deleteConfiguration,
            addNewConfiguration: addNewConfiguration,
            fetchEndpoints: fetchEndpoints,
            updateConfiguration: updateConfiguration,
            changeStatus: changeStatus,
        },
        dispatch,
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Configuration);
