import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import RefreshIcon from "@material-ui/icons/Refresh";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import SearchIcon from "@material-ui/icons/Search";
import AdvancedFilters from "./filters";
import ClearIcon from '@material-ui/icons/ClearAll';

export const useStyles = makeStyles(theme => ({
  time: {
    border: "2px solid #d1d5da",
    padding: "3px",
    borderRadius: "3px"
  },
  refresh: {
    padding: "9px"
  },
  formControl: {
    // margin: theme.spacing(1),
    minWidth: 120
  },
  root: {
    flexGrow: 1
  },
  paper: {
    margin: "auto",
    maxWidth: "100%",
    // marginTop: '1%',
    // marginBottom: '1%',
    borderRadius: "3px"
  },
  paper: {
    border: "2px solid #d1d5da",
    padding: theme.spacing(2),
    margin: "auto",
    maxWidth: "100%",
    marginTop: "1%",
    marginBottom: "1%",
    borderRadius: "3px"
  },
  box: {
    padding: "1%"
  },
  margin: {
    // margin: theme.spacing(1),
    marginLeft: "2%"
  }
}));

function Header(props) {
  const classes = useStyles();
  const [time, setTime] = React.useState("All");
  const [advanced, setAdvanced] = React.useState(false);

  const handleTimeChange = event => {
    setTime(event.target.value);
    props.setFilters(event);
    props.getMessages();
  };

  const handleStatusChange = event => {
    props.setFilters(event);
  };

  const handleSourceChange = event => {
    props.setFilters(event);
  };

  const handleDestinationChange = event => {
    props.setFilters(event);
  };

  const openFiltersDialog = () => {
    setAdvanced(true);
  };

  const closeFiltersDialog = () => {
    setAdvanced(false);
  };

  const searchMessages = (filterDialogStatus) => {
    setAdvanced(filterDialogStatus);
    props.getMessages()
  }

  //pass/set filters in parent
  const passFilters = event => {
    props.setFilters(event);
  };

  const getCurrentDate = () => {
    let parsedDate = new Date().toLocaleString().split(", ")
    return `${parsedDate[0].split("/").reverse("").join("-")} ${parsedDate[1]}`
  }

  const clearTimeAndFilters = () => {
    setTime("All");
    props.clearFilters();
    props.getMessages();

  }

  return (
    <div>
      {advanced === true && (
        <AdvancedFilters
          closeFiltersDialog={closeFiltersDialog}
          endpoints={props.endpoints}
          setFilters={passFilters}
          clearFilters={props.clearFilters}
          filters={props.filters}
          searchMessages={searchMessages}
          closeDialog={closeFiltersDialog}
        />
      )}
      <Paper className={classes.paper}>
        <Grid container>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={6}
            container
            direction="row"
            justify="flex-start"
            alignItems="center"
          >
            <Paper className={classes.time}>
              <Typography> Last Updated: {getCurrentDate()}</Typography>
            </Paper>
            <Box m={1} color="text.primary" clone>
              <IconButton
                className={classes.button}
                aria-label="delete"
                color="primary"
                onClick={props.getMessages}
              >
                <Tooltip title="Refresh" aria-label="add">
                  <RefreshIcon fontSize="small" />
                </Tooltip>
              </IconButton>
            </Box>
            {/* <InputLabel id="demo-simple-select-label">Time</InputLabel> */}
            <Paper style={{ "boxShadow": "0px 0px" }}>
              <Typography> Time: </Typography>
            </Paper>
            &nbsp;  &nbsp;
            < FormControl className={classes.formControl}>
              {/* <InputLabel id="demo-simple-select-label">Time</InputLabel> */}
              <Select
                name="Time"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={time}
                onChange={handleTimeChange}
              >
                <MenuItem value="All">All</MenuItem>
                <MenuItem value={2}>Last 2 Hours</MenuItem>
                <MenuItem value={4}>Last 4 Hours</MenuItem>
                <MenuItem value={8}>Last 8 Hours</MenuItem>
              </Select>
            </FormControl>
            <Box m={1} color="text.primary" clone>
              <IconButton
                className={classes.button}
                aria-label="clear"
                onClick={clearTimeAndFilters}
              >
                <Tooltip title="Clear Filters" aria-label="add">
                  <ClearIcon fontSize="small" />
                </Tooltip>
              </IconButton>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={6}
            container
            direction="row"
            justify="flex-end"
            alignItems="center"
          >
            <Typography>
              Showing {props.pager.startIndex + 1} - {props.pager.endIndex + 1} of {props.pager.totalItems}
            </Typography>
            &nbsp; &nbsp;
            <Button
              onClick={() => setAdvanced(true)}
              variant="contained"
              color="primary"
              size="medium"
            >
              Advanced Filters
            </Button>

          </Grid>
          <Grid>

          </Grid>
        </Grid>
      </Paper>
    </div >
  );
}

export default Header;
