import {
    FETCH_SCHEDULER_PENDING,
    FETCH_SCHEDULER_SUCCESS,
    FETCH_SCHEDULER_ERROR,
    DELETE_SCHEDULER_ERROR,
    DELETE_SCHEDULER_SUCCESS,
    DELETE_SCHEDULER_PENDING,
    UPDATE_SCHEDULER_ERROR,
    UPDATE_SCHEDULER_SUCCESS,
    UPDATE_SCHEDULER_PENDING,
    ADD_SCHEDULER_ERROR,
    ADD_SCHEDULER_PENDING,
    ADD_SCHEDULER_SUCCESS,
    CHANGE_ACTIVESTATUS_PENDING,
    CHANGE_ACTIVESTATUS_SUCCESS,
    SCHEDULAR_HEALTH
} from '../action-types/scheduler-action-types';

const initialState = {
    fetchData: false,
    schedulers: [],
    loading: false,
    error: null,
    schedularHealth: false
};

export function schedulerReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_SCHEDULER_PENDING:
            return {
                ...state,
                loading: true,
                error: null,
            };

        case FETCH_SCHEDULER_SUCCESS:
            return {
                ...state,
                loading: false,
                schedulers: action.payload.scheduler,
                fetchData: true,
            };
        case FETCH_SCHEDULER_ERROR:
            return {
                ...state,
                error: action.error,
            };
        case ADD_SCHEDULER_ERROR:
            return {
                ...state,
                pending: true,
            };
        case ADD_SCHEDULER_SUCCESS:
            return {
                ...state,
                schedulers: state.schedulers.concat(action.payload.scheduler),
            };
        case ADD_SCHEDULER_ERROR:
            return {
                ...state,
                error: action.error,
            };

        case DELETE_SCHEDULER_PENDING:
            return {
                ...state,
                pending: true,
            };
        case DELETE_SCHEDULER_SUCCESS:
            var schedulers = state.schedulers.filter(function (obj) {
                return obj.ID !== action.payload.id;
            });
            return {
                ...state,
                pending: false,
                schedulers: schedulers,
            };
        case DELETE_SCHEDULER_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error,
            };
        case UPDATE_SCHEDULER_PENDING:
            return {
                ...state,
                pending: true,
            };
        case UPDATE_SCHEDULER_SUCCESS:
            var newSchedulers = [];
            console.log(action.payload.scheduler.endpointName);
            newSchedulers = state.schedulers.slice();
            var index = newSchedulers.findIndex(
                x => x.ID === action.payload.scheduler.ID,
            );
            newSchedulers[index].Name = action.payload.scheduler.Name;
            newSchedulers[index].ScheduledDateTime =
                action.payload.scheduler.ScheduledDateTime;
            newSchedulers[index].EndpointId = action.payload.scheduler.endpointId;
            newSchedulers[index].EndpointName = action.payload.scheduler.endpointName;
            newSchedulers[index].Execute = action.payload.scheduler.execute;
            return {
                ...state,
                schedulers: newSchedulers,
            };
        case UPDATE_SCHEDULER_ERROR:
            return {
                ...state,
                error: action.error,
            };

        case CHANGE_ACTIVESTATUS_PENDING:
            return {
                ...state,
                pending: true,
            };
        case SCHEDULAR_HEALTH:
            return {
              ...state,
                schedularHealth: action.payload
            };
        case CHANGE_ACTIVESTATUS_SUCCESS:
            var newSchedulers = [];
            newSchedulers = state.schedulers.slice();
            var index = newSchedulers.findIndex(x => x.ID === action.payload.Id);
            newSchedulers[index].Active = !newSchedulers[index].Active;
            return {
                ...state,
                schedulers: newSchedulers,
            };
        default:
            return state;
    }
}
