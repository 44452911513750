import React, {Component} from 'react';

class Upload extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedFile: null,
            setAttachment: '',
            setContent: [],
        };
    }

    maxSelectFile = event => {
        let files = event.target.files; // create file object
        if (files.length > 10) {
            const msg = 'Only 10 files can be uploaded at a time';
            event.target.value = null; // discard selected file
            return false;
        }
        return true;
    };

    handleFileChosen = file => {
        if (window.File && window.FileReader && window.FileList && window.Blob) {
            // Great success! All the File APIs are supported.
            const reader = new FileReader();
            reader.onload = event => console.log(event.target.result); // desired file content
            // eslint-disable-next-line no-undef
            reader.onerror = error => reject(error);
            reader.readAsBinaryString(file);
        } else {
            alert('The File APIs are not fully supported in this browser.');
        }
    };

    onChangeHandler = event => {
        console.log('====event====', event.target.files);
        var files = event.target.files;
        var setContentArray = [];
        if (this.maxSelectFile(event)) {
            // if return true allow to setState
            for (let i = 0; i < files.length; i++) {
                const reader = new FileReader();
                reader.onload = event => {
                    setContentArray.push(event.target.result);
                }; // desired file content

                // eslint-disable-next-line no-undef
                reader.onerror = error => reject(error);
                reader.readAsBinaryString(files[i]);
            }
            this.setState({
                setAttachment: files,
                setContent: setContentArray,
            });
            //   this.props.setAttachmentData(event.target.files);
            //   this.props.setContentData(setContentArray);
        }
    };

    render() {
        return (
            <div className="container">
                <div className="row">
                    <div className=" col-md-25">
                        <div className="form-group files">
                            <label>Upload Your File </label>

                            <input
                                type="file"
                                name="file"
                                multiple
                                onChange={this.onChangeHandler}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Upload;
