import {
    FETCH_LOGS_ERROR,
    FETCH_LOGS_SUCCESS,
    FETCH_LOGS_PENDING,
    FILE_SYSTEM_HEALTH
} from '../action-types/logs-action-types';

const initialState = {
    logs: [],
    pending: true,
    error: false,
    fileStorageHealth: false
};

export function LogsReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_LOGS_PENDING:
            return {
                ...state,
                pending: true,
            };

        case FETCH_LOGS_SUCCESS:
            return {
                ...state,
                logs: action.payload.logs,
            };
        case FETCH_LOGS_ERROR:
            return {
                ...state,
                error: action.error,
            };
        case FILE_SYSTEM_HEALTH:
            return {
                ...state,
                fileStorageHealth: action.payload,
            };
        default:
            return state;
    }
}
