import * as action from '../actions/endpoint-actions';
import { connectionUrl } from "../utils/Url";
import axios from "axios";
import { getConnectionData, getConnectionError, getConnectionProcessing } from "../actions/connections-actions";
import { getOptions, removeCommon } from "../utils/JsUtils";
import { setFilteredConnection } from "../actions/endpoint-actions";

const apiAdapter = require('../utils/APIAdapter').apiAdapter;
const { BASE_URL } = require('../utils/Url');

export const fetchEndpoints = () => {
    return dispatch => {
        dispatch(action.fetchEndpointPending());
        dispatch(action.setProcessingEndpoint(true));

        const api = apiAdapter(`${BASE_URL}/endpoints/fetchAll`);
        api.get().then(res => {
            if (res.error) {
                dispatch(action.setProcessingEndpoint(false));
                dispatch(action.fetchEndpointError(res.error));
            } else {
                dispatch(action.setProcessingEndpoint(false));
                dispatch(action.fetchEndpointSuccess(res.data.result));
            }
        }).catch((err) => {
            dispatch(action.setProcessingEndpoint(false));
            dispatch(action.fetchEndpointError(true));
        });
    };
};


export const setActiveEndpoint = (activeEndpoint) => {
    return dispatch => {
        dispatch(action.setActiveEndpoint(activeEndpoint));
    };
};


export const setFilteredActiveConnection = (connection) => {
    return (dispatch, getState) => {
        if (connection) {
            dispatch(action.setFilteredConnection(connection));
        } else {
            const prepareUrl = `${connectionUrl}/connections/getAllConnections`;

            axios.get(prepareUrl, getOptions()).then((respose) => {
                if (respose.data) {
                    let data = respose.data.result;
                    let filtered = data.filter((data) => {
                        return data.Active === 1;
                    });

                    let endPointConnections = getState().endpoints.connectionsWithEndpoint;
                    if (endPointConnections && endPointConnections.length) {
                        let filteredConnections = removeCommon(filtered, endPointConnections);
                        dispatch(action.setFilterConnectionLoading(false));
                        dispatch(setFilteredActiveConnection(filteredConnections));
                    } else {
                        dispatch(action.setFilterConnectionLoading(false));
                        dispatch(setFilteredActiveConnection(filtered));
                    }


                } else {
                    dispatch(action.setFilterConnectionLoading(false));
                    dispatch(action.setFilterConnectionError(true));
                }
            }).catch((err) => {
                dispatch(action.setFilterConnectionLoading(false));
                dispatch(action.setFilterConnectionError(true));
            });
        }
    };

};


export const setConnectionsOfEndpoint = (data, endpointName) => {
    return (dispatch) => {
        if (data) {
            dispatch(action.setEndpointConnections(data));
        } else {
            const prepareUrl = `${connectionUrl}/connections/findByEndpointName`;
            let formData = {
                "EndpointName": endpointName
            };
            axios.post(prepareUrl, formData, getOptions()).then((respose) => {
                if (respose.data && respose.data.result && respose.data.result.length) {
                    let endpointConnections = respose.data.result;

                    dispatch(action.setConnectionEndLoading(false));
                    dispatch(action.setEndpointConnections(endpointConnections));
                    dispatch(setFilteredActiveConnection(null));

                } else {
                    dispatch(action.setEndpointConnections(null));
                    dispatch(setFilteredActiveConnection(null));
                    dispatch(action.setConnectionEndLoading(false));
                    dispatch(action.setConnectionEndErr(true));
                }
            }).catch((err) => {
                dispatch(action.setConnectionEndLoading(false));
                dispatch(action.setConnectionEndErr(true));
            });
        }
    };
};

export const updateEndpointConnection = (endPoint, selecteditem, operation) => {
    return (dispatch, getState) => {
        let EndpointConnections = [];
        let connectionsWithEndpoint = getState().endpoints.connectionsWithEndpoint;
        if (!connectionsWithEndpoint) {
            connectionsWithEndpoint = [];
        }

        switch (operation) {
            case 'ADD':
                connectionsWithEndpoint.push(selecteditem);
                if (connectionsWithEndpoint && connectionsWithEndpoint.length) {
                    connectionsWithEndpoint.forEach((result) => {
                        EndpointConnections.push(result.Id);
                    });
                }
                break;
            case 'REMOVE':
                let removedResult = removeCommon(connectionsWithEndpoint, [selecteditem]);
                if (removedResult && removedResult.length) {
                    removedResult.forEach((result) => {
                        EndpointConnections.push(result.Id);
                    });
                }
                break;
            default:
                break;
        }

        let formData = {
            "EndpointId": endPoint.Id,
            "CreatedBy": "Integrator-UI",
            "EndpointConnections": EndpointConnections
        };

        const prepareUrl = `${connectionUrl}/connections/updateEndpointConnections`;

        axios.post(prepareUrl, formData, getOptions()).then((respose) => {
            if (respose.data && respose.data.success) {
                dispatch(setConnectionsOfEndpoint(null, endPoint.Name));
            }
        }).catch((err) => {
            dispatch(action.setConnectionEndLoading(false));
            dispatch(action.setConnectionEndErr(true));
        });

    };
};

