const uuidv1 = require('uuid/v1');
export const firstCapital = (myString) => {
    return (myString.charAt(0).toUpperCase() + myString.substring(1)); 
};

export const getOptions = () => {

    return ({
        headers: {

            'Accept': 'application/json',
            'Content-Type': 'application/json',
            traceId: uuidv1()

        }
    });
};


export const removeCommon = (targetArray, endpointConnections) => {
    let result = targetArray;
    endpointConnections.forEach((endpointConnection)=>{
        for( var i = 0; i < result.length; i++){
            if ( result[i].Id === endpointConnection.Id) {
                result.splice(i, 1);
            }
        }
    });
    return result;

};

export const REQUEST_REST = "REQUEST_REST";
export const REQUEST_SOAP = "REQUEST_SOAP";
export const REQUEST_MAIN = "REQUEST_MAIN";