import {DELETE_CONFIGURATION_ERROR} from '../action-types/configurations-action-types';

import {
    FETCH_SCHEDULER_PENDING,
    FETCH_SCHEDULER_SUCCESS,
    FETCH_SCHEDULER_ERROR,
    ADD_SCHEDULER_PENDING,
    ADD_SCHEDULER_SUCCESS,
    ADD_SCHEDULER_ERROR,

    UPDATE_SCHEDULER_PENDING,
    UPDATE_SCHEDULER_SUCCESS,
    UPDATE_SCHEDULER_ERROR,

    DELETE_SCHEDULER_PENDING,
    DELETE_SCHEDULER_SUCCESS,
    DELETE_SCHEDULER_ERROR,
    CHANGE_ACTIVESTATUS_PENDING,
    CHANGE_ACTIVESTATUS_SUCCESS,
    CHANGE_ACTIVESTATUS_ERROR,
    SCHEDULAR_HEALTH

} from '../action-types/scheduler-action-types';

export const fetchSchedulerPending = () => {
    return {
        type: FETCH_SCHEDULER_PENDING,
    };
};

export const fetchSchedulerSuccess = scheduler => {
    return {
        type: FETCH_SCHEDULER_SUCCESS,
        payload: {scheduler},
    };
};

export const fetchSchedulerError = error => {
    return {
        type: FETCH_SCHEDULER_ERROR,
        payload: error,
    };
};

export const addSchedulerPending = () => {
    return {
        type: ADD_SCHEDULER_PENDING,
    };
};

export const addSchedulerSuccess = scheduler => {
    return {
        type: ADD_SCHEDULER_SUCCESS,
        payload: {scheduler},
    };
};

export const addSchedulerError = error => {
    return {
        type: ADD_SCHEDULER_ERROR,
        payload: {error},
    };
};

export const updateSchedulerPending = () => {
    return {
        type: UPDATE_SCHEDULER_PENDING,
    };
};

export const updateSchedulerSuccess = scheduler => {
    return {
        type: UPDATE_SCHEDULER_SUCCESS,
        payload: {scheduler},
    };
};

export const updateSchedulerError = error => {
    return {
        type: UPDATE_SCHEDULER_ERROR,
        payload: {error},
    };
};

export const deleteSchedulerPending = () => {
    return {
        type: DELETE_SCHEDULER_PENDING,
    };
};

export const deleteSchedulerSuccess = id => {
    return {
        type: DELETE_SCHEDULER_SUCCESS,
        payload: {id},
    };
};

export const deleteSchedulerError = error => {
    return {
        type: DELETE_CONFIGURATION_ERROR,
        payload: error,
    };
};

export const changeActiveStatusPending = () => {
    return {
        type: CHANGE_ACTIVESTATUS_PENDING,
    };
};

export const changeActiveStatusSuccess = Id => {
    return {
        type: CHANGE_ACTIVESTATUS_SUCCESS,
        payload: {Id},
    };
};

export const changeActiveStatusError = error => {
    return {
        type: CHANGE_ACTIVESTATUS_ERROR,
        payload: error,
    };
};

export const setSchedularHealth = (flag) => {
    return {
        type: SCHEDULAR_HEALTH,
        payload: flag
    };
};
