import React, { Component, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from "@material-ui/core/Paper";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchEndpoints } from "../../Services/endpoint";
import { NotificationManager } from 'react-notifications';
import Select from 'react-select';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import UploadButton from "../UploadButton";
import { FILE_URL } from "../../utils/Url";
import axios from "axios";

const getOptions = require("../../Services/connection").getOptions;
const apiAdapter = require('../../utils/APIAdapter').apiAdapter;

const useStyles = makeStyles(theme => ({
    button: {
        display: 'block',
        marginTop: theme.spacing(2),
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    paper: {
        padding: theme.spacing(2),
    },
}));

function Upload(props) {
    const classes = useStyles();
    const [endpoints, setEndpoints] = React.useState([])
    const [source, setSource] = React.useState("");
    const [destination, setDestination] = React.useState("");
    const [selectedFile, setFile] = React.useState(null)

    const handleSourceChange = value => {
        setSource(value);
    };

    const handleDestinationChange = value => {
        setDestination(value);
    };

    useEffect(() => {
        props.fetchEndpoints();
    }, []);

    useEffect(() => {
        let endpoint = []
        props.endpoints.map(row => {
            endpoint.push({
                value: `${row.Id}`,
                label: `${row.Name}`
            })
        })
        setEndpoints(endpoint)
    }, [props.endpoints])


    const onFileChange = event => {
        setFile(event.target.files[0])
    }

    const onUploadCLick = () => {
        var fileName = ""
        if (source.value === "" || destination.value === "" || source.value === destination.value)
            NotificationManager.error('Source or destination names are empty or invalid', 'Error');
        else {
            if (selectedFile === null)
                NotificationManager.error("Please select a file", "Error")
            else {
                fileName = source.value + "-" + destination.value + ".xsl"
                const data = new FormData()
                data.append('file', selectedFile)
                const api = apiAdapter(FILE_URL);
                api.post(`/xsl/upload?fileName=${fileName}`, data, getOptions()).then(res => {
                    setFile(null)
                    NotificationManager.success(source.label + "-" + destination.label + ' uploaded', 'Success');
                })
            }
        }
    }

    return (
        <div>
            <Grid id="overidePaperElevation" container spacing={2}>
                <Grid id="overidePaperElevation" item xs={12} sm={12} lg={12} xl={12}>
                    <Typography className="Grid-heading" variant="h4"> Upload XSLT </Typography>
                    <Divider className="divider" />
                </Grid>
                <br></br>
                <Grid id="overidePaperElevation" item xs={8}>
                    <Select onChange={(val) => handleSourceChange(val)} placeholder={'Source'} options={endpoints} />
                </Grid>
                <br></br>
                <Grid id="overidePaperElevation" item xs={8}>
                    <Select onChange={(val) => handleDestinationChange(val)} placeholder={'Destination'} options={endpoints} />
                </Grid>
                <br></br>
                <Grid id="overidePaperElevation" item xs={6} md={6} lg={6} xl={6}>
                    <div style={{ align: "left" }}>
                        <UploadButton onFileChange={onFileChange} onUploadCLick={onUploadCLick} />
                    </div>
                    {selectedFile != null && selectedFile.name}
                </Grid>
            </Grid>
        </div>
    )
}

const mapStateToProps = state => ({
    endpoints: state.endpoints.endpoints,
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            fetchEndpoints: fetchEndpoints,
        },
        dispatch,
    );

export default connect(mapStateToProps, mapDispatchToProps)(Upload);