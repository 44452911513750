import React, { Component } from 'react';
import DonutPie from '../../../Components/DonutPie';
import { connect } from "react-redux";
import Loading from "../../../Components/Loading";
import ShowError from "../../../Components/ShowError";
import { fetchSchedulers } from '../../../Services/scheduler';

class SchedularGraph extends Component {

    componentDidMount() {
        this.props.fetchSchedulers();
    }

    transform = (schedulers) => {

        let total = 0;
        let data = [{
            item: 'Active',
            count: 0
        },
        {
            item: 'InActive',
            count: 0
        }];

        schedulers.map((schedule) => {
            total = total + 1;
            if (schedule.Active === 1) {
                data[0].count++;
            } else {
                data[1].count++;
            }

        });

        return {
            data,
            total
        };
    };

    render() {
        if (!this.props.fetchData) {
            return (
                <div>
                    <Loading />
                </div>
            );
        } else if (this.props.schedulers) {
            if (!this.props.schedulers.length) {
                return <div>No Data</div>;
            }

            let result = this.transform(this.props.schedulers);

            return (
                < div>
                    <DonutPie data={result.data} total={result.total} title={"Schedulers"} legend={true} />
                </div>
            );
        } else {
            return (
                <div>
                    {this.props.error && <div>
                        <ShowError />
                    </div>}
                </div>
            );
        }

    }
}


const mapStateToProps = (state) => {
    return {
        schedulers: state.schedulerReducer.schedulers,
        fetchData: state.schedulerReducer.fetchData,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchSchedulers: () => dispatch(fetchSchedulers()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SchedularGraph);
