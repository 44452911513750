import React, { Component } from 'react'
import UserManagement from "../../Components/UserManagement";

const User = () => {
    return (
        <React.Fragment>
            <UserManagement/>
        </React.Fragment>
    )
}

export default UserManagement
