import React from 'react';
import LinkOffIcon from '@material-ui/icons/LinkOff';

export default function ShowError(props) {
    return (
        <div style={{ textAlign: 'center', padding: 30 }}>
            <div style={{ color: '#8d79a6', fontSize: 30 }}>
                {props.message ? props.message:'Unable to fetch data'}
           </div>
            <div>
                <LinkOffIcon style={{ width: 70, height: 70, color: '#8d79a6' }} />
            </div>
        </div>
    );
}


