import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button from "@material-ui/core/Button";
import RefreshIcon from '@material-ui/icons/Cached';
import Divider from "@material-ui/core/Divider";
import graphcss from '../Containers/Dashboard/Graphs/Graphs.css';

class DashboardTiles extends Component {

    refresh = () => {
        this.props.refresh();
    };

    render() {
        return (
            <div style={{ height: '100%' }}>
                <div className="row" style={{ height: '10%', marginLeft: 0, textAlign: 'center' }}>
                    <div style={{ textAlign: 'center', marginTop: 5 }}>
                        <Button
                            style={{ height: '73%', padding: 0 }}
                            variant="outlined"
                            size={'small'}
                            color="default"
                            startIcon={<RefreshIcon style={{ fontSize: 28, fontWeight: 500, marginLeft: 8, marginRight: 0 }} />}
                            onClick={this.refresh}
                        >
                        </Button>
                    </div>
                </div>
                <Divider />
                <div className="row" style={{ height: '80%', overflow: 'auto' }}>
                    <div className={'col-12'}>
                        {this.props.children}
                    </div>
                </div>
                <div className="heading_color">
                    <Divider />
                    <div className="row" style={{ height: '10%', margin: 0, justifyContent: 'center' }}>
                        <div className="heading_graph " style={{ marginTop: 20, marginBottom: -1 }}>
                            {this.props.title}
                        </div>
                    </div>
                </div>
            </div>


        );
    }
}

DashboardTiles.propTypes = {};

export default DashboardTiles;