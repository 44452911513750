import {
  getError,
  setNotificationEvents,
  setRecipientRoles,
  setNotificationTags,
  getAllNotifications,
  setEditData,
} from "../actions/notifications";
const axios = require("axios");
const baseUrl = require('../utils/Url').notificationURL;
const APIAdapter = require('../utils/APIAdapter').apiAdapter;
const getOption = require('./connection').getOptions;

export const fetchNotificationEvents = () => {
  return (dispatch) => {
    dispatch(setNotificationEvents(null));
    let url = baseUrl;
    axios.post(baseUrl + "eventadapter/getEvents", {

    }, getOption())
      .then((response) => {
        console.log("Response", response.data);
        if (response.data.result) {
          dispatch(setNotificationEvents(response.data.result));
        }
      })
      .catch((err) => {
        console.log(err);
        debugger;
        dispatch(getError(err));
      });
  };
};
export const SetNotificationTags = (Name) => {
  return (dispatch) => {
    dispatch(setNotificationTags(null));
    let url = baseUrl;
    const api = APIAdapter(url);
    axios
      .post(url + "eventadapter/getEventTags", {
        "EventName": Name
      }, getOption())
      .then((response) => {
        if (response.data.result) {
          dispatch(setNotificationTags(response.data.result));
        }
      })
      .catch((err) => {
        dispatch(getError(err));
      });
  };
}
export const addNotification = (data) => {


  let url = baseUrl;
  const api = APIAdapter(url);
  return axios
    .post(url + "templateadapter/createTemplate", {
      "EventId": data.EventId,
      "Code": data.Code,
      "Name": data.Name,
      "To": data.To,
      "From": "halvi@gmail.com",
      "CC": data.CC,
      "BCC": data.BCC,
      "Subject": data.Subject,
      "Body": data.Body,
      "isActive": data.isActive
    }, getOption())
    .then((response) => {
      console.log(response);
      debugger;
      if (response.data.success) {
        return true;
      }
    })
    .catch((err) => {
      return false;
    });
}
export const editNotification = (data) => {

  let url = baseUrl;
  const api = APIAdapter(url);
  return axios
    .post(url + `templateadapter/editTemplate`, {
      "ID": data.ID,
      "EventId": data.EventId,
      "Code": data.Code,
      "Name": data.Name,
      "To": data.To,
      "CC": data.Cc,
      "BCC": data.Bcc,
      "Subject": data.Subject,
      "Body": data.Body,
      "IsActive": data.IsActive
    }, getOption())
    .then((response) => {
      console.log(response);
      debugger;
      if (response.data.success) {
        return true;
      }
    })
    .catch((err) => {
      return false;
    });
}
export const getEventName = (Id) => {
  let url = baseUrl;
  const api = APIAdapter(url);
  return axios
    .post(url + "eventadapter/getEvents", {
      "Id": Id
    }, getOption())
    .then((response) => {
      if (response.data.success) {
        return response.data.result[0].Name;
      }
    })
    .catch((err) => {
      return false;
    });
}
export const fetchAllNotifications = () => {
  return (dispatch) => {
    dispatch(getAllNotifications(null));
    let url = baseUrl;
    const api = APIAdapter(url);
    axios
      .post(url + "templateadapter/getTemplates", {}, getOption())
      .then((response) => {
        if (response.data.result) {
          dispatch(getAllNotifications(response.data.result));
        }
      })
      .catch((err) => {
        dispatch(getError(err));
      });
  };
};
export const fetchEditData = (data) => {
  return (dispatch) => {
    dispatch(setEditData(data));
  };
};