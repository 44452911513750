import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Divider from "@material-ui/core/Divider";
import graphcss from "./Graphs.css";
import RefreshIcon from '@material-ui/icons/Refresh';
import Button from '@material-ui/core/Button';
import {setSystemHealth} from '../../../Services/configurations';
import StatusOn from '@material-ui/icons/BatteryChargingFull';
import StatusOff from '@material-ui/icons/BatteryUnknown';
import Tooltip from '@material-ui/core/Tooltip';
import {setFileHealth} from '../../../Services/logs';

import {connect} from "react-redux";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import {fetchSchedulers} from "../../../Services/scheduler";
import DashboardTiles from "../../../Hoc/DashboardTiles";


class SystemHealth extends Component {


    componentDidMount() {
        this.props.setSystemHealth();
        this.props.fetchSchedulers();
        this.props.setFileHealth();
    }

    refresh = () => {
        this.props.setSystemHealth();
        this.props.fetchSchedulers();
        this.props.setFileHealth();
    };

    showItem = (name, status) => {
        return [
            <Tooltip title={status ? 'Active' : 'In Active'} aria-label="add">
                <div className={"row"} style={{minHeight: 45, padding: 10, margin: 0}}>
                    <div className={'col-2'}>{status ? <StatusOn style={{color: 'green', fontSize: 30}}/> :
                        <StatusOff style={{color: 'red', fontSize: 30}}/>}</div>
                    <div className={'col-10'} style={{color: '#4c4c4c', fontSize: 18}}>{name}</div>
                </div>
            </Tooltip>,
            <Divider/>
        ];
    };

    showHealth = () => {
        let data = [
            {name: 'Configuration', status: this.props.configHealth},
            {name: 'Database', status: this.props.configHealth},
            {name: 'Schedular', status: this.props.schedularHealth},
            {name: 'File Storage', status: this.props.fileStorageHealth}];
        return data.map((val) => {
            return this.showItem(val.name, val.status);
        });
    };


    render() {
        return (
            <DashboardTiles title={'System Health'} refresh={this.refresh}>
                        {this.showHealth()}
            </DashboardTiles>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        configHealth: state.configurations.configHealth,
        schedularHealth: state.schedulerReducer.schedularHealth,
        fileStorageHealth: state.logsReducer.fileStorageHealth
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setSystemHealth: () => dispatch(setSystemHealth()),
        fetchSchedulers: () => dispatch(fetchSchedulers()),
        setFileHealth: () => dispatch(setFileHealth())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SystemHealth);